import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

  export default ({ header, children, isOpen, scrollable, onClose }) => {
  if (!isOpen) { return null }

  return (
    <Modal isOpen={isOpen} toggle={onClose} size="lg" scrollable={scrollable}>
      <ModalHeader toggle={onClose}>{header}</ModalHeader>
      <ModalBody>{children}</ModalBody>
    </Modal>
  )
}
