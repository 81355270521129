import * as React from 'react'
import { useFormContext } from "react-hook-form"
import { FormRegistryShape, SurveyElementFieldShape, SurveyFieldAnswerShape } from "./survey_answer_form"
import WidgetFactory from "./widget_factory"

interface FieldProps {
  field: SurveyElementFieldShape
  fieldsRegistry: FormRegistryShape
  answer: SurveyFieldAnswerShape
}

export default function Field({ field, answer, fieldsRegistry }: FieldProps) {
  const registry = fieldsRegistry.fields[field.id]
  const { register } = useFormContext()


  return (
    <>
      <WidgetFactory field={field} fieldName={registry.fieldName} />
      {answer && <input type="hidden" {...register(registry.fieldAnswerIdName)} value={answer.id} />}
      <input type="hidden" {...register(registry.fieldIdName)} value={field.id} />
    </>
  )
}
