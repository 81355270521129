import ajax from '../libs/init_ajax'
import videojs from 'video.js'

import 'styles/core'

import init from 'init'
import React from 'react'
import ReactRailsUJS from 'react_rails_ujs'
import Components from '../components/application_components'
import views from '../views'

init(views)
window.ReactComponents = Components
// We should set react as global variable, because we would like to use syntasic like `class MyComponent extends Component`
window.React = React
window.ReactRailsUJS = ReactRailsUJS

ReactRailsUJS.mountComponents()
