import React from 'react'
import connect from './modal_window_connect'
import Spinner from 'components/shared/spinner'
import Forms from './forms'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const I18n = window.I18n

class ModalWindow extends React.Component {
  render () {
    const { isFetching } = this.props

    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} size="lg">
        <ModalHeader toggle={this.props.toggleModal}>
          {I18n.t('multi_step.project_step_button.modal_title')} { isFetching && <Spinner viewType="inline" size="1" />}
        </ModalHeader>
        <ModalBody>
          <Forms toggleModal={this.props.toggleModal} />
        </ModalBody>
      </Modal>
    )
  }
}

export default connect(ModalWindow)
