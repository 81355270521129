import * as React from 'react'
import { elementSubtotal } from './element'
import OrderMeta from './order_meta'
import StockLevelWarning from './stock_level_warning'
import ElementList from './element_list'
import TotalPrice from './total_price'
import FormActions from './form_actions'

const { I18n } = window as any

type FormProps = {
  submitData: {
    url: string
    method: string
  }
  accessControl: {
    canCancelOrder: boolean
    canEditOrder: boolean
    canSendOrder: boolean
    canApproveOrder: boolean
  }
  elements: any
  editElement: (element: any) => void
  removeElement: (element: any) => void
  authenticityToken: string
  tableHeaderTitle: string
  deliveryDate: string
  deliveryAttention: string
  deliveryPhoneNumber: string
  deliveryInfo: string
  orderAddress: any
  locationAddress: any
  comment: string
  availableCountries: string[]
  removed: string[]
}

function inputName(name) {
  return `elements_order[${name}]`
}

function inputAttributeName(name, row) {
  return `elements_order[order_elements_attributes][${row}][${name}]`
}

export default function Form(props: FormProps) {
  const [validate, setValidate] = React.useState(false)
  const {
    elements,
    removeElement,
    editElement,
    removed,
    authenticityToken,
    tableHeaderTitle,
    accessControl,
    deliveryDate,
    deliveryAttention,
    deliveryPhoneNumber,
    deliveryInfo,
    orderAddress,
    locationAddress,
    comment,
    availableCountries,
  } = props
  const total = elements.reduce((a, b) => a + elementSubtotal(b), 0)
  const disableValidation = () => setValidate(false)
  const enableValidation = () => setValidate(true)

  const removedElements = removed.map((id, index) => {
    const row = elements.size + index
    return (
      <span key={id}>
        <input type="hidden" name={inputAttributeName('id', row)} value={id} />
        <input type="hidden" name={inputAttributeName('_destroy', row)} value="true" />
      </span>
    )
  })

  const {
    submitData: { url, method },
  } = props

  return (
    <div className="orders">
      <form
        className="default-form order-elements-form formtastic elements_order"
        action={url}
        acceptCharset="UTF-8"
        method="post"
        noValidate={!validate}
      >
        <input name="utf8" type="hidden" value="✓" />
        <input type="hidden" name="_method" value={method} />
        <input type="hidden" name="authenticity_token" value={authenticityToken} />
        <div className="order-inputs">
          <StockLevelWarning elements={elements} />
          <div className="table-header">
            <h3>
              {tableHeaderTitle}
              <TotalPrice total={total} />
            </h3>

            <div className="submit-top">
              <FormActions
                accessControl={accessControl}
                enableValidation={enableValidation}
                disableValidation={disableValidation}
                inputName={inputName}
              />
            </div>

            <table className="table table-sm mb-3">
              <thead>
                <tr>
                  <th>{I18n.t('activerecord.attributes.elements/base_element.amount')}</th>
                  <th>{I18n.t('elements.order_form.stock_level')}</th>
                  <th>{I18n.t('activerecord.attributes.elements/base_element.sub_group')}</th>
                  <th>{I18n.t('activerecord.attributes.elements/base_element.department')}</th>
                  <th>{I18n.t('activerecord.attributes.elements/base_element.sku')}</th>
                  <th>{I18n.t('activerecord.attributes.elements/base_element.name')}</th>
                  <th>{I18n.t('activerecord.attributes.elements/base_element.description')}</th>
                  <th>{I18n.t('activerecord.attributes.elements/base_element.width')}</th>
                  <th>{I18n.t('activerecord.attributes.elements/base_element.height')}</th>
                  <th>{I18n.t('activerecord.attributes.elements/base_element.photo')}</th>
                  <th>{I18n.t('activerecord.attributes.elements/base_element.price')}</th>
                  <th>{I18n.t('activerecord.attributes.elements/base_element.subtotal')}</th>
                  <th>{I18n.t('activerecord.attributes.elements/base_element.note')}</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                <ElementList
                  elements={elements}
                  editElement={editElement}
                  removeElement={removeElement}
                  inputAttributeName={inputAttributeName}
                />
              </tbody>
            </table>
            {removedElements}
            <OrderMeta
              inputName={inputName}
              deliveryDate={deliveryDate}
              deliveryAttention={deliveryAttention}
              deliveryPhoneNumber={deliveryPhoneNumber}
              deliveryInfo={deliveryInfo}
              orderAddress={orderAddress}
              locationAddress={locationAddress}
              orderComment={comment}
              availableCountries={availableCountries}
            />
            <div className="footer-price">
              <TotalPrice total={total} />
              <div className="total-title">{I18n.t('elements.order_form.order_total')}</div>
            </div>

            <div>
              <FormActions
                accessControl={accessControl}
                enableValidation={enableValidation}
                disableValidation={disableValidation}
                inputName={inputName}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
