import React from 'react'
import Projects from './step/projects'

const { I18n } = window

function LocationRows({ location, stepsProjects, grouping, filters, steps, emptyMultiStepProjects }) {
  const selectedLocationIds = filters.locations.map((loc) => loc.value)
  const selectedStatusIds = filters.statuses.map((s) => s.value)

  if (selectedLocationIds.length !== 0 && !selectedLocationIds.includes(location.id)) {
    return null
  }

  return (
    <>
      {grouping.map((groupingKey) => {
        return (
          <tr key={groupingKey}>
            <td>
              <a className="ml-2" href={location.href} target="_blank" rel="noopener noreferrer">
                {location.name}
              </a>
              {emptyMultiStepProjects.length > 0 && (
                <div className="alert alert-warning">
                  <i className="fa fa-icon fa-warning mr-2" />
                  { I18n.t('multi_step.empty_multi_step_projects')}:
                  <ul>
                    {emptyMultiStepProjects.map((msp) => {
                      return (
                        <li className="text-nowrap" key={msp.id}>
                          <a href={msp.url}>{msp.name}</a>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              )}
            </td>
            {steps.map((step) => {
              const projects = stepsProjects[step.id]
              const locationProjects = projects.reduce((result, project) => {
                if (project.location.id === location.id && project.multiStepProject.id === groupingKey) {
                  if (selectedStatusIds.length === 0 || selectedStatusIds.includes(project.statusId)) {
                    result.push(project)
                  }
                }
                return result
              }, [])

              return (
                <td key={step.id}>
                  <Projects items={locationProjects} />
                </td>
              )
            })}
          </tr>
        )
      })}
    </>
  )
}

export default LocationRows
