import { connect } from 'react-redux'
import { setHiddenFields } from 'actions/campaigns/summary_actions'

function mapStateToProps (state) {
  return {
  }
}

function mapDispatchToProps (dispatch) {
  return {
    setHiddenFields: (hiddenFields) => dispatch(setHiddenFields(hiddenFields)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)

