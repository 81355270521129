import * as React from 'react'
import { useState, useEffect } from 'react'
import store from '../../../../../stores/campaign_summary_amounts_store'

type ElementTotalPrintsProps = {
  elementId: number
  pdfPagesCount: number
  totalPrints: number
}
export default function ElementTotalPrints(props: ElementTotalPrintsProps) {
  const { elementId, pdfPagesCount, totalPrints } = props
  const [componentTotalPrints, updateComponentTotalPrints] = useState(totalPrints)

  useEffect(() => {
    const listener = store.addListener(`TotalWasChanged-${elementId}`, () => {
      const storeTotalPrints = store.totalPrintsFor(elementId, pdfPagesCount)
      updateComponentTotalPrints(storeTotalPrints)
    })
    return () => listener.remove()
  }, [])

  return (
    <div className="row">
      <div className="col-12 text-center">{componentTotalPrints}</div>
    </div>
  )
}
