import React from 'react'
import Select from 'components/shared/base_react_select'
import axios from 'axios'
import PropTypes from 'prop-types'
import Spinner from 'components/shared/spinner'
import ReactDOM from 'react-dom'

const { $ } = window

export default class CompanySelector extends React.Component {
  static propTypes = {
    currentCompanyId: PropTypes.number.isRequired,
    companies: PropTypes.array.isRequired,
    fetchFieldsUrl: PropTypes.string.isRequired,
  }

  constructor (props) {
    super(props)
    this.state = {
      currentCompanyId: this.props.currentCompanyId,
    }
  }

  componentDidMount () {
    this.$fieldsContainer = $('ul.form-fields')
    this.onChange({ value: this.state.currentCompanyId })
  }

  onChange = (opt) => {
    ReactDOM.render(<Spinner />, this.$fieldsContainer.get(0))

    const companyId = opt.value
    const url = this.props.fetchFieldsUrl + `?company_id=${companyId}`

    return axios({
      method: 'get',
      url,
      data: {
        company_id: companyId,
      },
    }).then((response) => {
      ReactDOM.unmountComponentAtNode(this.$fieldsContainer.get(0))
      this.insertAdditionalFields(response.data)
      this.setState({ currentCompanyId: companyId })
    }).catch(() => {
      ReactDOM.unmountComponentAtNode(this.$fieldsContainer.get(0))
    })
  }

  insertAdditionalFields (data) {
    const html = data.form_fields
    if (html) {
      this.$fieldsContainer.html(html)
      if (window.ReactRailsUJS) {
        window.ReactRailsUJS.mountComponents(this.$fieldsContainer.get(0))
      }
    }
  }

  render () {
    const { companies } = this.props
    const { currentCompanyId } = this.state

    return (
      <div>
        <Select
          value={companies.filter(option => option.value == currentCompanyId)}
          options={companies}
          onChange={this.onChange}
        />
        <input type="hidden" name="company_id" value={currentCompanyId || ''} />
      </div>
    )
  }
}
