import CustomDatatable from 'libs/datatables/custom_datatable'
import RequestPool from 'libs/datatables/request_pool'
import LoadingIndicator from './loading_indicator'

const { $, ReactRailsUJS } = window

export default class CustomDatatableReports extends CustomDatatable {
  constructor(table) {
    super()
    if (table.length === 0) return

    this.appendFilterInputs(table)

    const filterInputs = table.find('tr.filters th input')
    const loadingIndicator = new LoadingIndicator(table, filterInputs)
    const requestPool = new RequestPool()

    const options = this.datatableOptions(table, requestPool, loadingIndicator)

    const datatable = table.DataTable(options)
    this.bindFilterInputs(filterInputs, datatable, loadingIndicator, requestPool)
  }

  appendFilterInputs(table) {
    table.find('thead').addClass('filters')
    const search_tr = $('<tr/>').addClass('filters')
    table.find('thead tr th').each(function (index, th) {
      const search_input = $('<input />')
        .attr('type', 'text')
        .attr('name', `search[${$(th).data('column')}]`)
        .attr('placeholder', $(th).data('search-placeholder'))
        .addClass(`search_${$(th).data('column')}`)
      th = $('<th/>')
      th.append(search_input)
      const spinner = $('<span>').addClass('loading')
      th.append(spinner)
      search_tr.append(th)
    })

    table.find('thead').append(search_tr)
  }

  bindFilterInputs(filterInputs, datatable, loadingIndicator, requestPool) {
    const filterFunction = _.debounce((val, index) => {
      datatable.column(index).search(val, true, false).draw()
    }, 500)

    datatable
      .columns()
      .header()
      .each((headerCell, index) =>
        $(filterInputs.get(index)).on('keyup change', function () {
          loadingIndicator.show(index)
          requestPool.abortAll()
          filterFunction(this.value, index)
        })
      )
  }

  datatableOptions(table, requestPool, loadingIndicator) {
    const url = table.data('url')
    const cachedTotal = table.data('total')

    const fetchData = (data, callback, settings) =>  {
      requestPool.abortAll()

      const filtersData = table.data('externalFilters') || {}
      const requestData = { ...data, filters: filtersData }

      const request = $.ajax({
        url,
        data: requestData,
        method: 'POST',
        complete: (rq, status) => {
          requestPool.remove(rq)
        },
        success: (response) => {
          const totalCells = table.find('tfoot tr.totals td')
          if (totalCells.length && response.totals) {
            for (let idx = 0; idx < response.totals.length; idx++) {
              const total = response.totals[idx]
              $(totalCells.get(idx)).html(total)
            }
          }
          // Unlikely amount of rows (unfiltered) will change upon viewing report,
          // so to avoid calculating overal row count in each request
          // it is calculated on page load and stored in data-attribute
          response.recordsTotal = cachedTotal
          callback(response)
          if (response.needToMountReactComponents) {
            ReactRailsUJS.mountComponents('td .report-item-photos')
          }
          loadingIndicator.hide()
        },
        error: (response) => {
          if (response && response.responseJSON) {
            alert(response.responseJSON.message)
          }
          loadingIndicator.hide()
        },
      })

      requestPool.add(request)
    }

    // we should keep order, because datatables throws an error on search if no
    // sorting is defined
    const options = $.extend(this.defaultOptions(), {
      orderCellsTop: true,
      serverSide: true,
      ajax: fetchData,
      paging: true,
      info: true,
      dom: '<"table_wrapper"t><"datatables-pager"p>',
      language: {
        paginate: {
          next: '>',
          previous: '<',
        },
      },
      iDisplayLength: 20,
    })

    // fill in filter inputs upon state loading
    options['stateLoaded'] = (settings, state) => {
      if (state && state['columns']) {
        const { columns } = state
        $(table)
          .find('tr.filters th')
          .each(function (i, el) {
            const filterValue = columns[i]['search']['search']
            const input = $(el).find('input[type="text"]')
            if (filterValue) {
              input.val(filterValue)
            }
          })
      }
    }

    return options
  }
}
