import React from 'react'
import Spinner from 'components/shared/spinner'
import Form from './modal_form'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

const { I18n, toastr } = window

const showMessage  = () => window.toastr['success'](I18n.t('application.messages.you_will_notified_about_finish'))

const ModalWindow = ({ isFetching, toggleModal, isOpen, ...otherProps }) => {

  const sendData = (url, data) => {
    ajax({url: url, method: 'patch', data: data}).then(resp => {
      if (isOpen) { toggleModal() }
      showMessage()
    })
  }

  return (
    <Modal isOpen={isOpen} toggle={toggleModal} size="lg">
      <ModalHeader toggle={toggleModal}>
        Create projects for campaign elements { isFetching && <Spinner viewType="inline" size="1" />}
      </ModalHeader>
      <ModalBody>
        <Form {...otherProps} sendData={sendData} />
      </ModalBody>
    </Modal>
  )
}

export default ModalWindow
