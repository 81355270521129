import * as React from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { ProjectSurveyValuesShape, SurveyShape } from './survey_answer_form'
import Element from './element'
import useBranchingConditions from './use_branching_conditions'
import useDefaultValues from './use_default_values'
import CSRFInput from '../../shared/csrf'

type AnswerFormProps = {
  projectSurvey: { survey: SurveyShape }
  submitUrl: string
}
export default function AnswerForm({ projectSurvey, submitUrl }: AnswerFormProps) {
  const { survey } = projectSurvey
  const { elements } = survey
  const defaultValues = useDefaultValues(elements)
  const form = useForm<ProjectSurveyValuesShape>({ defaultValues })
  const { I18n } = window as any

  const { isVisible, fieldsRegistry } = useBranchingConditions(elements, form)

  return (
    <FormProvider {...form}>
      <form
        // onSubmit={form.handleSubmit(onSubmit)}
        action={submitUrl}
        method="post"
        className="surveys-answer-form default-form default-form-left-labeled surveys_answer"
      >
        <input type="hidden" name="_method" value="patch" />
        <CSRFInput />
        {elements.map((element, index) => (
          <Element
            visible={isVisible(element)}
            element={element}
            key={element.id}
            fieldsRegistry={fieldsRegistry}
            registerFormField={form.register}
          />
        ))}
        <button className="btn btn-primary" type="submit">
          {I18n.t('application.actions.save')}
        </button>
      </form>
    </FormProvider>
  )
}
