import * as React from 'react'
import { UseControllerReturn } from 'react-hook-form/dist/types/controller'
import { Controller, FieldPath, FieldValues } from 'react-hook-form'
import { WidgetFactoryInterface } from '../survey_answer_form'
import WorkingHoursInput from '../../../admin/working_hours'

type ControlledWorkingHoursProps = UseControllerReturn<FieldValues, FieldPath<FieldValues>> & {
  name: string
}

function ControlledWorkingHours(props: ControlledWorkingHoursProps) {
  const { name, field } = props
  const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
  const { I18n } = window as any
  const translatedDays = days.map((day) => {
    return { name: I18n.t(`days_of_the_week.${day}`), key: day }
  })
  const value = field.value || {}
  return <WorkingHoursInput days={translatedDays} fieldName={name} data={value} />
}

export default function WorkingHours({ field, fieldName }: WidgetFactoryInterface) {
  const { required } = field
  const fieldId = fieldName.replace(/[\[\]]/g, '_')

  return (
    <li className={`select input form-group ${required ? 'required' : ''}`}>
      <label htmlFor={fieldId} className="label">
        {field.name}
        {required && <abbr title="required">*</abbr>}
      </label>
      <div className="default-form-input-wrapper working_hours">
        <Controller
          render={(fieldProps) => <ControlledWorkingHours name={fieldName} {...fieldProps} />}
          name={fieldName}
        />
      </div>
    </li>
  )
}
