import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { List } from 'immutable'
import { DropTarget } from 'react-dnd';
import Column from './column'

const availableColumnsTarget = {
  drop(props) {
    return
  }
}

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  }
}

class AvailableColumns extends Component {
  static propTypes = {
    availableColumns: PropTypes.array.isRequired,
    translate: PropTypes.func.isRequired,
    dropToSelected: PropTypes.func.isRequired,
    dropToAvailable: PropTypes.func.isRequired,
  }

  render() {
    const { availableColumns, connectDropTarget, isOver, translate } = this.props
    return connectDropTarget && connectDropTarget(
      <div className="available-columns">
        {availableColumns.map( (name) => {
          return <Column name={name} translate={translate} key={name}
                    isSelected={ () => false }
                    dropToSelected={this.props.dropToSelected}
                    dropToAvailable={this.props.dropToAvailable}
                 />
        })}
      </div>
    )
  }
}

export default DropTarget('enabled-columns', availableColumnsTarget, collect)(AvailableColumns)
