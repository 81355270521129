import React from 'react'
import store from 'store'
import { Provider } from 'react-redux'
import CreatePostorderForm from './create_postorder_form'
import { initBaseState } from 'actions/campaign_postorders/create_postorder_form'

export default class CreatePostorderApp extends React.Component {

  constructor (props) {
    super(props)
    store.dispatch(initBaseState(props))
  }

  render () {
    return (
      <Provider store={store}>
        <CreatePostorderForm />
      </Provider>
    )
  }
}
