import React from 'react'
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';

const I18n = window.I18n

const isValidFormData = (data) => data.name && data.workflow

const CLEAR_FORM_STATE = { name: '', workflow: '' }

class CreateForm extends React.Component {

  constructor (props) {
    super(props)
    this.state = Object.assign({}, CLEAR_FORM_STATE)
  }

  onFormInputChange = (e) => {
    this.setState(Object.assign(this.state, { [e.target.name]: e.target.value }))
  }

  onSumbit = () => {
    this.props.createMspProject(
      this.props.current.get('projectId'),
      { name: this.state.name, workflow_id: this.state.workflow }
    )
    this.setState(Object.assign({}, CLEAR_FORM_STATE))
  }

  render () {
    const { mspWorkflows, current } = this.props
    const isReadyForSubmit = isValidFormData(this.state)
    return (
      <Form>
        <FormGroup>
          <Label for="new_msp_project_name">
            {I18n.t('activerecord.attributes.multi_step/project.name')}
          </Label>
          <Input type="text" id="new_msp_project_name" name="name" onChange={this.onFormInputChange} value={this.state.name} />
        </FormGroup>
        <FormGroup>
          <Label for="new_msp_project_workflow">
            {I18n.t('activerecord.models.multi_step/workflow.one')}
          </Label>
          <a href={`/admin/companies/${current.get('companyId')}/multi_step_workflows/new`}
            target="blank"
          >&nbsp;{I18n.t('application.actions.create_new', {name: I18n.t('activerecord.models.multi_step/workflow.one')}) }</a>
          <Input type="select" id="new_msp_project_workflow" ref="workflowInput" name="workflow" onChange={this.onFormInputChange} value={this.state.workflow}>
            <option value=""></option>
            { mspWorkflows.map(workflow =>
                <option key={`mspWorkflowKey-${workflow.get('id')}`}
                  value={workflow.get('id')}
                >
                  {workflow.get('name')}
                </option>
              )
            }
          </Input>
        </FormGroup>
        <Button color="primary" disabled={!isReadyForSubmit} onClick={this.onSumbit}>{I18n.t('application.actions.create')}</Button>
      </Form>
    )
  }
}

export default CreateForm
