import { connect } from 'react-redux'
import { getProjects } from 'actions/overview_actions'

export default connect(state => {
  return {
    projectGroups: state.getIn(['overview', 'projectGroups']).toJS(),
    mspWorkflows: state.getIn(['overview', 'mspWorkflows']).toJS(),
    projects: state.getIn(['overview', 'projects']).toJS()
  }
}, { getProjects })
