import PropTypes from 'prop-types'
import AlertIcons from 'components/campaigns/summary/icons'
import store from 'stores/campaign_summary_amounts_store'
import { snakeCase } from 'libs/helpers'

const I18n = window.I18n

const { AlertOctagonFill, AlertSquareFill, AlertTriangleFill } = AlertIcons

export const ERROR_TYPE_TO_ICON = {
  [store.config.errorTypes.minMax]: AlertOctagonFill,
  [store.config.errorTypes.tagsAmountsDuplicates]: AlertTriangleFill,
  [store.config.errorTypes.tagsAmountsConflicts]: AlertSquareFill,
}

const MinMaxError = ({ count, type }) => {
  if (!count) { return null }

  const IconView = ERROR_TYPE_TO_ICON[type]

  return (
    <div>
      <IconView />
      [{count}]&nbsp;
      {I18n.t(`campaigns.error_messages.${snakeCase(type)}`)}
    </div>
  )
}

const TagsAmountsDuplicates = ({ count, type }) => {
  if (!count) { return null }

  const IconView = ERROR_TYPE_TO_ICON[type]

  return (
    <div>
      <IconView />
      [{count}]&nbsp;
      {I18n.t(`campaigns.error_messages.${snakeCase(type)}`)}
    </div>
  )
}

const TagsAmountsConflicts = ({ count, type }) => {
  if (!count) { return null }

  const IconView = ERROR_TYPE_TO_ICON[type]

  return (
    <div>
      <IconView />
      [{count}]&nbsp;
      {I18n.t(`campaigns.error_messages.${snakeCase(type)}`)}
    </div>
  )

}

const ICON_WITH_MSG_PROP_TYPES = {
  count: PropTypes.number.isRequired,
  type: PropTypes.oneOf(Object.values(store.config.errorTypes))
}

MinMaxError.propTypes = ICON_WITH_MSG_PROP_TYPES
TagsAmountsDuplicates.propTypes = ICON_WITH_MSG_PROP_TYPES
TagsAmountsConflicts.propTypes = ICON_WITH_MSG_PROP_TYPES

export const ERROR_TYPE_TO_ICON_MSG = {
  [store.config.errorTypes.minMax]: MinMaxError,
  [store.config.errorTypes.tagsAmountsDuplicates]: TagsAmountsDuplicates,
  [store.config.errorTypes.tagsAmountsConflicts]: TagsAmountsConflicts,
}
