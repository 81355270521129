import React from 'react'
import ItemTypes from '../../lib/item_types'

const I18n = window.I18n

const RadioBtnSettings = ({ index, label, value, onChange, onClickDelete }) => {

  return (
    <fieldset className="radio-btn-setings">
      <legend>{index + 1}</legend>
      { index > 1 &&
        <div className="controls">
          <i className="fa fa-trash" onClick={() => onClickDelete(index)}></i>
        </div>
      }
      <div className="form-field">
        <input type="text" className="form-control" name={`label[${index}]`} value={label || ''} onChange={onChange} placeholder={I18n.t('application.titles.label')} />
      </div>
      <div className="form-field">
        <input type="text" className="form-control" name={`value[${index}]`} value={value || ''} onChange={onChange} placeholder={I18n.t('application.titles.value')} />
      </div>
    </fieldset>
  )
}

export default class RadioButtonsSettings extends React.Component {

  onChange = (e) => {
    const { item, path, handlers } = this.props
    handlers.updateItemAttr(e, item, ItemTypes.ELEMENT.type, this.props.path)
  }

  getCollection = () => {
    return this.props.item.collection
  }

  addNewRadioBtn = () => {
    let collection = this.getCollection()
    collection.push({ label: '', value: '' })
    const target = { name: 'collection', value: collection }

    this.onChange({ target })
  }

  updateRadioBtnSettings = (e) => {
    const [_, name, index] = /(.+)\[(\d+)\]/.exec(e.target.name)
    const value = e.target.value
    const collection = this.getCollection().reduce((result, itemSettings, collectionItemIndex) => {
      if (index == collectionItemIndex) {
        result.push(Object.assign({}, itemSettings, { [name]: value }))
      }
      else { result.push(itemSettings) }
      return result
    }, [])
    const target = { name: 'collection', value: collection }
    this.onChange({ target })
  }

  deleteRadioBtn = (index) => {
    const collection = this.getCollection()
    collection.splice(index, 1)
    const target = { name: 'collection', value: collection }
    this.onChange({ target })
  }

  handleChkValue = (e) => {
    const { name, value, checked } = e.target
    const target = { name,  value: (checked ? value : 0) }
    this.onChange({ target })
  }

  render () {
    const { header, defaultValue, display } = this.props.item
    const collection = this.getCollection()

    return (
      <div className="radio-buttons-settings row">
        <div className="col-12 form-group">
          <label className="label">{I18n.t('application.titles.header')}</label>
          <div className="default-form-input-wrapper">
            <input type="text" className="form-control" name="header" value={header || ''} onChange={this.onChange} />
          </div>
        </div>
        <div className="col-12 form-group">
          <label className="label">{I18n.t('handover_forms.elements.radio_buttons.select_default_value')}</label>
          <div className="default-form-input-wrapper">
            <select className="form-control" name="defaultValue" defaultValue={defaultValue || ''} onChange={this.onChange}>
              <option key="def-val-def"></option>
              { collection.map((itemSettings, idx) => (
                  <option key={`def-val-${idx}`} value={itemSettings.value}>{itemSettings.label}</option>
                ))
              }
            </select>
          </div>
        </div>
        <div className="col-12 form-group">
          <div className="form-check">
            <input type="checkbox" id="display-chk-field" name="display" className="form-check-input"
              defaultValue="inline"
              checked={display == 'inline'}
              onChange={this.handleChkValue}
            />
            <label htmlFor="display-chk-field" className="form-check-label">
              {I18n.t('handover_forms.elements.radio_buttons.inline')}
            </label>
          </div>
        </div>
        <div className="col-12">
          { collection.map((itemSettings, collectionItemIndex) => (
              <RadioBtnSettings key={`radio-btn-settings-${collectionItemIndex}`}
                index={collectionItemIndex}
                label={itemSettings.label}
                value={itemSettings.value}
                onChange={this.updateRadioBtnSettings}
                onClickDelete={this.deleteRadioBtn}
              />
            ))
          }
        </div>
        <div className="col-12">
          <button type="button" className="btn btn-secondary" onClick={this.addNewRadioBtn}>
            {I18n.t('application.actions.add')}
          </button>
        </div>
      </div>
    )
  }
}
