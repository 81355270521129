import Cell from './cell'

export default ({ row, path, fieldsAnswers }) => {
  const cells = row.cells.reduce((result, cell) => {
    if (cell._destroy) { return result }
    result.push(cell)
    return result
  }, [])

  return (
    <div className="row">
      { cells.map((cell, index) =>
          <Cell key={`cell-i-${index}`} cell={cell} path={[...path, index]} fieldsAnswers={fieldsAnswers} />
        )
      }
    </div>
  )
}
