import React from 'react'

const I18n = window.I18n

export default class SaveAndGoButton extends React.Component {
  render() {
    return (
      <button type="button" className="btn btn-primary" id="save_and_go_to_overview" onClick={this.props.onClick}>
        {I18n.t('campaigns.others.save_and_go_to_overview')}
      </button>
    )
  }
}
