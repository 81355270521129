import React, { Fragment } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import Spinner from 'components/shared/spinner'
import NewElementForm from './new_element/form'

const I18n = window.I18n

const BUTTON_CAPTION = I18n.t('application.actions.create_new', {
  name: I18n.t('activerecord.models.elements/base_element.one').toLowerCase()
})

class AddNewElement extends React.Component {

  state = { isOpen: false }

  toggle = () => this.setState(prevState => ({ isOpen: !prevState.isOpen }))
  close  = () => this.setState({ isOpen: false })

  render () {
    const { formData, createElement, isFetching } = this.props
    const needToResetForm = !!(this.isFetching && !isFetching)
    this.isFetching = isFetching

    return (
      <Fragment>
        <Button color="primary" onClick={this.toggle}>{BUTTON_CAPTION}</Button>
        <Modal isOpen={this.state.isOpen} toggle={this.toggle} className={this.props.className}>
          <ModalHeader toggle={this.toggle}>
            {I18n.t('application.actions.create_new', { name: I18n.t('activerecord.models.elements/base_element.one') })}
            { isFetching && <Spinner viewType="inline" size="1" />}
          </ModalHeader>
          <ModalBody>
            <NewElementForm fields={formData.get('fields')} closeModal={this.close} createElement={createElement} needToResetForm={needToResetForm} />
          </ModalBody>
        </Modal>
      </Fragment>
    )
  }
}

export default AddNewElement
