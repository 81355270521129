import { EventEmitter } from 'fbemitter'

class StateIndicatorStore extends EventEmitter {
  constructor () {
    super()
    this.options    = {
      'FavoritePhoto': { method: 'patch', attrName: 'favorite' }
    }
    this.indicators = { 'FavoritePhoto' : [] }
    this.records    = { }
  }

  initRecord (type, record) {
    if (!this.isEnabledFor(type, record)) {
      this.indicators[type].push(record.id)
    }
  }

  add (type, record) {
    this.sendReq(type, record, true).then(function (resp) {
      this.indicators[type].push(resp.data.id)
      this.update(type + resp.data.id)
      this.records[type] = resp.data.records
      this.loaded(type)
    }.bind(this))
  }

  remove (type, record) {
    this.sendReq(type, record, false).then(function (resp) {
      this.indicators[type] = _.without(this.indicators[type], resp.data.id)
      this.update(type + resp.data.id)
      this.records[type] = resp.data.records
      this.loaded(type)
    }.bind(this))
  }

  isEnabledFor (type, record) {
    if (this.indicators[type].indexOf(record.id) == -1) return false
    else return true
  }

  sendReq(type, record, value) {
    let options = this.options[type]
    let data = {}
    data[options.attrName] = value
    return ajax({ url: record.url, method: options.method, data: data})
  }

  update (type) {
    this.emit("change" + type)
  }

  loaded (type) {
    this.emit('loaded' + type)
  }
}

export default new StateIndicatorStore()
