import * as React from 'react'
import { UseControllerReturn } from 'react-hook-form/dist/types/controller'
import { Controller, FieldPath, FieldValues } from 'react-hook-form'
import { WidgetFactoryInterface } from '../survey_answer_form'

type ControlledAddressFieldsProps = UseControllerReturn<FieldValues, FieldPath<FieldValues>> & {
  name: string
  fieldId: string
}

function ControlledAddressFields(props: ControlledAddressFieldsProps) {
  const { name, fieldId, field } = props
  const address = field.value || {}
  return (
    <fieldset className="col-9">
      <input type="hidden" value={address.id || ''} name={`${name}[id]`} />
      <li className="string input form-group optional stringish">
        <label htmlFor={`${fieldId}_line1`} className="label">
          Line 1
        </label>
        <div className="default-form-input-wrapper">
          <input
            id={`${fieldId}_line1`}
            className="form-control"
            type="text"
            defaultValue={address.line1 || ''}
            name={`${name}[line1]`}
          />
        </div>
      </li>
      <li className="string input form-group optional stringish">
        <label htmlFor={`${fieldId}_line2`} className="label">
          Line 2
        </label>
        <div className="default-form-input-wrapper">
          <input
            id={`${fieldId}_line2`}
            className="form-control"
            type="text"
            defaultValue={address.line2 || ''}
            name={`${name}[line2]`}
          />
        </div>
      </li>
      <li className="string input form-group optional stringish">
        <label htmlFor={`${fieldId}_zip`} className="label">
          Zip
        </label>
        <div className="default-form-input-wrapper">
          <input
            id={`${fieldId}_zip`}
            className="form-control"
            type="text"
            defaultValue={address.zip || ''}
            name={`${name}[zip]`}
          />
        </div>
      </li>
      <li className="string input form-group optional stringish">
        <label htmlFor={`${fieldId}_city`} className="label">
          City
        </label>
        <div className="default-form-input-wrapper">
          <input
            id={`${fieldId}_city`}
            className=" form-control"
            type="text"
            defaultValue={address.city || ''}
            name={`${name}[city]`}
          />
        </div>
      </li>
      <li className="country input form-group optional">
        <label htmlFor={`${fieldId}_country`} className="label">
          Country
        </label>
        <div className="default-form-input-wrapper">
          <select
            id={`${fieldId}_country`}
            className="form-control"
            defaultValue={address.country || ''}
            name={`${name}[country]`}
          >
            <option />
            <option value="DK">Denmark</option>
            <option value="FI">Finland</option>
            <option value="NO">Norway</option>
            <option value="SE">Sweden</option>
            <option disabled value="---------------">
              ---------------
            </option>
            <option value="BE">Belgium</option>
            <option value="CZ">Czechia</option>
            <option value="EE">Estonia</option>
            <option value="FO">Faroe Islands</option>
            <option value="FR">France</option>
            <option value="DE">Germany</option>
            <option value="GL">Greenland</option>
            <option value="IS">Iceland</option>
            <option value="IE">Ireland</option>
            <option value="IT">Italy</option>
            <option value="LV">Latvia</option>
            <option value="LT">Lithuania</option>
            <option value="NL">Netherlands</option>
            <option value="PL">Poland</option>
            <option value="RU">Russian Federation</option>
            <option value="ES">Spain</option>
            <option value="CH">Switzerland</option>
            <option value="GB">United Kingdom</option>
          </select>
        </div>
      </li>
    </fieldset>
  )
}

export default function AddressFields({ field, fieldName }: WidgetFactoryInterface) {
  const { choices, required } = field
  const fieldId = fieldName.replace(/[\[\]]/g, '_')

  return (
    <li className={`address_fields input form-group ${required ? 'required' : ''}`}>
      <label htmlFor={fieldId} className="label">
        {field.name}
        {required && <abbr title="required">*</abbr>}
      </label>
      <Controller
        render={(fieldProps) => <ControlledAddressFields fieldId={fieldId} name={fieldName} {...fieldProps} />}
        name={fieldName}
      />
    </li>
  )
}
