export const RadioButtonCollection = ({ name, collection, onChange, isChecked }) => {

  return (
    <div className="form-group">
      { collection.map((item) => (
          <div className="form-check-inline" key={`radio-btn-collection-${item.value}`}>
            <label className="form-check-label">
              <input type="radio" className="form-check-input" name={name} value={item.value} checked={isChecked(item.value)} onChange={onChange} />
              {item.caption}
            </label>
          </div>
        )
      )}
    </div>
  )
}
