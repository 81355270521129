import ReactHover, { Hover, Trigger } from 'react-hover'
import React, { Fragment } from 'react'
import { CalendarElementColumns, CalendarHeadRows, CalendarHelper } from './calendar'
import ElementInfo from './element_info'

function CalendarTable(props) {
  const { startDate, endDate, groupedAvailableElements, elementGroups,
    handleElementClick, rentedItems, rentList } = props

  const cal = new CalendarHelper(startDate, endDate)

  return (
    <table className="rent-list-table table table-bordered table-hover">
      <thead>
        <CalendarHeadRows startDate={startDate} endDate={endDate} />
      </thead>
      <tbody>
        {Object.keys(groupedAvailableElements)
          .map(groupId => (
            <Fragment key={`group-${groupId}`}>
              <tr className="rent-list-table-group-row">
                <td className="rent-list-table-element-cell" colSpan={cal.totalDays()}>
                  <span className="rent-list-table-element-cell-content">
                    <b>{elementGroups[groupId]}</b>
                  </span>
                </td>
              </tr>
              {groupedAvailableElements[groupId].map(element => (
                <tr key={`element-${element.id}`} onClick={() => handleElementClick(element)}>
                  <td className="rent-list-table-element-cell">
                    <ReactHover options={{
                      followCursor: true,
                      shiftX: 20,
                      shiftY: 0 }}>
                      <Trigger type="trigger">
                        <span className="rent-list-table-element-cell-content">{element.name}</span>
                      </Trigger>
                      <Hover type="hover">
                        <ElementInfo element={element} />
                      </Hover>
                    </ReactHover>
                  </td>
                  <CalendarElementColumns
                    element={element}
                    startDate={startDate}
                    endDate={endDate}
                    rentedItems={rentedItems}
                    rentList={rentList}
                  />
                </tr>
              ))}
            </Fragment>
          ))}
      </tbody>
    </table>
  )
}

export default CalendarTable
