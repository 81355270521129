import { connect } from 'react-redux'
import {
  loadTemplates, loadTemplate, selectElementGroup, selectElementSubGroup,
  selectDepartment, searchTemplate, types
} from 'actions/elements_order'
import { createLoadingSelector } from 'libs/requests_status_handlers'

const loadingSelector = createLoadingSelector([types.LOAD_TEMPLATE])

function mapStateToProps (state) {
  return {
    dictionaries: {
      elementGroups: state.getIn(['elementsOrder', 'dictionaries', 'elementGroups']),
      elementSubGroups: state.getIn(['elementsOrder', 'dictionaries', 'elementSubGroups']),
      departments: state.getIn(['elementsOrder', 'dictionaries', 'departments']),
    },
    filtered: {
      departments: state.getIn(['elementsOrder', 'filtered', 'departments']),
      elementSubGroups: state.getIn(['elementsOrder', 'filtered', 'elementSubGroups']),
    },
    currentElementGroup: state.getIn(['elementsOrder', 'currentElementGroup']),
    currentDepartment: state.getIn(['elementsOrder', 'currentDepartment']),
    currentElementSubGroup: state.getIn(['elementsOrder', 'currentElementSubGroup']),
    currentTemplate: state.getIn(['elementsOrder', 'currentTemplate']),
    currentTemplateLoading: loadingSelector(state),
    currentTemplateData: state.getIn(['elementsOrder', 'currentTemplateData']),
    searchTerm: state.getIn(['elementsOrder', 'searchTerm']),
    templates: state.getIn(['elementsOrder', 'templates']),
    templatesLoading: state.getIn(['elementsOrder', 'templatesLoading']),
  }
}

function mapDispatchToProps (dispatch) {
  return {
    loadTemplates: filterParams => dispatch(loadTemplates(filterParams)),
    loadTemplate: templateId => dispatch(loadTemplate(templateId)),
    selectElementGroup: elementGroupId => dispatch(selectElementGroup(elementGroupId)),
    selectElementSubGroup: elementSubGroupId => dispatch(selectElementSubGroup(elementSubGroupId)),
    selectDepartment: departmentId => dispatch(selectDepartment(departmentId)),
    searchTemplate: searchTerm => dispatch(searchTemplate(searchTerm)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
