class ItemType {
  constructor (type, name, initStruct, options = {}) {
    this.type = type
    this._name = name
    this.init = initStruct
    this.options = options
  }

  name = (index = '-') => `${this._name} ${index}`
}

const ELEMENT_VARIANTS = {
  HEADER: 'header',
  PARAGRAPH: 'paragraph',
  IMAGE: 'image',
  DATE_PICKER: 'date-picker',
  SIGNATURE: 'signature',
  TEXT_INPUT: 'text-input',
  CHECKBOX_INPUT: 'checkbox-input',
  RADIO_BUTTONS: 'radio-buttons',
  TEXTAREA: 'textarea',
  SUBMIT_BTN: 'submit-btn'
}

const INVERT_ELEMENT_VARIANTS = Object.keys(ELEMENT_VARIANTS).reduce((result, key) => {
  result[ELEMENT_VARIANTS[key]] = key
  return result
}, {})

const ELEMENT_DEFAULT_OPTIONS = {
  [ELEMENT_VARIANTS.HEADER]: { caption: 'Header', size: '3' },
  [ELEMENT_VARIANTS.PARAGRAPH]: { },
  [ELEMENT_VARIANTS.IMAGE]: { },
  [ELEMENT_VARIANTS.DATE_PICKER]: { },
  [ELEMENT_VARIANTS.SIGNATURE]: { },
  [ELEMENT_VARIANTS.TEXT_INPUT]: { },
  [ELEMENT_VARIANTS.CHECKBOX_INPUT]: { },
  [ELEMENT_VARIANTS.TEXTAREA]: { },
  [ELEMENT_VARIANTS.RADIO_BUTTONS]: { header: '', collection: [{ label: 'Yes', value: '1' }, { label: 'No', value: '0' }] },
  [ELEMENT_VARIANTS.SUBMIT_BTN]: { caption: 'Save button' },
}

export const getElementDefaultOptionByType = (elementType) => {
  return ELEMENT_DEFAULT_OPTIONS[elementType]
}

export const getElementDefaultName = (elementType) => {
  const elementKey = INVERT_ELEMENT_VARIANTS[elementType]
  return  elementKey
}

import AllElementsSettings from '../settings_bar/elements'

export const getElementSettingsComponentByType = (elementType) => {
  switch (elementType) {
    case ELEMENT_VARIANTS.HEADER:
      return AllElementsSettings.HeaderSettings
    case ELEMENT_VARIANTS.PARAGRAPH:
      return AllElementsSettings.ParagraphSettings
    case ELEMENT_VARIANTS.IMAGE:
      return AllElementsSettings.ImageSettings
    case ELEMENT_VARIANTS.DATE_PICKER:
      return AllElementsSettings.DatePickerSettings
    case ELEMENT_VARIANTS.SIGNATURE:
      return AllElementsSettings.SignatureSettings
    case ELEMENT_VARIANTS.TEXT_INPUT:
      return AllElementsSettings.TextInputSettings
    case ELEMENT_VARIANTS.CHECKBOX_INPUT:
      return AllElementsSettings.CheckboxInputSettings
    case ELEMENT_VARIANTS.RADIO_BUTTONS:
      return AllElementsSettings.RadioButtonsSettings
    case ELEMENT_VARIANTS.TEXTAREA:
      return AllElementsSettings.TextareaSettings
    case ELEMENT_VARIANTS.SUBMIT_BTN:
      return AllElementsSettings.SubmitBtnSettings
  }
}

import AllElementsItems from '../items/elements'
export const getElementItemComponentByType = (elementType) => {
  switch (elementType) {
    case ELEMENT_VARIANTS.HEADER:
      return AllElementsItems.Header
    case ELEMENT_VARIANTS.PARAGRAPH:
      return AllElementsItems.Paragraph
    case ELEMENT_VARIANTS.IMAGE:
      return AllElementsItems.Image
    case ELEMENT_VARIANTS.DATE_PICKER:
      return AllElementsItems.DatePicker
    case ELEMENT_VARIANTS.SIGNATURE:
      return AllElementsItems.Signature
    case ELEMENT_VARIANTS.TEXT_INPUT:
      return AllElementsItems.TextInput
    case ELEMENT_VARIANTS.CHECKBOX_INPUT:
      return AllElementsItems.CheckboxInput
    case ELEMENT_VARIANTS.RADIO_BUTTONS:
      return AllElementsItems.RadioButtons
    case ELEMENT_VARIANTS.TEXTAREA:
      return AllElementsItems.Textarea
    case ELEMENT_VARIANTS.SUBMIT_BTN:
      return AllElementsItems.SubmitBtn
  }
}

const ItemTypes = {
  ROW: new ItemType('row-item', 'Row', { cells: [{}] }),
  CELL: new ItemType('cell-item', 'Cell', { elements: [] }),
  ELEMENT: new ItemType('element-item', 'ELEMENT', {}, { variants: ELEMENT_VARIANTS })
}

export default ItemTypes
