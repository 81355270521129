import { Fragment } from 'react'

const I18n = window.I18n

const SELECT_GROUP_CAPTION = I18n.t('application.actions.select_what', {
  what: I18n.t('activerecord.models.project_group.one').toLowerCase()
})

const CREATE_GROUP_CAPTION = [
  I18n.t('application.actions.create'),
  I18n.t('activerecord.models.project_group.one').toLowerCase()
].join(' ')

export default ({ onChange, activeFormType }) => {

  return (
    <div className="row col-12 mb-4 text-center">
      <div className="form-check form-check-inline">
        <input className="form-check-input" type="radio" name="activeFormType" id="select_group_form"  value="selectGroupForm"
          onChange={onChange} checked={activeFormType == 'selectGroupForm'}
        />
        <label className="form-check-label" htmlFor="select_group_form">{SELECT_GROUP_CAPTION}</label>
      </div>
      <div className="form-check form-check-inline">
        <input className="form-check-input" type="radio" name="activeFormType" id="create_group_form" value="createGroupForm"
          onChange={onChange} checked={activeFormType == 'createGroupForm'}
        />
        <label className="form-check-label" htmlFor="create_group_form">{CREATE_GROUP_CAPTION}</label>
      </div>
    </div>
  )
}
