import * as React from 'react'
import { Controller, FieldPath, FieldValues } from 'react-hook-form'
import { UseControllerReturn } from 'react-hook-form/dist/types/controller'
import { UploaderFieldInterface } from '../survey_answer_form'
import UploaderV2 from '../../../uploader_v2_app/uploader'

function toUnderscore(str: string) {
  return str.replace(/([\[\]])/g, '_').replace(/([A-Z])/g, (match) => `_${match.toLowerCase()}`)
}

type ControlledFieldProps = UseControllerReturn<FieldValues, FieldPath<FieldValues>> & {
  buttonId: string
  dropElementId: string
  name: string
}

function ControlledUploader(props: ControlledFieldProps) {
  const { useMemo } = React

  const [removedFiles, setRemovedFiles] = React.useState([])
  const { buttonId, dropElementId, field, name } = props
  const files = field.value || []

  const uploaderProps = useMemo(
    () => ({
      view: 'FormInput',
      buttonId,
      dropElementId,
      multiple: true,
      modelData: {
        id: 'new',
        name,
        attribute: 'answer_photos',
      },
      filesOptions: {
        editableDescription: false,
        editableTitle: false,
        onlyImages: true,
      },
      files,
      pluploadWrapperActions: {
        addNewFiles: (_, newFiles) => {
          field.onChange([...files, ...newFiles])
        },
        fileUploaded: (_, data: { id: string; url: string }) => {
          field.onChange(files.map((file) => (file.id === data.id ? { ...file, src: data.url, _new: true } : file)))
        },
      },
      fileActions: {
        removeFile: (_, fileId) => {
          const file = files.find((f) => f.id === fileId)
          if (!file['_new']) {
            setRemovedFiles([...removedFiles, fileId])
          }
          field.onChange(files.filter((f) => f.id !== fileId))
        },
        updateFile: (imgId, data) => {
          field.onChange(files.map((file) => (file.id === imgId ? { ...file, ...data } : file)))
        },
      },
    }),
    [files]
  )

  return (
    <>
      {removedFiles.map((fileId, index) => (
        <React.Fragment key={fileId}>
          <input type="hidden" name={`${name}[answer_photos_attributes][${index}][_destroy]`} value="1" />
          <input type="hidden" name={`${name}[answer_photos_attributes][${index}][id]`} value={fileId} />
        </React.Fragment>
      ))}
      <UploaderV2 {...uploaderProps} />
    </>
  )
}

export default function Uploader(props: UploaderFieldInterface) {
  const { name, fieldName } = props
  const buttonId = `${toUnderscore(name)}_uploader_button`
  const dropElementId = `${toUnderscore(name)}_uploader_drop_zone`

  // In the original uploader the name is "surveys_answer[answer_photos_attributes][0][temp_file]"
  return (
    <li className="uploader_v2 input form-group optional" id="surveys_answer_answer_photos_input">
      <label htmlFor="surveys_answer_answer_photo_ids" className="label">
        Photos
      </label>
      <div className="default-form-input-wrapper uploader-input uploader-v2">
        <Controller
          render={(fieldProps) => (
            <ControlledUploader buttonId={buttonId} dropElementId={dropElementId} name={fieldName} {...fieldProps} />
          )}
          name={name}
        />
      </div>
    </li>
  )
}
