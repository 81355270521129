import { useMemo } from 'react'
import { UseFormReturn } from 'react-hook-form'
import {
  FormRegistryShape,
  ProjectSurveyValuesShape,
  SurveyElementFieldChoiceShape,
  SurveyElementShape,
} from './survey_answer_form'

function selectChoicesWithConditions(elements: SurveyElementShape[]): SurveyElementFieldChoiceShape[] {
  const conditionFields = []
  elements.forEach((el) => {
    el.fields.forEach((field) => {
      if (field.choices && field.choices.length > 0) {
        field.choices.forEach((choice) => {
          if (choice.next_step_element_id !== null) {
            conditionFields.push(choice)
          }
        })
      }
    })
  })
  return conditionFields
}

function formRegistryFromElements(elements: SurveyElementShape[]): FormRegistryShape {
  const elementsRegistry = {}
  const fieldsRegistry = {}

  elements.forEach((element, index) => {
    elementsRegistry[element.id] = {
      answerIdName: `answers_attributes[${index}][id]`,
      elementIdName: `answers_attributes[${index}][surveys_element_id]`,
      uploaderName: `answers_attributes[${index}][answer_photos_attributes]`,
      uploaderFieldName: `answers_attributes[${index}]`,
    }

    element.fields.forEach((field, fieldIndex) => {
      let fieldName = `answers_attributes[${index}][field_answers_attributes][${fieldIndex}][value]`
      if (field.widget === 'check_boxes') {
        fieldName = `${fieldName}[]`
      }
      fieldsRegistry[field.id] = {
        fieldAnswerIdName: `answers_attributes[${index}][field_answers_attributes][${fieldIndex}][id]`,
        fieldIdName: `answers_attributes[${index}][field_answers_attributes][${fieldIndex}][surveys_field_id]`,
        fieldName,
      }
    })
  })

  return {
    elements: elementsRegistry,
    fields: fieldsRegistry,
  }
}

function selectControllingFieldNames(conditionalChoices: SurveyElementFieldChoiceShape[], registry: FormRegistryShape) {
  const names = []
  conditionalChoices.forEach((choice) => {
    if (choice.surveys_field_id) {
      const name = registry.fields[choice.surveys_field_id].fieldName
      names.push(name)
    }
  })
  return names
}

export default function useBranchingConditions(
  elements: SurveyElementShape[],
  form: UseFormReturn<ProjectSurveyValuesShape, any>
) {
  const conditionalChoices = useMemo(() => selectChoicesWithConditions(elements), [elements])
  const fieldsRegistry = useMemo(() => formRegistryFromElements(elements), [elements])

  const controllingFieldNames = selectControllingFieldNames(conditionalChoices, fieldsRegistry)
  form.watch(controllingFieldNames)
  const values = form.getValues()

  const isVisible = (element: SurveyElementShape) => {
    const elementIsInConditionChoices = conditionalChoices.some((choice) => {
      if (!choice.next_step_element_id) {
        return false
      }
      return choice.next_step_element_id.toString() === element.id.toString()
    })
    if (!elementIsInConditionChoices) {
      return true
    }

    // choices that have this element as a dependent
    const filteredChoices = conditionalChoices.filter((choice) => choice.next_step_element_id === element.id)

    let hiddenByBranchingConditions = true
    filteredChoices.forEach((choice) => {
      // find in values a field that matches the choice's dependent field
      const answerAttributes = values.answers_attributes
      if (answerAttributes) {
        answerAttributes.forEach((answer) => {
          const fieldAttributes = answer.field_answers_attributes
          if (fieldAttributes) {
            fieldAttributes.forEach((field) => {
              if (field.surveys_field_id === choice.surveys_field_id.toString()) {
                // if the value of the field matches the choice's value, then the element is visible
                if (Array.isArray(field.value) && field.value.includes(choice.id.toString())) {
                  hiddenByBranchingConditions = false
                } else if (field.value === choice.id.toString()) {
                  hiddenByBranchingConditions = false
                }
              }
            })
          }
        })
      }
    })

    return !hiddenByBranchingConditions
  }

  return { isVisible, fieldsRegistry }
}
