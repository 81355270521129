import { connect } from 'react-redux'
import { types } from 'actions/projects/msp_step_button_actions'
import { createLoadingSelector } from 'libs/requests_status_handlers'

const loadingSelector = createLoadingSelector([
  types.CREATE_MSP_PROJECT,
  types.LOAD_MSP_PROJECT_STEPS
])

export default connect((state) => ({
  isFetching: loadingSelector(state)
}), { })
