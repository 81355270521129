export const COLLAPSE_EXPAND_STATES = { collapsed: 'collapsed', expanded: 'expanded' }

const Expand = ({ changeAction, ...props }) => {

  const onClick = () => changeAction(COLLAPSE_EXPAND_STATES.expanded, props)

  return (
    <i className="fa fa-chevron-down ml-2 js-icon-expand" onClick={onClick}></i>
  )
}

const Collapse = ({ changeAction, ...props }) => {

  const onClick = () => changeAction(COLLAPSE_EXPAND_STATES.collapsed, props)

  return (
    <i className="fa fa-chevron-up ml-2 js-icon-collapse" onClick={onClick}></i>
  )
}

export const isExpanded = (currentState) => (currentState == COLLAPSE_EXPAND_STATES.expanded)

export default ({ currentState, changeAction, ...props }) => {
  if (!currentState) { return null }

  if (currentState == COLLAPSE_EXPAND_STATES.collapsed) {
    return <Expand changeAction={changeAction} {...props} />
  }

  return <Collapse changeAction={changeAction} {...props} />
}
