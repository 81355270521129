import React, { useEffect, useRef } from 'react'

const I18n = window.I18n

const UpdateTableBtn = () => {
  const buttonRef = useRef(null)

  useEffect(() => {
    const handleClick = (e) => {
      e.preventDefault()
      const form = document.querySelector('[id^="edit_campaign"]')
      if (form) {
        const hiddenInput = document.createElement('input')
        hiddenInput.type = 'hidden'
        hiddenInput.name = 'step_action'
        hiddenInput.value = 'only_save_on_summary'

        form.appendChild(hiddenInput)

        form.submit()
      }
    }

    const button = buttonRef.current
    if (button) {
      button.addEventListener('click', handleClick)
    }

    return () => {
      if (button) {
        button.removeEventListener('click', handleClick)
      }
    }
  }, [])

  return (
    <button className="btn btn-primary" id="save_with_step_action" ref={buttonRef}>
      {I18n.t('campaigns.others.save_and_update')}
    </button>
  )
}

export default UpdateTableBtn
