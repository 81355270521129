import Index from './list'
import Show from './show'
import Summary from './summary'
import FormFields from './form_fields'
import ChangesMonitor from './helpers/changes_monitor'

export default {
  Show,
  Index,
  Summary,
  FormFields,
  ChangesMonitor
}
