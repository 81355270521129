import React, { Fragment } from 'react'
import ElementsSelector from './selector'
import ElementItemInterface from './selector/element_item_interface'
import CompositElementID from 'models/campaigns/composit_element_id'

const itemInterface = new ElementItemInterface()

const I18n = window.I18n

const Placeholder = I18n.t('application.actions.select_what', {
  what: I18n.t('activerecord.models.elements/location_element.other').toLowerCase()
}) + '...'

class Selector extends React.Component {

  constructor (props) {
    super(props)
    this.state = { values: [] }
  }

  onChange = (opt) => {
    this.props.selectItems([
      new CompositElementID(opt.campaignElementID, opt.value, opt.type, null)
    ])
  }

  render () {
    const options = this.props.items
    return (
      <Fragment>
        <ElementsSelector
          itemInterface={itemInterface}
          placeholder={Placeholder}
          values={this.state.values} options={options} onChange={this.onChange}
        />
      </Fragment>
    )
  }
}

export default Selector
