import ExpenseCategory from './_expense_categories'

export default function () {
  const { $ } = window
  $(() => {
    const category_select = $('#expenses_project_expense_expense_category_id')
    category_select.on('change', function() {
      const value = $(this).val()
      if (!value) { return }
      const option = $(this).find(`option[value=${value}]`)
      const type = option.data('type')
      const category = ExpenseCategory.fromType(type, this)
      category.renderInputs()

      const rateSelect = $('#expenses_project_expense_rate_input')
      const worktypeSelect = $('#expenses_project_expense_worktype_input')
      switch (type) {
        case 'financial':
          rateSelect.show()
          worktypeSelect.hide()
          break
        case 'freeprice':
          rateSelect.hide()
          worktypeSelect.hide()
          break
        case 'time':
          rateSelect.hide()
          worktypeSelect.show()
          break
        default:
          rateSelect.show()
          worktypeSelect.show()
      }
    })

    category_select.trigger('change')
  })
}
