import React from 'react'
import ItemTypes from '../../lib/item_types'

const I18n = window.I18n

export default class ImageSettings extends React.Component {

  onChange = (e) => {
    const { item, path, handlers } = this.props
    handlers.updateItemAttr(e, item, ItemTypes.ELEMENT.type, path)
  }

  render () {
    const { src, width, height } = this.props.item

    return (
      <div className="image-settings">
        <div className="form-group">
          <label>{I18n.t('application.titles.url')}</label>
          <input type="text" className="form-control" name="src" value={src || ''} onChange={this.onChange} />
        </div>
        <div className="form-group">
          <label>{I18n.t('application.titles.width')}(%)</label>
          <input type="text"  className="form-control" name="width" value={width || ''} onChange={this.onChange} />
        </div>
      </div>
    )
  }
}
