import React, { useRef } from 'react'

const { I18n } = window

export default function ResourceUrl({ currentLink, resourceUrlTitle }) {
  const linkInput = useRef(null)

  if (!currentLink.resourceUrl) {
    return ''
  }

  const copyToClipboard = () => {
    linkInput.current.focus()
    linkInput.current.select()

    try {
      document.execCommand('copy')
    } catch (err) {
      console.log('Can not copy selection!')
    }
  }

  return (
    <div className="form-group">
      <label className="label" htmlFor="sharing-link-url">
        {resourceUrlTitle}
      </label>
      <div className="d-flex">
        <input
          ref={linkInput}
          value={currentLink.resourceUrl}
          type="text"
          className="form-control"
          readOnly
          name="sharing_link[url]"
          id="sharing-link-url"
        />
        <button className="btn btn-small btn-secondary ml-2" type="button" onClick={copyToClipboard}>
          {I18n.t('application.actions.copy')}
        </button>
      </div>
    </div>
  )
}
