import React from 'react'
import ElementSelector from './element_selector'
import ElementsReadyList from './elements_ready_list'
import ItemTypes, { getElementDefaultOptionByType } from '../../lib/item_types'

const I18n = window.I18n

export default class CellSettings extends React.Component {

  static type = ItemTypes.CELL

  getCurrentItem = () => this.props.item || Object.assign({}, ItemTypes.CELL.initStruct)

  addElement = (opt) => {
    const cell = this.getCurrentItem()
    if (!cell.elements) { cell.elements = [] }
    cell.elements.push(
      Object.assign({}, getElementDefaultOptionByType(opt.id), {type: opt.id, caption: opt.label})
    )
    this.updateItem(cell)
  }

  applyCssSettings = (settings) => {
    const cell = this.getCurrentItem()
    if (!cell.options) { cell.options = {} }
    cell.options = Object.assign({}, cell.options, { css: settings })
    this.updateItem(cell)
  }

  updateItem = (data) => {
    this.props.handlers.updateItem(ItemTypes.CELL.type, data, this.props.path)
  }

  render () {
    const cell = this.props.item
    const elements = (cell.elements || []).reduce((result, el) => {
      if (!el._destroy) { result.push(el) }
      return result
    }, [])

    const cssOptions = cell.options?.css || {}

    const CssCollector = this.props.CssCollector

    return (
      <div className="cell-settings">
        <CssCollector controls={['textAligment']} onChange={this.applyCssSettings} {...cssOptions} />
        <div className="form-group">
          <label className="label">{I18n.t('handover_forms.items.element')}</label>
          <div className="default-form-input-wrapper">
            <ElementSelector key="selector" addElement={this.addElement} />
          </div>
        </div>
        <div className="form-group">
          <label className="label"></label>
          <div className="default-form-input-wrapper">
            <ElementsReadyList key="list" list={elements} path={this.props.path} handlers={this.props.handlers} />
          </div>
        </div>
      </div>
    )
  }
}
