import React from 'react'

import HeaderSettings from './header_settings'
import ParagraphSettings from './paragraph_settings'
import ImageSettings from './image_settings'
import DatePickerSettings from './date_picker_settings'
import SignatureSettings from './signature_settings'
import TextInputSettings from './text_input_settings'
import CheckboxInputSettings from './checkbox_input_settings'
import RadioButtonsSettings from './radio_buttons_settings'
import TextareaSettings from './textarea_settings'
import SubmitBtnSettings from './submit_btn_settings'

export default {
  HeaderSettings,
  ParagraphSettings,
  ImageSettings,
  DatePickerSettings,
  SignatureSettings,
  TextInputSettings,
  CheckboxInputSettings,
  RadioButtonsSettings,
  TextareaSettings,
  SubmitBtnSettings
}
