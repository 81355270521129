import SelectGroupForm from './select_group_form'
import CreateGroupForm from './create_group_form'
import ViewSelector from './view_selector'

export const FormViewSelector = ViewSelector

export default {
  selectGroupForm: SelectGroupForm,
  createGroupForm: CreateGroupForm,
}
