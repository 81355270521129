import React from 'react'
import PropTypes from 'prop-types'
import List from 'immutable'
import SpinnerOverlay from 'components/shared/spinner_overlay'

class FilterSelect extends React.Component {
  static propTypes = {
    elementId: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    blankLabel: PropTypes.string.isRequired,
    size: PropTypes.number,
    searchEnabled: PropTypes.bool,
    searchTerm: PropTypes.string,
    selectOptions: PropTypes.object,
    showOverlay: PropTypes.bool,
    selectedValue: PropTypes.number,
  }

  static defaultProps = {
    showOverlay: false,
    selectedValue: '',
  }

  optionsFromArray () {
    const { selectOptions, searchTerm } = this.props
    if (selectOptions !== undefined) {
      let filtered = selectOptions
      if (searchTerm) {
        const filterFn = (o) => {
          const regexp = new RegExp(`${searchTerm}`, 'i')
          return regexp.test(o.get(0))
        }
        filtered = selectOptions.filter(filterFn)
      }
      return filtered.map(o => <option key={o.get(1)} value={o.get(1)}>{o.get(0)}</option>)
    }
    return ''
  }

  searchField () {
    if (this.props.searchEnabled == true) {
      return (
        <div className="filter-select-search mb-2">
          <input className="form-control"
            type="text"
            defaultValue={ this.props.searchTerm }
            onChange={ (e) => this.props.handleSearchChange(e.target.value) }
          />
        </div>
      )
    }
  }

  selectSize () {
    const { size, searchEnabled } = this.props
    return searchEnabled ? (size - 2) : size
  }

  render () {
    return (
      <div className="filter-select">
        <div className="label">
          <label htmlFor={this.props.elementId}>
            {this.props.label}
          </label>
        </div>
        <SpinnerOverlay isActive={this.props.showOverlay} >
          <div>
            {this.searchField()}
            <select className="form-control" id={this.props.elementId}
              size={this.selectSize()}
              value={this.props.selectedValue || ''}
              onChange={e => this.props.handleChange(e.target.value)}
            >
              <option>{this.props.blankLabel}</option>
              {this.optionsFromArray()}
            </select>
          </div>
        </SpinnerOverlay>
      </div>
    )
  }
}

export default FilterSelect
