import * as React from 'react'
import { useState } from 'react'
import DateTimeInput from '../../shared/date_time_input'

const { I18n } = window as any
type RescheduleButtonProps = {
  date: string
  authenticityToken: string
  timeZone: string
}

export default function RescheduleButtonApp(props: RescheduleButtonProps) {
  const { date, authenticityToken, timeZone } = props
  const [showForm, setShowForm] = useState(false)
  const label = date ? I18n.t('project_tickets.actions.reschedule') : I18n.t('project_tickets.actions.schedule')
  return (
    <div className="reschedule-button">
      {date && <span className="mr-2">{date}</span>}
      {!showForm && (
        <button
          type="button"
          className="button reschedule btn btn-secondary"
          onClick={() => {
            setShowForm(true)
          }}
        >
          {label}
        </button>
      )}
      {showForm && (
        <div className="comment book_another_date">
          <form className="default-form" method="post">
            <input type="hidden" name="_method" value="patch" autoComplete="off" />
            <input type="hidden" name="authenticity_token" value={authenticityToken} />
            <li className="datetime input form-group optional" id="project_ticket_installer_visit_date_input">
              <label htmlFor="project_ticket_installer_visit_date" className="label">
                {I18n.t('activerecord.attributes.project_ticket.installer_visit_date')}
              </label>
              <DateTimeInput
                name="project_ticket[installer_visit_date]"
                value={date}
                timeZone={timeZone}
                showTimeSelect
              />
            </li>
            <li className="action input_action form-group" id="project_ticket_submit_action">
              <div className="default-form-input-wrapper">
                <input
                  type="submit"
                  name="commit"
                  value={I18n.t('project_tickets.actions.book')}
                  className="btn btn-primary"
                  data-disable-with={I18n.t('project_tickets.actions.book')}
                />
              </div>
            </li>
          </form>
        </div>
      )}
    </div>
  )
}
