import React from 'react'
import store from 'store'
import { Provider } from 'react-redux'
import ElementTotalPrints from './element_total_prints'

export default class ElementTotalPrintsApp extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <ElementTotalPrints {...this.props}/>
      </Provider>
    )
  }
}
