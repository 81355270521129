import { connect } from 'react-redux'
import {
  loadInstalledElements, loadTemplate, selectElementGroup, selectElementSubGroup,
  selectDepartment, searchTemplate, types, loadInstalledElement,
} from '../../../../actions/elements_order'
import { createLoadingSelector } from '../../../../libs/requests_status_handlers'

const loadingSelector = createLoadingSelector([types.LOAD_INSTALLED_ELEMENT])

function mapStateToProps (state) {
  return {
    dictionaries: {
      locationGroups: state.getIn(['elementsOrder', 'dictionaries', 'locationGroups']),
      locationSubGroups: state.getIn(['elementsOrder', 'dictionaries', 'locationSubGroups']),
      locationDepartments: state.getIn(['elementsOrder', 'dictionaries', 'locationDepartments']),
    },
    filtered: {
      departments: state.getIn(['elementsOrder', 'filtered', 'departments']),
      elementSubGroups: state.getIn(['elementsOrder', 'filtered', 'elementSubGroups']),
    },
    currentElementGroup: state.getIn(['elementsOrder', 'currentElementGroup']),
    currentDepartment: state.getIn(['elementsOrder', 'currentDepartment']),
    currentElementSubGroup: state.getIn(['elementsOrder', 'currentElementSubGroup']),
    currentReplaceElementId: state.getIn(['elementsOrder', 'currentReplaceElementId']),
    currentTemplateLoading: loadingSelector(state),
    currentTemplateData: state.getIn(['elementsOrder', 'currentTemplateData']),
    searchTerm: state.getIn(['elementsOrder', 'searchTerm']),
    installedElements: state.getIn(['elementsOrder', 'installedElements']),
    installedElementsLoading: state.getIn(['elementsOrder', 'installedElementsLoading']),
  }
}

function mapDispatchToProps (dispatch) {
  return {
    loadInstalledElements: (locationId, filterParams) => dispatch(loadInstalledElements(locationId, filterParams)),
    loadInstalledElement: (locationId, elementId) => dispatch(loadInstalledElement(locationId, elementId)),
    selectElementGroup: elementGroupId => dispatch(selectElementGroup(elementGroupId)),
    selectElementSubGroup: elementSubGroupId => dispatch(selectElementSubGroup(elementSubGroupId)),
    selectDepartment: departmentId => dispatch(selectDepartment(departmentId)),
    searchTemplate: searchTerm => dispatch(searchTemplate(searchTerm)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
