import React from 'react'
import PropTypes from 'prop-types'

function SubmitButton ({
  enabled, className, onClick, name, value, content,
}) {
  if (!enabled) {
    return null
  }

  return (
    <button
      type="submit"
      onClick={onClick}
      className={className}
      name={name}
      value={value}
    >
      {content}
    </button>
  )
}

SubmitButton.propTypes = {
  enabled: PropTypes.bool.isRequired,
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  content: PropTypes.string.isRequired,
}
SubmitButton.defaultProps = {
  value: '',
}

export default SubmitButton
