import * as React from 'react'
import UploaderV2 from '../../../../uploader_v2_app/uploader'
import LnkSplitter from '../../../../shared/lnk_splitter'
import ItemTags from './item_tags'
import ItemName from './item_name'
import LocationName from './location_name'
import { CloneLnk, RemoveLnk } from './action_links'
import CollapseExpandControl from '../../../../shared/collapse_expand'
import TagBadge from '../../../../shared/tags/badge'

type ElementRowProps = {
  item: any
  onChange: any
  clickSelect: any
  clickClone: any
  clickRemove: any
  uploaderProps: any
  expandStateAction: any
}

export default function ElementRow(props: ElementRowProps) {
  const { item, onChange, clickSelect, clickClone, clickRemove, uploaderProps, expandStateAction } = props
  const { locationName, isCopy, name, dimensions, note, minAmount, maxAmount, tags, matchedTags, tagIDs } = item
  const { I18n } = window as any

  const actions = []
  if (!item.isLocation) {
    actions.push(<CloneLnk key="clone-lnk" onClick={clickClone} />)
  }
  if (matchedTags == 0) {
    if (actions.length > 0) {
      actions.push(<LnkSplitter key="lnk-plitter" />)
    }
    actions.push(<RemoveLnk key="remove-lnk" onClick={clickRemove} />)
  }

  return (
    <tr>
      <td className="name-cell">
        {tagIDs.length > 0 && <CollapseExpandControl {...expandStateAction} />}
        <LocationName name={locationName} />
        <ItemName isCopy={isCopy} name={name} onChange={onChange} />
        <ItemTags tags={matchedTags} />
      </td>
      <td className="min-amount-cell">
        <input type="text" value={minAmount} onChange={onChange} name="minAmount" className="form-control" />
      </td>
      <td className="max-amount-cell">
        <input type="text" value={maxAmount} onChange={onChange} name="maxAmount" className="form-control" />
      </td>
      <td>{dimensions}</td>
      <td className="uploader-cell">
        <UploaderV2 {...uploaderProps} />
      </td>
      <td>
        <input value={note} onChange={onChange} name="note" className="w-100 form-control" />
      </td>
      <td className="tags-cell">
        <a onClick={clickSelect}>{I18n.t('application.actions.select')}</a>
        <div>
          {tags.map((tag) => (
            <TagBadge key={`tag-${tag.value}`} type={tag.type} name={tag.label} />
          ))}
        </div>
      </td>
      <td>{actions}</td>
    </tr>
  )
}
