import * as React from 'react'
import { useEffect, useState } from 'react'
import { Button, Form, Input, Label } from 'reactstrap'
import { isUndefined } from 'lodash'
import axios, { Method, ResponseType } from 'axios'
import Select from '../../shared/base_react_select'
import Spinner from '../../shared/spinner'

const { I18n, $ } = window as any

interface DuplicateFormProps {
  projectId: number
  projectGroupId: number
  projectGroups: any
}

function loadLocations(callback, setFetching) {
  const ajaxParams = {
    method: 'get' as Method,
    responseType: 'json' as ResponseType,
  }

  setFetching(true)
  axios('/locations.json', ajaxParams)
    .then((response) => {
      const { data } = response
      if (data) {
        callback(data)
      } else {
        callback([])
      }
      setFetching(false)
    })
    .catch(() => {
      setFetching(false)
    })
}

function DuplicateForm({
  projectId,
  projectGroupId,
  projectGroups,
}: DuplicateFormProps) {
  const [isFetching, setIsFetching] = useState(false)
  const [locations, setLocations] = useState([])

  useEffect(() => {
    loadLocations(setLocations, setIsFetching)
  }, [])

  const [selectAll, setSelectAll] = useState(false)
  const [selectedLocations, setSelectedLocations] = useState({})

  if (!locations) {
    return null
  }
  const token = $('meta[name=csrf-token]').attr('content')
  const projectGroupsOptions = projectGroups.map((g) => {
    return {
      value: g[1],
      label: g[0],
    }
  })

  const isLocationSelected = (locationId) =>
    isUndefined(selectedLocations[locationId]) ? selectAll : selectedLocations[locationId]

  return (
    <div className="col">
      <Form action={`/project_tickets/${projectId}/duplicate`} method="POST">
        <input type="hidden" name="authenticity_token" value={token} />
        <div className="input form-group optional">
          <label>{I18n.t('activerecord.attributes.project_ticket.project_group')}</label>
          <Select
            name="project_group_id"
            defaultValue={projectGroupsOptions.find((g) => g.value === projectGroupId)}
            options={projectGroupsOptions}
          />
        </div>
        <label>{I18n.t('activerecord.models.location.other')}</label>
        <div className="project-ticket-duplicate-form-locations">
          <div className="input form-group optional">
            <Label>
              <Input
                className="mr-1"
                type="checkbox"
                checked={selectAll}
                onChange={(e) => {
                  setSelectedLocations({})
                  setSelectAll(e.target.checked)
                }}
              />
              {I18n.t('application.actions.select_all')}
            </Label>
          </div>
          <div className="input form-group optional">
            {isFetching && <Spinner viewType="inline" size="1" />}
            {locations.map((location) => {
              return (
                <div key={location.id}>
                  <Label>
                    <Input
                      className="mr-1"
                      type="checkbox"
                      name="location_ids[]"
                      value={location.id}
                      checked={isLocationSelected(location.id)}
                      onChange={(e) => {
                        setSelectedLocations({
                          ...selectedLocations,
                          [location.id]: e.target.checked,
                        })
                      }}
                    />
                    {`${location.number} - ${location.name}`}
                  </Label>
                </div>
              )
            })}
          </div>
        </div>
        <Button color="primary" type="submit" disabled={false}>
          {I18n.t('application.actions.apply')}
        </Button>
      </Form>
    </div>
  )
}

export default DuplicateForm
