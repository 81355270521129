import classNames from 'classnames'
import Element from './element'

export default ({ cell, path, fieldsAnswers }) => {
  const elements = cell.elements.reduce((result, element) => {
    if (element._destroy) { return result }
    result.push(element)
    return result
  }, [])

  const optionCssClasses = (cell.options?.css ? Object.values(cell.options.css) : [])

  return (
    <div className={classNames(["col"].concat(optionCssClasses))}>
      { elements.map((element, index) =>
          <Element key={`element-i-${index}`} element={element} path={[...path, index]} />
        )
      }
    </div>
  )
}
