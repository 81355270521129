import React, { useState } from 'react'
import PropTypes from 'prop-types'
import PhotoswipeImageLink from 'components/shared/photoswipe/photoswipe_image_link'
import SmModalWindow from 'components/shared/modal_window'
import Uploader from 'components/uploader_v2_app/uploader'

const { I18n } = window

export default function Photos (props) {
  const { photoFiles, uploaderOptions } = props
  const [dialogOpened, setDialogOpened] = useState(false)
  const buttonTitle = I18n.t('elements.edit_photos')
  const modalContent = (
    <div className="text-left">
      <Uploader buttonId={uploaderOptions.buttonId} />
    </div>
  )

  return (
    <div className="element-photos">
      {photoFiles.map(photo => (
        <div className="element-photo" key={photo.id}>
          <PhotoswipeImageLink {...photo} imgSrc={photo.src} />
        </div>
      ))}
      <button className="btn btn-light" type="button" onClick={() => setDialogOpened(true)}>
        {buttonTitle}
      </button>
      <SmModalWindow
        title={buttonTitle}
        visible={dialogOpened}
        onClose={(e) => { setDialogOpened(false) }}
        content={modalContent}
        windowID={`element-photos-uploader-${1}`}
      />
    </div>
  )
}
