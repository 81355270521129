import React from 'react'
import Select from 'components/shared/base_react_select'
import { COLOR_BY_TYPE } from 'components/shared/tags/badge'

const I18n = window.I18n

const dot = (color = '#ccc') => ({
  alignItems: 'center',
  display: 'flex',
  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 4,
    marginLeft: 8,
    height: 10,
    width: 10,
  },
})

const Styles = {
  option: (styles, { data }) => ({...styles,  ...dot(COLOR_BY_TYPE[data.type])}),
  multiValue: (styles, { data }) => ({ ...styles, ...dot(COLOR_BY_TYPE[data.type]) }),
}

const Placeholder = I18n.t('application.actions.select_by_what', {
  what: I18n.t('activerecord.models.tag.other')
})

export default function ({ items, selectTag }) {
  const value = items.filter(tag => tag.selected)
  
  return (
    <div>
      <Select
        isMulti
        styles={Styles}
        value={value}
        options={items}
        onChange={selectTag}
        placeholder={Placeholder}
      />
    </div>
  )
}
