import { set } from 'react-hook-form'
import { useMemo } from "react"
import { ProjectSurveyValuesShape, SurveyElementShape } from './survey_answer_form'

function elementsToDefaultValues(elements: SurveyElementShape[]): ProjectSurveyValuesShape {
  const defaultValues: ProjectSurveyValuesShape = {} as ProjectSurveyValuesShape
  elements.forEach((element, index) => {
    // A hash fieldId => fieldAnswer
    const fieldAnswers = {}
    const { answer } = element
    if (answer) {
      answer.fieldAnswers.forEach((fieldAnswer, idx) => {
        fieldAnswers[fieldAnswer.surveysFieldId] = fieldAnswer
      })
      if (answer.answerPhotos) {
        set(defaultValues, `answers_attributes[${index}][answer_photos_attributes]`, answer.answerPhotos)
      }
    }
    element.fields.forEach((field, idx) => {
      set(
        defaultValues,
        `answers_attributes[${index}][field_answers_attributes][${idx}][surveys_field_id]`,
        field.id.toString()
      )
      let value
      const fieldAnswer = fieldAnswers[field.id]
      if (fieldAnswer) {
        if (typeof fieldAnswer.value === 'string') {
          if (field.widget === 'boolean') {
            value = fieldAnswer.value === '0' ? false : fieldAnswer.value
          } else {
            // eslint-disable-next-line prefer-destructuring
            value = fieldAnswer.value
          }
        } else if (Array.isArray(fieldAnswer.value)) {
          value = fieldAnswer.value.map((v) => v.id.toString())
        } else if (field.widget === 'working_hours' || field.widget === 'address_fields') {
          // eslint-disable-next-line prefer-destructuring
          value = fieldAnswer.value
        } else {
          value = fieldAnswer.value?.id?.toString()
        }
      } else {
        const { defaultValue } = field
        if (defaultValue) {
          if (field.widget === 'working_hours' || field.widget === 'address_fields') {
            value = defaultValue
          } else {
            value = defaultValue.toString()
          }
        }
      }
      set(defaultValues, `answers_attributes[${index}][field_answers_attributes][${idx}][value]`, value)
    })
  })
  // const defaultValues: ProjectSurveyValuesShape = {
  //   surveys_answers_attributes: [
  //     {
  //       field_answers_attributes: [
  //         {
  //           survey_field_id: '1968',
  //           value: '2214',
  //         },
  //       ],
  //     },
  //   ],
  // }
  return defaultValues
}

export default function useDefaultValues(elements: SurveyElementShape[]): ProjectSurveyValuesShape {
  return useMemo(() => elementsToDefaultValues(elements), [elements])
}
