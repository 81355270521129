import store from 'store'
import { Provider } from 'react-redux'
import SharingLinks from './sharing_links'

export default function SharingLinksApp (props) {
  return (
    <Provider store={store}>
      <SharingLinks {...props} />
    </Provider>
  )
}
