import { useState } from 'react'
import { Button } from 'reactstrap'
import ModalWindow from './modal_window'

const I18n = window.I18n

const ButtonCaption = [
  I18n.t('application.actions.create'),
  I18n.t('activerecord.models.project_ticket.other')
].join(' ')

export default function (props) {
  const [isOpen, toggle] = useState(false)

  const toggleModal = () => toggle(prevState => !prevState)

  return (
    <div>
      <Button className="btn btn-secondary" onClick={toggleModal}>{ButtonCaption}</Button>
      <ModalWindow isOpen={isOpen} toggleModal={toggleModal} {...props} />
    </div>
  )
}
