import CustomDatatableWithSearch from 'libs/datatables/custom_datatable_with_search'

const { $ } = window
export default function branShow() {
  $(document).on('ready', () => {
    const $brandLocations = $('.brand-locations')
    const selector = 'table.custom-datatable tbody td:not(.actions):not(.element-table-photos):not(.gallery)'
    $brandLocations.on('click', selector, (e) => {
      window.location.href = $(e.currentTarget).parent('tr').data('url')
    })

    $('.element-group').each(function(index, elements){
      const table = $(this).find('table')
      const search = $(this).find('input.search')
      let sort_column = $(table).data('sortColumn')
      if (_.isUndefined(sort_column)) { sort_column = 1 }
      const order = [[ sort_column, 'asc']]
      new CustomDatatableWithSearch(table, search, {order})
    })
  })

}
