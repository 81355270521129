import ElementTotalAmount from './element_total_amount'
import ElementTotalPrintsApp from './element_total_prints_app'
import AmountCellApp from './amount_cell_item_app'
import TotalPrintsCellApp from './total_prints_cell_app'
import AmountsMessagesArea from './amounts_messages_area'

export default {
  AmountCellApp,
  TotalPrintsCellApp,
  ElementTotalAmount,
  ElementTotalPrintsApp,
  AmountsMessagesArea
}
