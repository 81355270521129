import React from 'react'
import LocationRows from './location_rows'

export default ({ steps, stepsProjects, selectedFilters, colSize, emptyMultiStepProjects }) => {
  if (!steps.length) {
    return null
  }

  const allProjects = Object.keys(stepsProjects).reduce((result, key) => {
    return result.concat(stepsProjects[key])
  }, [])

  const locations = {}
  const grouping = {}
  allProjects.forEach((project) => {
    locations[project.location.id] = project.location
    const groupingKey = project.multiStepProject.id
    const locationId = project.location.id

    if (grouping.hasOwnProperty(locationId)) {
      if (!grouping[locationId].includes(groupingKey)) {
        grouping[locationId].push(groupingKey)
      }
    } else {
      grouping[locationId] = [groupingKey]
    }
  })

  emptyMultiStepProjects.forEach((emptyProject) => {
    const { location } = emptyProject
    if (!locations.hasOwnProperty(location.id)) {
      locations[location.id] = location
      grouping[location.id] = [`empty-group-${location.id}`]
    }
  })

  return (
    <>
      <table className="table">
        <thead>
          <tr>
            <th />
            {steps.map((step, i) => (
              <th className="text-center" key={`step-index-${i}`}>
                {step.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {Object.keys(locations).map(locationId => {
            const location = locations[locationId]
            const emptyMultiStepProjectsForLocation = emptyMultiStepProjects.filter(msp => msp.location.id == locationId)
            return (
              <LocationRows
                key={locationId}
                location={location}
                grouping={grouping[locationId]}
                stepsProjects={stepsProjects}
                filters={selectedFilters}
                steps={steps}
                emptyMultiStepProjects={emptyMultiStepProjectsForLocation}
              />
            )
          })}
        </tbody>
      </table>
    </>
  )
}
