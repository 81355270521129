import { connect } from 'react-redux'

function mapStateToProps (state, props) {
  return {
    photoFiles: state.getIn(['uploader', props.uploaderOptions.buttonId, 'files'])?.toJS() || {}
  }
}

function mapDispatchToProps (dispatch) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)
