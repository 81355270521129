import { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export default class Comment extends Component {
  static propTypes = {
    comment: PropTypes.object.isRequired,
  }

  onClick = () => {
    this.props.assignComment(this.props.comment.toJS())
  }  

  render () {
    const comment = this.props.comment
    return (
      <tr className="overview-comment-list-comment-container">
        <td className="overview-comment-list-comment-icon">
          <i className="fa fa-comments" onClick={this.onClick} />
        </td>
        <td className={classNames('overview-comment-list-comment-body', 'small')}>
          <a href={comment.get('link')}>
            {comment.get('body')}
          </a>
        </td>
        <td className={classNames('overview-comment-list-comment-projectInfo', 'small')}>
          {comment.get('company_name')}<br />
          {comment.get('project_id')}
        </td>
      </tr>
    )
  }
}
