import { useState, useCallback } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

const I18n = window.I18n

const ModalWindow = ({toggle, isOpen, createLocationAlbum, isReqInProgress, locationId}) => {

  const [name, changeName] = useState('')
  const onChangeName = (e) => changeName(e.target.value)

  const onSubmit = useCallback(() => createLocationAlbum(locationId, name), [name])

  const submitDisabled = !name

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg" className="modal-form-create-msp-project" id="create_album_window">
      <ModalHeader toggle={toggle}>
        {I18n.t('albums.modal_form.title')}
        {isReqInProgress && <Spinner viewType="inline" size="1" />}
      </ModalHeader>
      <ModalBody>
        <input type="text" className="form-control" placeholder={I18n.t('activerecord.attributes.album.title')} value={name} onChange={onChangeName} />
      </ModalBody>
      <ModalFooter>
        <div className="text-center">
          <Button color="primary" onClick={onSubmit} disabled={submitDisabled}>
            {I18n.t('application.actions.create')}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default ModalWindow