import * as React from 'react'
import { useEffect } from 'react'
import connect from './connect'
import FilterSelect from './filter_select'
import { ProjectReportFilterDefinitionType, SavedFilterType, SelectedFiltersType } from './types'
import SelectedFilters from './selected_filters'
import SpinnerOverlay from '../../../shared/spinner_overlay'
import ShowCompletedCheckbox from './show_completed_checkbox'
import SavedFilters from './saved_filters'

type ProjectsReportFiltersProps = {
  initialAvailableFilters: ProjectReportFilterDefinitionType[]
  initialSavedFilters: SavedFilterType[]
  completedStatusId: string
  showCompletedLabel: string

  // Redux props
  availableFilters: ProjectReportFilterDefinitionType[]
  setAvailableFilters: (filters: ProjectReportFilterDefinitionType[]) => void
  selectedFilters: SelectedFiltersType
  setSelectedFilters: (filters: SelectedFiltersType) => void
  savedFilters: SavedFilterType[]
  setSavedFilters: (filters: SavedFilterType[]) => void
  updateUserFilters: (filters: SavedFilterType[]) => void
  isFetching: boolean
  loadAvailableFilters: (payload) => void
}

function setDatatableExternalFilters(selectedFilterIds: any) {
  const { $ } = window as any
  const table = $('.table.dataTable')
  if (!table.length) return

  table.data('externalFilters', selectedFilterIds)
  table.DataTable().ajax.reload()
}

function ProjectsReportFilters(props: ProjectsReportFiltersProps) {
  const {
    initialAvailableFilters,
    initialSavedFilters,
    savedFilters,
    setSavedFilters,
    updateUserFilters,
    completedStatusId,
    showCompletedLabel,
    availableFilters,
    setAvailableFilters,
    selectedFilters,
    setSelectedFilters,
    isFetching,
    loadAvailableFilters,
  } = props

  useEffect(() => {
    setAvailableFilters(initialAvailableFilters)
    setSavedFilters(initialSavedFilters)
  }, [])

  // Workaround to avoid infinite loop due to immutable.js state
  const [selectedFilterIds, setSelectedFilterIds] = React.useState({})
  useEffect(() => {
    const newSelectedFilterIds = Object.entries(selectedFilters).reduce((acc, [filterName, filterValues]) => {
      acc[filterName] = filterValues.map((f) => f.value)
      return acc
    }, {})
    if (JSON.stringify(newSelectedFilterIds) !== JSON.stringify(selectedFilterIds)) {
      setSelectedFilterIds(newSelectedFilterIds)
    }
  }, [selectedFilters])

  useEffect(() => {
    setDatatableExternalFilters(selectedFilterIds)

    if (Object.keys(selectedFilters).length === 0) {
      setAvailableFilters(initialAvailableFilters)
    } else {
      loadAvailableFilters(selectedFilterIds)
    }
  }, [selectedFilterIds])

  return (
    <SpinnerOverlay isActive={isFetching}>
      <div className="d-flex mb-4 justify-content-between">
        <div className="d-flex mr-2">
          {availableFilters.map((filter) => (
            <FilterSelect
              key={filter.name}
              filter={filter}
              initialAvailableFilters={initialAvailableFilters}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
            />
          ))}
          <SelectedFilters selectedFilters={selectedFilters} setSelectedFilters={setSelectedFilters} />
        </div>
        <SavedFilters
          savedFilters={savedFilters}
          filter={selectedFilters}
          setFilter={setSelectedFilters}
          availableFilters={initialAvailableFilters}
          saveUserFilters={updateUserFilters}
          initialAvailableFilters={initialAvailableFilters}
          completedStatusId={completedStatusId}
          showCompletedLabel={showCompletedLabel}
        />
      </div>
      <ShowCompletedCheckbox
        showCompletedLabel={showCompletedLabel}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
        initialAvailableFilters={initialAvailableFilters}
        completedStatusId={completedStatusId}
      />
    </SpinnerOverlay>
  )
}

export default connect(ProjectsReportFilters)
