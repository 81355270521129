import * as React from 'react'
import { useFormContext } from "react-hook-form"
import { WidgetFactoryInterface } from '../survey_answer_form'

export default function Boolean({ field, fieldName }: WidgetFactoryInterface) {
  const { required } = field
  const { register } = useFormContext()
  const fieldId = fieldName.replace(/[\[\]]/g, '_')


  return (
    <li className={`boolean input form-group ${required ? 'required' : 'optional'}`}>
      <div className="form-check">
        <label htmlFor={fieldId} className="form-check-label">
          <input type="hidden" name={fieldName} value="0" />
          <input
            type="checkbox"
            id={fieldId}
            className="form-check-input"
            value={field.defaultValue}
            {...register(fieldName)}
          />
          {field.name}
        </label>
      </div>
    </li>
  )
}
