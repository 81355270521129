import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import SpinnerOverlay from 'components/shared/spinner_overlay'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import CalendarTable from './calendar_table'


const { I18n } = window

function RentList (props) {
  const {
    loadData, rentElement, unrentItem, rentList, groupedAvailableElements,
    elementGroups, rentElementRequestInProgress, rentedItems, initialRentedItems,
    errors, clearErrors,
  } = props
  const { startDate, endDate } = rentList

  useEffect(() => {
    const data = {
      elementGroups: rentList.elementGroups,
      groupedAvailableElements: rentList.groupedAvailableElements,
      rentedItems: initialRentedItems,
      rentListId: rentList.id,
    }
    loadData(data)
  }, [])

  const handleElementClick = (element) => {
    const rentItems = rentedItems[element.id]
    if (!rentItems) {
      rentElement(rentList.id, element.id)
      return
    }
    const rentItem = rentItems.find(i => i.rentList.id === rentList.id)
    if (rentItem) {
      unrentItem(rentList.id, rentItem.id)
    } else {
      rentElement(rentList.id, element.id)
    }
  }

  return (
    <div className="rent-list">
      <Modal isOpen={errors.length > 0} toggle={clearErrors} size="lg">
        <ModalHeader toggle={clearErrors}>
          {I18n.t('elements.rent_list.error_modal_header')}
        </ModalHeader>
        <ModalBody>
          {errors.join('. ')}
        </ModalBody>
      </Modal>
      <SpinnerOverlay isActive={rentElementRequestInProgress}>
        <CalendarTable
          startDate={startDate}
          endDate={endDate}
          groupedAvailableElements={groupedAvailableElements}
          elementGroups={elementGroups}
          handleElementClick={handleElementClick}
          rentedItems={rentedItems}
          rentList={rentList}
        />
      </SpinnerOverlay>
    </div>
  )
}

RentList.propTypes = {
  loadData: PropTypes.func.isRequired,
  rentElement: PropTypes.func.isRequired,
  unrentItem: PropTypes.func.isRequired,
  rentList: PropTypes.shape({
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
  }).isRequired,
  rentElementRequestInProgress: PropTypes.bool.isRequired,
  rentedItems: PropTypes.object.isRequired,
  initialRentedItems: PropTypes.object.isRequired,
  groupedAvailableElements: PropTypes.object.isRequired,
  elementGroups: PropTypes.object.isRequired,
}

export default RentList
