import { useState, useCallback } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import Form from './form'
import ajax from 'axios'

const { I18n } = window

const CREATE_CAPTION = I18n.t('application.actions.new') + ' ' + I18n.t('activerecord.models.multi_step/project.one')

const sendData = (formData) => ajax({
  url: '/multi_step/projects',
  method: 'post',
  data: { multi_step_project: formData, next_page: 'create_first_step' }
})

const CreateButton = ({ locations, workflows, pathToRedirect }) => {

  const [isOpen, setOpenFlag] = useState(false)
  const [formData, updateFormData] = useState({ name: '', workflow_id: '', location_id: '' })

  const isReqInProgress = false

  const toggle = () => setOpenFlag(prevVal => !prevVal)
  const handleFormChanges = (data) => {
    updateFormData(prevFormData => Object.assign({}, prevFormData, data))
  }
  const onSubmit = useCallback(() => {
    sendData(formData).then((resp) => {
      if (resp.data.redirect_to) { window.location.href = resp.data.redirect_to }
    })
  }, [formData])

  const submitDisabled = !formData.name || !formData.workflow_id || !formData.location_id

  return (
    <div className="button-with-form">
      <Button color="secondary" onClick={toggle}>
        {CREATE_CAPTION}
      </Button>
      <Modal isOpen={isOpen} toggle={toggle} size="lg" className="modal-form-create-msp-project">
        <ModalHeader toggle={toggle}>
          {CREATE_CAPTION}
          {isReqInProgress && <Spinner viewType="inline" size="1" />}
        </ModalHeader>
        <ModalBody>
          <Form data={formData} onChange={handleFormChanges} locations={locations} workflows={workflows} />
        </ModalBody>
        <ModalFooter>
          <div className="text-center">
            <Button color="primary" onClick={onSubmit} disabled={submitDisabled}>
              {I18n.t('application.actions.create')}
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default CreateButton
