import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { DragSource, DropTarget } from 'react-dnd'

const columnSource = {
  beginDrag (props, monitor, component) {
    return {
      name: props.name,
    }
  },
  endDrag (props, monitor, component) {
    if (!monitor.didDrop()) {
      return
    }

    const result = monitor.getDropResult()
    if (result.hasOwnProperty('isSelected') && result.isSelected) {
      props.dropToSelected(props.name, result.position)
    } else {
      props.dropToAvailable(props.name)
    }
  }
}

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }
}

function collectDrop(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  }

}

const columnTarget = {
  drop(props, monitor) {
    return {
      name: props.name,
      position: props.position,
      isSelected: props.isSelected()
    }
  },
}


class Column extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    position: PropTypes.number,
    isSelected: PropTypes.func,
    translate: PropTypes.func.isRequired,
  }

  render () {
    const { name, position, translate, connectDragSource, connectDropTarget,
      isOver, isSelected } = this.props
    let columnClass = `enabled-columns-column column-${name}`
    if (isOver && isSelected() ) {
      columnClass += ' drop-target-active'
    }
    return connectDragSource && connectDropTarget && connectDragSource(connectDropTarget(
      <span className={columnClass}>
        {translate(name)}
      </span>
    ))
  }
}

export default DragSource('enabled-columns', columnSource, collect)(
    DropTarget('enabled-columns', columnTarget,  collectDrop)(Column)
  )
