
import * as React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import DuplicateForm from './duplicate_form'

interface ModalWindowProps {
  projectId: number
  isOpen: boolean
  toggle: () => void
  toggleModal: () => void
  windowTitle: string
  projectGroupId: number
  projectGroups: any
}

function ModalWindow({
  projectId,
  isOpen,
  toggle,
  toggleModal,
  windowTitle,
  projectGroupId,
  projectGroups,
}: ModalWindowProps) {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggleModal}>
        {windowTitle}
      </ModalHeader>
      <ModalBody>
        <DuplicateForm
          projectId={projectId}
          projectGroupId={projectGroupId}
          projectGroups={projectGroups}
        />
      </ModalBody>
    </Modal>
  )
}

export default ModalWindow
