import * as React  from 'react'
import { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import ProjectGroup from './project_group'

export default class ProjectGroupList extends Component {
  static propTypes = { projectGroups: PropTypes.array.isRequired }

  render () {
    const { projectGroups, getProjects, assignGroup } = this.props

    return (
      <Fragment>
        { this.props.projectGroups.map(projectGroup => (
            <ProjectGroup projectGroup={projectGroup} key={projectGroup.id} getProjects={getProjects} assignGroup={assignGroup} />
          ))
        }
      </Fragment>
    )
  }
}
