import React from 'react'
import store from 'store'
import { Provider } from 'react-redux'
import { Button } from 'reactstrap'
import ModalWindow from './modal_window'
import { init } from 'actions/projects/msp_step_button_actions'

class MspStepButtonApp extends React.Component {

  constructor (props) {
    super(props)
    store.dispatch(init(this.props))
    this.state = { modalFormIsActive: false }
  }

  toggleModal = () => {
    this.setState(prevState => ({
      modalFormIsActive: !prevState.modalFormIsActive
    }))
  }

  render () {
    return (
      <Provider store={store}>
        <div>
          <Button className="btn btn-secondary" onClick={this.toggleModal}>
            {this.props.caption}
          </Button>
          <ModalWindow isOpen={this.state.modalFormIsActive} toggleModal={this.toggleModal} />
        </div>
      </Provider>
    )
  }
}

export default MspStepButtonApp
