import { Component } from 'react'
import PropTypes from 'prop-types'
import CableEmitter from 'emitters/cable_emitter'
import classNames from 'classnames'
import MyFileModel from 'models/my_file'

export default class HeaderLink extends Component {
  static propTypes = {
    inProgressIds: PropTypes.array,
  }

  state = {
    inProgressIds: []
  }

  constructor (props) {
    super(props)
    // TODO (atanych): replace this structure with redux store
    this.state = { inProgressIds: props.in_progress_ids }

    CableEmitter.addListener('MY_FILES.CREATE', this.handleCreate)
    CableEmitter.addListener('MY_FILES.UPDATE', this.handleUpdate)
  }

  handleCreate = (file) => {
    if (file.status !== MyFileModel.IN_PROGRESS) { return null }

    const { inProgressIds } = this.state
    this.setState({ inProgressIds: inProgressIds.concat(file.id) })
  }

  handleUpdate = (file) => {
    const { inProgressIds } = this.state
    if (file.status === MyFileModel.IN_PROGRESS && !inProgressIds.includes(file.id)) {
      this.setState({ inProgressIds: inProgressIds.concat(file.id) })
    } else {
      this.setState({ inProgressIds: inProgressIds.filter(id => id !== file.id) })
    }
  }

  getIcon () {
    const { inProgressIds } = this.state
    return inProgressIds.length ? 'fa-spinner fa-fw fa-spin' : 'fa-download'
  }
  render () {
    return (
      <span>
        <i className={classNames('fa', this.getIcon())} />
        {I18n.t('my_files.index.title')}
      </span>
    )
  }
}
