import React from 'react'

import ItemTypes, { getElementSettingsComponentByType } from '../lib/item_types'
import Elements from './elements'

export default class ElementSettings extends React.Component {

  static type = ItemTypes.ELEMENT

  render () {
    const element = this.props.item
    const { path, handlers } = this.props
    const ElementItemSettings = getElementSettingsComponentByType(element.type)
    return (
      <div className="element-settings">
        <ElementItemSettings item={element} path={path} handlers={handlers} />
      </div>
    )
  }
}
