import * as React from 'react'
import axios, { Method, ResponseType } from 'axios'
import Select, { Async } from '../../shared/base_react_select'

type CompanyShape = {
  id: number
  name: string
}

interface SelectProjectProps {
  companies: CompanyShape[]
  projectsIndexUrl: string
  selectedCompany: {
    id: number
    name: string
  }
  selectedProject: {
    id: number
    companyId: number
    location_name: string
    title: string
  }
}

function loadProjectOptions(url, filter, callback) {
  const ajaxParams = {
    method: 'get' as Method,
    responseType: 'json' as ResponseType,
    params: { ...filter },
  }
  axios(url, ajaxParams).then((response) => {
    const { data } = response
    if (data) {
      const options = data.map((project) => {
        return { label: `${project.id} - ${project.location_name} - ${project.title}`, value: project.id, project }
      })
      callback(options)
    } else {
      callback([])
    }
  })
}

export default function SelectProject(props: SelectProjectProps) {
  const { companies, selectedProject, selectedCompany, projectsIndexUrl } = props
  const companyOptions = companies.map((c) => ({ value: c.id, label: c.name }))
  const selectedCompanyOption = selectedCompany ? companyOptions.find((c) => c.value === selectedCompany.id) : null
  const defaultCompany = selectedProject ? companyOptions.find((c) => c.value === selectedProject.companyId) : selectedCompanyOption
  const [company, setCompany] = React.useState(defaultCompany)
  const defaultProject = selectedProject
    ? { value: selectedProject.id, label: `${selectedProject.id} - ${selectedProject.location_name} - ${selectedProject.title}` }
    : null
  const [project, setProject] = React.useState(defaultProject)
  const { I18n } = window as any
  return (
    <ul>
      <li className="sm_react_select input form-group">
        <label htmlFor="project_company_selector" className="label">
          {I18n.t('activerecord.models.company.one')}
        </label>
        <div className="default-form-input-wrapper sm-react-select-field-container">
          <Select
            name="project_company_selector"
            value={company}
            options={companyOptions}
            onChange={(c) => {
              setCompany(c)
              setProject(null)
            }}
            placeholder={I18n.t('project_tickets.timeapp_new.select_project_form.select_company_placeholder')}
            // className="default-form-input-wrapper sm-react-select-field-container"
          />
        </div>
      </li>
      {company && (
        <li className="sm_react_select input form-group">
          <label htmlFor="project_selector" className="label">
            {I18n.t('activerecord.models.project_ticket.one')}
          </label>
          <div className="default-form-input-wrapper sm-react-select-field-container">
            {project && <input type="hidden" name="project_id" value={project ? project.value : ''} />}
            <Async
              key={company.value}
              name="project_selector"
              value={project}
              onChange={setProject}
              defaultOptions
              loadOptions={(inputValue, callback) =>
                loadProjectOptions(
                  projectsIndexUrl,
                  {
                    q: inputValue,
                    company_id: company.value,
                  },
                  callback
                )
              }
              placeholder={I18n.t('project_tickets.timeapp_new.select_project_form.select_project_placeholder')}
            />
          </div>
        </li>
      )}
      <li className="action input_action form-group">
        <div className="default-form-input-wrapper">
          <input
            type="submit"
            name="commit"
            value="Save"
            className="btn btn-primary"
            data-disable-with="Save"
            disabled={project === null}
          />
        </div>
      </li>
    </ul>
  )
}
