import React from 'react'

const I18n = window.I18n

export default class SaveButton extends React.Component {

  render () {
    return (
      <button type="button" className="save btn btn-primary" onClick={this.props.onClick}>
        {I18n.t('campaigns.steps_captions.save_draft')}
      </button>
    )
  }
}
