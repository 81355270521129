import React from 'react'
import ItemTypes from '../../lib/item_types'
import { RadioButtonCollection } from '../service_parts'

const I18n = window.I18n

export default class HeaderSettings extends React.Component {

  onChange = (e) => {
    const { item, path, handlers } = this.props
    handlers.updateItemAttr(e, item, ItemTypes.ELEMENT.type, path)
  }

  isChecked = (val) => (this.props.item.size || '').toString() == val

  render () {
    const { caption } = this.props.item
    const collection  = [
      { caption: 'H1', value: '1' },
      { caption: 'H2', value: '2' },
      { caption: 'H3', value: '3' },
      { caption: 'H4', value: '4' },
      { caption: 'H5', value: '5' },
      { caption: 'H6', value: '6' }
    ]

    return (
      <div className="header-settings">
        <div className="form-group">
          <label>Caption</label>
          <input type="text" className="form-control" name="caption" value={caption}  onChange={this.onChange} />
        </div>
        <RadioButtonCollection name="size" collection={collection} onChange={this.onChange} isChecked={this.isChecked} />
      </div>
    )
  }

}
