import React from 'react'
import classNames from 'classnames'

const RadioBtn = ({ item, index, elementIndex, isChecked, display, mode, onChange }) => {
  const inputId = `radio-btn-${elementIndex}-${index}`
  const { value, label } = item

  let mainWrapperClassNames = 'form-check'
  if (display == 'inline') {
    mainWrapperClassNames = classNames([mainWrapperClassNames, 'form-check-inline'])
  }

  return (
    <div className={mainWrapperClassNames}>
      <input type="radio" id={inputId} name={`radio_btn_group_${elementIndex}`}
        className="form-check-input" defaultValue={value}
        disabled={mode.isReadOnly()}
        defaultChecked={isChecked}
        onChange={onChange}
      />
      { label && <label htmlFor={inputId} className="form-check-label">{label}</label> }
    </div>
  )
}

class RadioButtons extends React.Component {
  state = { value: '' }

  onChange = (e) => {
    if (e.target.checked) {
      this.setState({ value: e.target.value })
    }
  }

  isAnswerChecked = (val) => {
    const { fieldAnswer } = this.props
    return ((fieldAnswer || fieldAnswer == 0) && val == fieldAnswer)
  }

  isDefaultChecked = (val) => {
    const { defaultValue } = this.props
    return (defaultValue && val == defaultValue)
  }

  render () {
    const { display, fieldAnswer, elementIndex, defaultValue, collection, mode } = this.props

    const isChecked = (fieldAnswer || fieldAnswer == 0) ? this.isAnswerChecked : this.isDefaultChecked

    return (
      <React.Fragment>
        { collection.map((item, index) => (
            <RadioBtn key={`radio-btn-${index}`}
              item={item}
              index={index}
              elementIndex={elementIndex}
              display={display}
              defaultValue={defaultValue}
              isChecked={isChecked(item.value)}
              mode={mode}
              onChange={this.onChange}
            />
          ))
        }
        <input type="hidden" name="fields_answers_attributes[][value]" value={this.state.value} />
      </React.Fragment>
    )
  }
}

export default ({ element, path, mode }) => {
  const { header, collection, id, fieldAnswer, display, defaultValue } = element

  const elementIndex = path.slice(-1)

  return (
    <div className="radio-buttons">
      { header && <h5>{header}</h5> }
      <RadioButtons collection={collection} elementIndex={elementIndex} fieldAnswer={fieldAnswer} display={display} defaultValue={defaultValue} mode={mode} />
      <input type="hidden" name={`fields_answers_attributes[][field_id]`} value={id} />
    </div>
  )
}
