import * as React from 'react'
import Photo from './photo'

const { I18n } = window as any

type ElementProps = {
  element: any
  subtotal: number
  inputAttributeName: (name: string, row: string) => string
  row: string
  removeElement: (key: string, id: string) => void
  editElement: (element: any) => void
}

export default function Element(props: ElementProps) {
  const handleRemove = (e: React.MouseEvent) => {
    e.preventDefault()
    const { element, removeElement } = props
    removeElement(element.get('key'), element.get('id'))
  }

  const handleEdit = (e: React.MouseEvent) => {
    e.preventDefault()
    const { element, editElement } = props
    editElement(element)
  }

  const { element, subtotal, inputAttributeName, row } = props
  const stockLevel = element.get('stock_level') || element.getIn(['template', 'stock_level'])

  let idField = null
  if (element.get('id') !== undefined) {
    const name = inputAttributeName('id', row)
    idField = <input type="hidden" name={name} value={element.get('id')} />
  }

  const getValue = (field: string) => {
    const fieldDefinition = element.getIn(['template', 'fields', field])
    let value = null
    if (fieldDefinition !== undefined) {
      const relationValue = fieldDefinition.get('relation_value')
      value = relationValue === undefined ? fieldDefinition.get('default_value') : relationValue
    }
    return value
  }
  const amount = element.get('amount') || element.getIn(['template', 'amount'])
  const replaceElementId = element.get('replace_element_id')
  const replaceText = replaceElementId ? ` ${I18n.t('elements.order_form.replace_suffix')}` : ''
  const name = getValue('name') + replaceText

  const width = element.get('width') || getValue('width')
  const height = element.get('height') || getValue('height')

  return (
    <tr>
      <td>{amount}</td>
      <td>{stockLevel}</td>
      <td>{getValue('sub_group')}</td>
      <td>{getValue('department')}</td>
      <td>{getValue('sku')}</td>
      <td>{name}</td>
      <td>{getValue('description')}</td>
      <td>{width}</td>
      <td>{height}</td>
      <td>
        <Photo element={element} />
      </td>
      <td>{parseFloat(getValue('price')).toFixed(2)}</td>
      <td>{subtotal.toFixed(2)}</td>
      <td>{element.get('note') || getValue('note')}</td>
      <td>
        <input type="hidden" name={inputAttributeName('amount', row)} value={amount} />
        <input type="hidden" name={inputAttributeName('width', row)} value={width} />
        <input type="hidden" name={inputAttributeName('height', row)} value={height} />
        <input type="hidden" name={inputAttributeName('note', row)} value={element.get('note') || ''} />
        <input type="hidden" name={inputAttributeName('replace_element_id', row)} value={replaceElementId || ''} />
        <input
          type="hidden"
          name={inputAttributeName('order_template_id', row)}
          value={element.getIn(['template', 'id'])}
        />
        {idField}
        <div className="d-flex flex-nowrap">
          <button type="button" className="btn btn-secondary mr-2" onClick={handleEdit}>
            {I18n.t('elements.actions.edit_element')}
          </button>
          <button type="button" className="remove-element close text-danger" onClick={handleRemove}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </td>
    </tr>
  )
}

function elementSubtotal(element) {
  const fields = element.get('template').get('fields')
  let subtotal = 0
  let price = 0
  if (fields.get('price')) {
    price = parseFloat(fields.get('price').get('default_value'))
  }

  const amount = Number(element.get('amount') || element.getIn(['template', 'amount']))

  // Price for elements with editable width and height should be calculated
  // with different formula
  const widthField = fields.get('width')
  const heightField = fields.get('height')
  if (widthField && widthField.get('editable') && heightField && heightField.get('editable')) {
    const width = element.get('width')
    const height = element.get('height')
    subtotal = (amount * price * width * height) / 1000000
  } else {
    subtotal = amount * price
  }
  if (Number.isNaN(subtotal)) {
    subtotal = 0
  }

  return subtotal
}

export { elementSubtotal }
