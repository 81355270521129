import * as React from 'react'
import { useState, useEffect } from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import SpinnerOverlay from '../../shared/spinner_overlay'
import SendLinksForm from './send_links_form'
import SelectProjects from './select_projects'
import connect from './connect'

const { I18n, $ } = window as any

type ModalWindowProps = {
  isOpen: boolean
  toggle: () => void
  toggleModal: () => void
  windowTitle: string
  // projectGroupId: number
  actionUrl: string
  loadProjects: (projectIds: number[]) => void
  isFetching: boolean
  availableProjects: any[]
}

function ModalWindow(props: ModalWindowProps) {
  const {
    isOpen,
    toggle,
    toggleModal,
    windowTitle,
    // projectGroupId,
    actionUrl,
    loadProjects,
    isFetching,
    availableProjects,
  } = props
  const [currentStep, setCurrentStep] = useState(0)
  const availableProjectIds = $('.projects-table tbody tr')
    .map(function () {
      return parseInt(this.id.replace(/^project_/, ''), 10)
    })
    .get()
  useEffect(() => {
    loadProjects(availableProjectIds)
  }, [])
  const [selectedProjects, setSelectedProjects] = useState(availableProjectIds)
  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg" className="send-links-modal">
      <ModalHeader toggle={toggleModal}>{windowTitle}</ModalHeader>
      <ModalBody>
        {currentStep === 0 && availableProjects && (
          <SpinnerOverlay isActive={isFetching}>
            <SelectProjects
              availableProjects={availableProjects}
              selectedProjects={selectedProjects}
              setSelectedProjects={setSelectedProjects}
            />
          </SpinnerOverlay>
        )}
        {currentStep === 1 && (
          <SendLinksForm
            toggleModal={toggleModal}
            actionUrl={actionUrl}
            selectedProjects={selectedProjects}
            setCurrentStep={setCurrentStep}
            availableProjects={availableProjects}
          />
        )}
      </ModalBody>
      {currentStep === 0 && (
        <ModalFooter>
          <Button color="secondary" onClick={toggleModal}>
            {I18n.t('application.actions.cancel')}
          </Button>
          <Button color="primary" onClick={() => setCurrentStep(1)}>
            {I18n.t('application.actions.next')}
          </Button>
        </ModalFooter>
      )}
    </Modal>
  )
}

export default connect(ModalWindow)
