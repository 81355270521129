import Header from './header'
import Paragraph from './paragraph'
import Image from './image'
import Signature from './signature'
import DatePicker from './date_picker'
import TextInput from './text_input'
import CheckboxInput from './checkbox_input'
import RadioButtons from './radio_buttons'
import Textarea from './textarea'
import SubmitBtn from './submit_btn'

export default {
  Header,
  Paragraph,
  Image,
  Signature,
  DatePicker,
  TextInput,
  CheckboxInput,
  RadioButtons,
  Textarea,
  SubmitBtn
}
