import React, { Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import ReactHover, { Trigger, Hover } from 'react-hover'
import { CalendarHeadRows, CalendarElementColumns, CalendarHelper } from '../../rent_list_app/rent_list/calendar'
import ElementInfo from '../../rent_list_app/rent_list/element_info'
import CalendarTable from '../../rent_list_app/rent_list/calendar_table'


const { I18n } = window

function RentListOverview (props) {
  const {
    initialData, loadData, startDate, endDate, groupedAvailableElements,
    elementGroups, rentedItems,
  } = props

  useEffect(() => {
    const data = {
      elementGroups: initialData.elementGroups,
      groupedAvailableElements: initialData.groupedAvailableElements,
      rentedItems: initialData.rentedItems,
    }
    loadData(data)
  }, [])

  const cal = new CalendarHelper(startDate, endDate)

  return (
    <div className="rent-list">
      <CalendarTable
        startDate={startDate}
        endDate={endDate}
        groupedAvailableElements={groupedAvailableElements}
        elementGroups={elementGroups}
        handleElementClick={() => {}}
        rentedItems={rentedItems}
        rentList={null}
      />
    </div>
  )
}

RentListOverview.propTypes = {
  loadData: PropTypes.func.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  initialData: PropTypes.object.isRequired,
  rentedItems: PropTypes.object.isRequired,
  groupedAvailableElements: PropTypes.object.isRequired,
  elementGroups: PropTypes.object.isRequired,
}

export default RentListOverview
