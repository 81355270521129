import React from 'react'
import PropTypes from 'prop-types'

function ElementInfo (props) {
  const { element } = props
  return (
    <div className="rent-list-element-info">
      <h5>{element.name}</h5>
      <div className="mb-2">
        {`${element.sku}, H: ${element.width}, B: ${element.height}, D: ${element.depth}`}
      </div>
      <div>
        <img className="rent-list-element-info-image" src={element.photoThumb} alt={element.name}/>
      </div>
    </div>
  )
}

ElementInfo.propTypes = {
  element: PropTypes.object.isRequired,
}

export default ElementInfo
