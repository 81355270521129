import { connect } from 'react-redux'
import { removeItem, selectItems, saveSelected,
  updateItem, makeCopy, assignActiveItem,
  updateItemImageList, mergeItemImages, removeItemImage, addNewImageToItem, updateItemImage,
  createElement, addAllElements, types, selectTags, nextStep, stepBack
} from 'actions/campaigns/elements_selector_actions'
import  { selectByTags } from 'actions/campaigns/common_actions'
import { createLoadingSelector } from 'libs/requests_status_handlers'

const loadingSelector = createLoadingSelector([types.CREATE_ELEMENT])

export default connect((state) => ({
  id: state.getIn(['campaigns', 'id']),
  isReady: state.getIn(['campaigns', 'isReady']),
  isFetching: loadingSelector(state),
  elements: state.getIn(['campaigns', 'elements']),
  tags: state.getIn(['campaigns', 'tags']),
  elementFormData: state.getIn(['campaigns', 'elementFormData']),
  activeItem: state.getIn(['campaigns', 'activeItem']),
  imageDeleteCandidate: state.getIn(['campaigns', 'imageDeleteCandidate'])
}), { selectItems, removeItem, updateItem, 
  updateItemImageList, mergeItemImages, addNewImageToItem, updateItemImage, removeItemImage,
  saveSelected, selectTags, makeCopy, assignActiveItem, createElement, addAllElements,
  selectByTags, nextStep, stepBack
})
