import { Fragment } from 'react'
import { FormGroup, Label, Input } from 'reactstrap';

const I18n = window.I18n

const PROJECT_GROUP_NAME_CAPTION = [
  I18n.t('activerecord.models.project_group.one'),
  I18n.t('activerecord.attributes.project_group.name')
].join(' ')

export default ({ onChange, initData, data }) => {
  const { workflows } = initData
  const groupWorkflowId = data['group']['workflow_id'] || ''
  const groupName = data['group']['name'] || ''
  return (
    <Fragment>
      <FormGroup>
        <Label for="group_name">{PROJECT_GROUP_NAME_CAPTION}</Label>
        <Input type="text" name="group[name]" id="group_name" onChange={onChange} value={groupName} />
      </FormGroup>
      <FormGroup>
        <Label for="projects_group_workflow">{I18n.t('activerecord.models.workflow.one')}</Label>
        <Input type="select" id="projects_group_workflow" name="group[workflow_id]" onChange={onChange} value={groupWorkflowId}>
          <option value=""></option>
          { workflows.map(workflow =>
              <option key={`projects-group-workflow-${workflow.value}`} value={workflow.value}>
                {workflow.label}
              </option>
            )
          }
        </Input>
      </FormGroup>
    </Fragment>
  )
}
