import React from 'react'
import PropTypes from 'prop-types'
const { I18n } = window


class ElementInput extends React.Component {
  static propTypes = {
    element: PropTypes.object.isRequired,
    updateElement: PropTypes.func.isRequired,
    field: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }

  handleInputChange (e) {
    const { element, updateElement, field } = this.props
    updateElement(element.set(field, e.target.value))
  }

  render () {
    const { element, field, name } = this.props
    const fields = element.getIn(['template', 'fields'])

    if (fields.get(field) === undefined) {
      return null
    }

    const editable = fields.getIn([field, 'editable'])
    let value
    if (element.has(field)) {
      value = element.get(field)
    } else {
      const templateValue = element.getIn(['template', field])
      value = templateValue || element.getIn(['template', 'fields', field, 'default_value'])
    }
    let input = null
    const cssClass = `element-field-${field} form-control`


    if (field == 'note') {
      input = (
        <textarea
          className={cssClass}
          name={name}
          defaultValue={value}
          disabled={!editable}
          onChange={e => this.handleInputChange(e)}
        />
      )
    } else {
      input = (
        <input
          className={cssClass}
          required="required"
          type="number"
          name={name}
          defaultValue={value}
          disabled={!editable}
          onChange={e => this.handleInputChange(e)}
        />
      )
    }

    return (
      <div key={field} className="col-sm-6 mb-2">
        <div className="row">
          <label className="col-4">
            {I18n.t(`activerecord.attributes.elements/base_element.${field}`)}
:
          </label>
          <div className="col-8">
            {input}
          </div>
        </div>
      </div>
    )
  }
}

export default ElementInput
