import * as React from 'react'
import { List } from 'immutable'
import Element, { elementSubtotal } from './element'

type ElementListProps = {
  elements: List<any>
  editElement: (element: any) => void
  removeElement: (element: any) => void
  inputAttributeName: (name: string, row: string) => string
}

export default function ElementList(props: ElementListProps) {
  const { elements, editElement, removeElement, inputAttributeName } = props
  return (
    <>
      {elements.valueSeq().map((element, index) => {
        const id = element.get('id') === undefined ? element.get('key') : element.get('id')
        return (
          <Element
            key={id}
            element={element}
            row={index.toString()}
            inputAttributeName={inputAttributeName}
            subtotal={elementSubtotal(element)}
            editElement={editElement}
            removeElement={removeElement}
          />
        )
      })}
    </>
  )
}
