import { connect } from 'react-redux'
import { types, updateAttachable } from 'actions/uploader_actions'
import { createLoadingSelector } from 'libs/requests_status_handlers'

import { VCSpinner } from 'components/shared/spinner'
import Uploader from 'components/uploader_v2_app/uploader'
import FilesThrower from './files_thrower'

const I18n = window.I18n

const ProjectUploads = (props) => {

  const { buttonId, targetKey, updateAttachable, isReqStateInProgress } = props

  const onClick = () => updateAttachable(buttonId, targetKey)

  return (
    <div className="col-12">
      <h4>
        <FilesThrower title={I18n.t('project_tickets.titles.share_files')} onClick={onClick} />
        {I18n.t('project_tickets.titles.project_uploads')}
        <VCSpinner visible={isReqStateInProgress} viewType="inline" size="1" />
      </h4>
      <hr />
      <Uploader {...props} />
    </div>
  )
}

const isReqStateInProgress = createLoadingSelector([types.UPDATE_ATTACHABLE])

export default connect((state, props) => {
  const uploaderData = state.getIn(['uploader', props.buttonId])?.toJS() || {}
  return ({
    isReqStateInProgress: isReqStateInProgress(state) || uploaderData.state == 'uploading',
    ...(state.getIn(['uploader', props.buttonId])?.toJS() || {})
  })
}, { updateAttachable })(ProjectUploads)
