import React, { useEffect, useRef } from 'react'

const I18n = window.I18n

const NextBtn = () => {
  const buttonRef = useRef(null)

  useEffect(() => {
    const handleClick = (e) => {
      e.preventDefault()
      const form = document.querySelector('[id^="edit_campaign"]')
      if (form) {
        const hiddenInput = document.createElement('input')
        hiddenInput.type = 'hidden'
        hiddenInput.name = 'next'
        hiddenInput.value = true

        form.appendChild(hiddenInput)

        form.submit()
      }
    }

    const button = buttonRef.current
    if (button) {
      button.addEventListener('click', handleClick)
    }

    return () => {
      if (button) {
        button.removeEventListener('click', handleClick)
      }
    }
  }, [])

  return (
    <button className="btn btn-secondary action input_action form-group " id="next" ref={buttonRef}>
      {I18n.t('application.actions.next')}
    </button>
  )
}

export default NextBtn
