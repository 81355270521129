const { $, I18n } = window

class ExpenseCategory {
  static fromType(type, selectField) {
    if (type === 'financial') {
      return new ExpenseCategoryFinancial(selectField)
    }
    if (type === 'freeprice') {
      return new ExpenseCategoryFreePrice(selectField)
    }
    return new ExpenseCategoryTime(selectField)
  }

  constructor(selectField) {
    this.selectField = selectField
    this.selectField = $(this.selectField)
  }

  renderInputs() {
    this.removeInputs()
    const widgets = this.selectField.data('valueWidgets')
    const input = decodeURIComponent(widgets[this.type])
    this.selectField.parent().parent().after(input)
    this.updateWidgetValue()
    this.bindEvents()
    this.updateValue(this.getValue())
  }

  removeInputs() {
    $('.value_widget').remove()
  }

  updateValue(value) {
    $('#expenses_project_expense_value').val(value)
  }

  bindEvents() {
    this.eventTargets().on('keyup change', (e) => {
      this.updateValue(this.getValue())
    })
  }
}

class ExpenseCategoryFinancial extends ExpenseCategory {
  static initClass() {
    this.prototype.type = 'financial'
  }

  updateWidgetValue() {
    $('#expense_value_financial').val(this.selectField.data('value'))
  }

  renderInputs() {
    super.renderInputs()
    const $currencySelect = $('#expense_unit_financial')
    $currencySelect.find('option').remove()

    const currencies = this.selectField.find(':selected').data('currencies')
    const selectedCurrency = $('form.expenses_project_expense').data('unit')
    if (currencies) {
      currencies.split(' ').forEach((currency) => {
        const selected = currency == selectedCurrency ? ' selected="selected" ' : ''
        const title = I18n.t(`project_expenses.currencies.${currency}`)
        $currencySelect.append(`<option ${selected} value="${currency}">${title}</option>`)
      })
    }
  }

  eventTargets() {
    return $('.value_widget').find('input')
  }

  getValue() {
    return $('#expense_value_financial').val()
  }
}

ExpenseCategoryFinancial.initClass()

class ExpenseCategoryFreePrice extends ExpenseCategoryFinancial {
  static initClass() {
    this.prototype.type = 'freeprice'
  }
}

ExpenseCategoryFreePrice.initClass()

class ExpenseCategoryTime extends ExpenseCategory {
  static initClass() {
    this.prototype.type = 'time'
  }

  updateWidgetValue() {
    const value = parseInt(this.selectField.data('value'), 10)
    const hours = Math.floor(value / 60.0)
    const minutes = value - hours * 60
    $('select#expense_value_time_h').find(`option[value='${hours}']`).attr('selected', true)
    $('select#expense_value_time_min').find(`option[value='${minutes}']`).attr('selected', true)
  }

  eventTargets() {
    return $('.value_widget').find('select')
  }

  getValue() {
    const hours = parseInt($('#expense_value_time_h').val(), 10)
    const minutes = parseInt($('#expense_value_time_min').val(), 10)
    return hours * 60 + minutes
  }
}

ExpenseCategoryTime.initClass()

export default ExpenseCategory
