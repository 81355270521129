import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { WidgetFactoryInterface } from '../survey_answer_form'

export default function Radio({ field, fieldName }: WidgetFactoryInterface) {
  const { choices, required } = field
  const { register } = useFormContext()
  return (
    <li className={`radio input form-group ${required ? 'required' : ''}`}>
      <fieldset className="choices">
        <legend className="label">
          {field.name}
          {required && <abbr title="required">*</abbr>}
        </legend>
        <ol className="choices-group">
          {choices.map((choice) => (
            <li className="choice form-check" key={choice.id}>
              <label htmlFor={`surveys_answer_field_answers_attributes_value_${choice.id}`}>
                <input
                  id={`surveys_answer_field_answers_attributes_value_${choice.id}`}
                  className="form-check-input"
                  type="radio"
                  value={choice.id}
                  {...register(fieldName)}
                />
                {choice.title}
              </label>
            </li>
          ))}
        </ol>
      </fieldset>
    </li>
  )
}
