import * as React from 'react'
import { useFormContext } from "react-hook-form"
import { WidgetFactoryInterface } from '../survey_answer_form'

export default function CheckBoxes({ field, fieldName }: WidgetFactoryInterface) {
  const { choices, required } = field
  const { register } = useFormContext()
  const fieldId = fieldName.replace(/[\[\]]/g, '_')

  return (
    <li className={`check_boxes input form-group ${required ? 'required' : ''}`}>
      <fieldset className="choices">
        <legend className="label">
          <label>
            {field.name}
            {required && <abbr title="required">*</abbr>}
          </label>
        </legend>
        {/* <input type="hidden" name="surveys_answer[field_answers_attributes][1][value][]" value=""> */}
        <ol className="choices-group">
          {choices.map((choice) => (
            <li className="choice form-check" key={choice.id}>
              <label htmlFor={`${fieldId}_${choice.id.toString()}`}>
                <input
                  id={`${fieldId}_${choice.id.toString()}`}
                  className="form-check-input"
                  type="checkbox"
                  value={choice.id}
                  {...register(fieldName)}
                />
                {choice.title || choice.name}
              </label>
            </li>
          ))}
        </ol>
      </fieldset>
    </li>
  )
}
