import { useState, useCallback } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

const I18n = window.I18n

const ControlRow = ({ caption, value, setForAll }) => {
  if (!value) { return null }

  return (
    <tr>
      <td>{caption}</td>
      <td>{value}</td>
      <td className="text-center">
        <Button outline color="primary" onClick={setForAll}>{I18n.t('application.actions.apply')}</Button>
      </td>
    </tr>
  )
}

const ModalWindow = ({ isOpen, toggle, title, min, max, optimalTagAmount, setMinForAll, setMaxForAll, setCustomAmountForAll }) => {

  const [customValue, setCustomValue] = useState('')

  const changeCustomValue = (e) => setCustomValue(e.target.value)

  const applyCustomAmount = useCallback(() => setCustomAmountForAll(customValue), [customValue])

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg" className="modal-amount-variants">
      <ModalHeader toggle={toggle}>
        {title}
      </ModalHeader>
      <ModalBody>
        <table className="table">
          <tbody>
            <ControlRow caption={I18n.t('application.titles.minimum')} value={min} setForAll={setMinForAll} />
            <ControlRow caption={I18n.t('application.titles.maximum')} value={max} setForAll={setMaxForAll} />
            <tr>
              <td>{I18n.t('application.titles.custom_value')}</td>
              <td><input type="number" className="form-control" min={min} max={max} value={customValue} onChange={changeCustomValue}/></td>
              <td className="text-center">
                <Button outline color="primary" onClick={applyCustomAmount}>{I18n.t('application.actions.apply')}</Button>
              </td>
            </tr>
          </tbody>
        </table>
      </ModalBody>
    </Modal>
  )
}

export default ModalWindow
