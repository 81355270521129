import React from 'react'

const I18n = window.I18n

export default class BackButton extends React.Component {
  render() {
    return (
      <button type="button" className="btn btn-secondary" id="save_and_go_to_overview" onClick={this.props.onClick}>
        {I18n.t('application.actions.back')}
      </button>
    )
  }
}
