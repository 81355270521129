import * as React from 'react'
import { ProjectReportFilterDefinitionType } from './types'

type FilterSelectProps = {
  filter: ProjectReportFilterDefinitionType
  initialAvailableFilters: ProjectReportFilterDefinitionType[]
  selectedFilters: { [key: string]: { value: string; label: string }[] }
  setSelectedFilters: (filters: { [key: string]: { value: string; label: string }[] }) => void
}
export default function FilterSelect(props: FilterSelectProps) {
  const { filter, initialAvailableFilters, selectedFilters, setSelectedFilters } = props

  return (
    <select
      className={`projects-report-filter-${filter.name} form-control mr-2 w-auto`}
      name={filter.name}
      value=""
      onChange={(e) => {
        e.preventDefault()
        const definition = initialAvailableFilters.find((f) => f.name === filter.name)
        const option = definition?.options.find((o) => o.value.toString() === e.target.value)

        setSelectedFilters({
          ...selectedFilters,
          [filter.name]: selectedFilters[filter.name] ? [...selectedFilters[filter.name], option] : [option],
        })
      }}
    >
      <option>{filter.label}</option>
      {filter.options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  )
}
