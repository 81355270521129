import { connect } from 'react-redux'
import { Map } from 'immutable'
import {
  setAvailableFilters,
  setSavedFilters,
  setSelectedFilters,
  loadAvailableFilters,
  updateUserFilters,
  types,
} from '../../../../actions/reports/projects_report'

import { createLoadingSelector } from '../../../../libs/requests_status_handlers'

const loadingSelector = createLoadingSelector([types.LOAD_AVAILABLE_FILTERS])

export default connect(
  (state: Map<string, any>) => ({
    availableFilters: state.getIn(['reports', 'projectsReport', 'availableFilters']).toJS(),
    savedFilters: state.getIn(['reports', 'projectsReport', 'savedFilters']).toJS(),
    selectedFilters: state.getIn(['reports', 'projectsReport', 'selectedFilters']).toJS(),
    isFetching: loadingSelector(state),
  }),
  {
    setAvailableFilters,
    setSavedFilters,
    setSelectedFilters,
    loadAvailableFilters,
    updateUserFilters,
  }
)
