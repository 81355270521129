import pageChangesStore from 'stores/page_changes_store'
import { types as LOCATIONS_TYPES } from 'actions/campaigns/locations_selector_actions'
import { types as ELEMENTS_TYPES  } from 'actions/campaigns/elements_selector_actions'

const $ = window.$

const getReduxMonitOptionsBasedOnRouteInfo = () => {
  const routeInfo = $('#route-info').data('route')
  if (routeInfo == 'campaigns-elements') {
    return {
      except: [ELEMENTS_TYPES.INIT_ELEMENTS],
      only:   Object.values(ELEMENTS_TYPES)
    }
  }
  else if (routeInfo == 'campaigns-locations') {
    return {
      except: [LOCATIONS_TYPES.INIT_BOXES],
      only:   Object.values(LOCATIONS_TYPES)
    }
  }
  else return { }
}

const handleConformation = (lnkTarget) => {
  const $link = $(lnkTarget)
  const $modal = $('#leave_step_confirm')
  $('button.ok', $modal).one('click', (e) => {
    window.location.href = $link.prop('href')
  })
  $('button.cancel', $modal).one('click', (e) => {
    $modal.modal('hide')
  })
  $modal.modal('show')
}

let pageChanged = false

const listener = pageChangesStore.addListener('changed', () => {
  pageChanged = true
})

export default function (disableReduxMonitor = false) {
  pageChangesStore.addJqMonitor('form.campaign')

  if (!disableReduxMonitor) {
    pageChangesStore.addReduxStateMonitor(['campaigns'], getReduxMonitOptionsBasedOnRouteInfo())
  }

  $('.steps .step a').on('click', (e) => {
    if (pageChanged) { e.preventDefault(); handleConformation(e.currentTarget) }
  })

  $( window ).unload(() => listener.remove() )
}
