import Select from 'components/shared/base_react_select'

I18n = window.I18n

export default ({options, selected, handleChange}) => {
  const onChange = (opts) => handleChange('statuses', opts)

  return (
    <div className="status-filter">
      <Select 
        isMulti
        value={selected}
        options={options}
        onChange={onChange}
        placeholder={I18n.t('activerecord.models.project_status.other')}
      />
    </div>
  )
}