import WaitingUpdatesChannel from 'acable_channels/waiting_updates'
import axios from 'axios'

const { $ } = window

const performRefresh = ($target) => {
  const { url, id } = $target.data()
  const $spinner = $('.spinner')
  $spinner.css('display', 'inline-block')

  return axios({
    url: `${url}.json?id=${id}`,
    method: 'get',
    responseType: 'json',
  })
}

const channelData = () => ({
  channel: 'PageDataChangedChannel',
  page: 'campaigns#index',
})

class CalculateTotalAmount {
  constructor (totalCount) {
    this.totalCount = totalCount
    this.valueEl = $('b.value', this.totalCount)
  }

  calculate () {
    this.total = 0.0
    $('table.stock-list-report tr:visible td.rest-cost').each((idx, el) => {
      const $el = $(el)
      const cellValue = parseFloat($el.text().replace('.', '').replace(',', '.'))
      this.total = this.total + parseFloat(cellValue.toFixed(2))
    })
  }

  updateView () {
    if (this.totalCount.not(':visible')) {
      this.totalCount.removeClass('hidden')
    }
    this.valueEl.text(this.formatResult(this.total.toFixed(2)))
  }

  formatResult (result) {
    const parts = result.toString().split('.')
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    return parts.join(',')
  }
}


export default function () {
  const $reportTable = $('table.stock-list-report')
  if ($reportTable.length > 0) {
    const $totalAmount = $('#total_amount')
    const totalAmountCalculator = new CalculateTotalAmount($totalAmount)
    $totalAmount.on('calculate', () => {
      totalAmountCalculator.calculate()
      totalAmountCalculator.updateView()
    })
    const table = $reportTable.DataTable({
      paging: false,
      orderCellsTop: false,
      autoWidth: false,
    })

    $totalAmount.trigger('calculate')
    table.on('draw', () => $totalAmount.trigger('calculate'))

    table.columns().eq(0).each((colIdx) => {
      $('input[type=text]', table.column(colIdx).header()).on('keyup change', e => (
        table.column(colIdx).search(e.target.value, true, false).draw()
      ))
      $('input', table.column(colIdx).header()).on('click', e => e.stopPropagation())
    })
  }

  const $refresh = $('i.fa-refresh')
  $refresh.on('click', e => performRefresh($refresh))
  new WaitingUpdatesChannel({ channel: 'PageDataChangedChannel', page: $refresh.data('page') })


  const $exportReport = $('#js-specsavers-export')
  $exportReport.on('click', (e) => {
    e.preventDefault()
    e.stopPropagation()

    const datatable = $reportTable.DataTable()

    if (datatable.page.info().recordsDisplay < 1) {
      return
    }

    const indexes = $reportTable.DataTable().rows({ search: 'applied' }).indexes().toArray()
    const url = $(e.currentTarget).attr('href')
    $.ajax({
      url,
      data: { indexes },
      type: 'post',
      dataType: 'json',
    })
  })
}
