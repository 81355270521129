import { useState } from 'react'
import store from 'store'
import { Provider } from 'react-redux'
import CreateButton from './button'
import ModalWindow from './modal_window'
import connect from './connect'

const ConnectedModalWindow = connect(ModalWindow)

const CreateButtonApp = (props) => {

  const [isOpen, setOpenFlag] = useState(false)
  const toggle = () => setOpenFlag(prevVal => !prevVal)

  return (
    <Provider store={store}>
      <div className="create-album-btn">
        <CreateButton onClick={toggle} />
        <ConnectedModalWindow toggle={toggle} isOpen={isOpen} {...props} />
      </div>
    </Provider>
  )
}

export default CreateButtonApp
