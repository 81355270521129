import { Component } from 'react'
import PropTypes from 'prop-types'
import PhotoswipeLink from 'components/shared/photoswipe/photoswipe_link'

export default class Link extends Component {
  static propTypes = {
    link: PropTypes.object.isRequired,
    photo: PropTypes.object.isRequired,
  }

  render () {
    const { photo, link } = this.props
    return (
      <div
        style={{ backgroundImage: `url(${photo.get('thumb_url')})` }}
        src={photo.get('thumb_url')}
        className="mr-1 project-element-photo-link-container"
      >
        <PhotoswipeLink
          className="card-img-overlay"
          src={photo.get('large_url')}
          galleryId={`element-links-${link.get('element_id')}`}>
        </PhotoswipeLink>
      </div>
    )
  }
}
