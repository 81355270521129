import { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export default class MyFile extends Component {
  static propTypes = {
    file: PropTypes.object,
  }

  getContainer (file) {
    if (file.isCompleted()) { return 'a' }
    return 'div'
  }

  getContainerAttrs (file) {
    if (!file.isCompleted()) { return {} }
    return { href: file.path }
  }

  clickDelete = () => {
    this.props.onDelete(this.props.file.id)
  }

  render () {
    const { file } = this.props
    const Container = this.getContainer(file)
    const statusStyle = `my-files-my-file-${file.status}`
    return (
      <tr>
        <td>{file.companyName}</td>
        <td>
          <Container {...this.getContainerAttrs(file)} className={classNames(statusStyle, 'd-block')}>
            <i className={classNames('fa', 'icon-default', file.getIcon())} />
            {file.getName()}
          </Container>
        </td>
        <td>
          <a className={classNames("delete-icon", "my-files-my-file-delete-control")} onClick={this.clickDelete} ref="delete"></a>
        </td>
      </tr>
    )
  }
}
