import { connect } from 'react-redux'
import {
  toggleDialog,
  saveSharingLink,
  loadSharingLinks,
  deleteSharingLink,
  types,
} from 'actions/sharing_links_actions'
import { createLoadingSelector } from 'libs/requests_status_handlers'

const loadingSelector = createLoadingSelector([types.SAVE_SHARING_LINK, types.DELETE_SHARING_LINK])

export default connect(
  (state) => ({
    dialogOpened: state.getIn(['sharingLinks', 'dialogOpened']),
    currentLink: state.getIn(['sharingLinks', 'currentLink']),
    successMessage: state.getIn(['sharingLinks', 'successMessage']),
    errorMessage: state.getIn(['sharingLinks', 'errorMessage']),
    links: state.getIn(['sharingLinks', 'links']).toJS(),
    isFetching: loadingSelector(state),
  }),
  {
    toggleDialog,
    saveSharingLink,
    loadSharingLinks,
    deleteSharingLink,
  }
)
