import * as React from 'react'
import { ProjectReportFilterDefinitionType, SelectedFiltersType } from './types'

type ShowCompletedCheckboxProps = {
  showCompletedLabel: string
  selectedFilters: SelectedFiltersType
  setSelectedFilters: (filters: SelectedFiltersType) => void
  initialAvailableFilters: ProjectReportFilterDefinitionType[]
  completedStatusId: string
}

export default function ShowCompletedCheckbox(props: ShowCompletedCheckboxProps) {
  const { selectedFilters, setSelectedFilters, initialAvailableFilters, completedStatusId, showCompletedLabel } = props
  const checked =
    selectedFilters && selectedFilters.status
      ? selectedFilters.status.some((status) => status.value === completedStatusId)
      : false

  const setChecked = (e) => {
    if (e.target.checked) {
      const statusOptions = initialAvailableFilters.find((filter) => filter.name === 'status').options
      const completedOption = statusOptions.find((option) => option.value === completedStatusId)

      const newSelectedStatuses = selectedFilters.status ? [...selectedFilters.status, completedOption] : [completedOption]
      setSelectedFilters({
        ...selectedFilters,
        status: newSelectedStatuses,
      })
    } else if (selectedFilters.status.length === 1) {
      const { status: _, ...newSelectedFilters } = selectedFilters
      setSelectedFilters(newSelectedFilters)
    } else {
      setSelectedFilters({
        ...selectedFilters,
        status: selectedFilters.status.filter((status) => status.value !== completedStatusId),
      })
    }
  }
  return (
    <div className="form-check mb-4">
      <input
        type="checkbox"
        className="form-check-input mr-2"
        id="showCompletedCheckbox"
        checked={checked}
        onChange={setChecked}
      />
      <label className="form-check-label" htmlFor="showCompletedCheckbox">
        {showCompletedLabel}
      </label>
    </div>
  )
}
