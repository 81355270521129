import { connect } from 'react-redux'
import { dropToSelected, dropToAvailable, loadSelected } from 'actions/elements_groups_enabled_columns'

function mapStateToProps (state) {
  return {
    selectedColumns: state.getIn(['element', 'group', 'enabledColumns', 'selectedColumns']),
  }
}

const mapDispatchToProps = { dropToSelected, dropToAvailable, loadSelected }

export default connect(mapStateToProps, mapDispatchToProps)
