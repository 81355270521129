import TagBadge from 'components/shared/tags/badge'

const ItemTags = ({ tags }) => {
  if (tags.length == 0) { return null }

  return (
    <div>
      {tags.map(tag => <TagBadge name={tag.name} type={tag.type} key={`tag-key-${tag.value}`} />)}
    </div>
  )
}

export default ItemTags
