import * as React from 'react'
import { useEffect } from 'react'
import { Provider } from 'react-redux'
import store from '../../../store'
import { loadDictionaries, loadElements } from '../../../actions/elements_order'
import TemplatePreview from './template_preview'
import Form from './form'
import FilterTabs from './filter_tabs'

type ElementsOrderAppProps = {
  elements: any
  locationId: number
  dictionaries: {
    elementGroups: any[]
    elementSubGroups: any[]
    departments: any[]
    locationGroups: any[]
    locationSubGroups: any[]
    locationDepartments: any[]
  }
  accessControl: {
    canSendOrder: boolean
    canApproveOrder: boolean
    canEditOrder: boolean
  }
  form: {
    submitData: {
      url: string
      method: string
    }
    submitMethod: string
    tableHeaderTitle: string
    authenticityToken: string
    deliveryDate: number
    comment: string
    availableCountries: string[]
  }
}

export default function ElementOrderApp(props: ElementsOrderAppProps) {
  const { elements, locationId, dictionaries } = props

  useEffect(() => {
    store.dispatch(loadDictionaries(dictionaries))
    store.dispatch(loadElements(elements))
  }, [])

  const { accessControl, form } = props
  return (
    <Provider store={store}>
      <div>
        {accessControl.canEditOrder && <FilterTabs locationId={locationId} accessControl={accessControl} />}
        {accessControl.canEditOrder && <TemplatePreview />}
        <Form
          submitData={form.submitData}
          tableHeaderTitle={form.tableHeaderTitle}
          accessControl={accessControl}
          authenticityToken={form.authenticityToken}
          deliveryAttention={form.deliveryAttention}
          deliveryDate={form.deliveryDate}
          deliveryPhoneNumber={form.deliveryPhoneNumber}
          deliveryInfo={form.deliveryInfo}
          comment={form.comment}
          locationAddress={form.locationAddress}
          orderAddress={form.orderAddress}
          availableCountries={form.availableCountries}
        />
      </div>
    </Provider>
  )
}
