import React, { Fragment } from 'react'
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';

const I18n = window.I18n

const initState = (fields) => {
  const [...fieldsKeys] = fields.keys()
  return fieldsKeys.reduce((result, key) => {
    if (!['print_colors', 'product_type'].includes(key)) {
      result[key] = ''
    }
    return result
  }, { print_color_id: '', product_type_id: '' })
}

class CreateElementForm extends React.Component {

  constructor (props) {
    super(props)
    this.state = initState(this.props.fields)
  }

  onChange = (e) => {
    this.setState(Object.assign(this.state, { [e.target.name]: e.target.value }))
  }

  onSumbit = () => {
    this.props.createElement(this.state)
  }

  static getDerivedStateFromProps(props, state) {
    if (props.needToResetForm) { return initState(props.fields) }
    else return null
  }

  render () {
    const { fields } = this.props
    return (
      <Form>
        <FormGroup>
          <Label for="campaign_element_name">{fields.getIn(['name', 'caption'])}</Label>
          <Input type="text" name="name" id="campaign_element_name" value={this.state.name} onChange={this.onChange} />
        </FormGroup>

        <FormGroup>
          <Label for="campaign_element_amount">{fields.getIn(['amount', 'caption'])}</Label>
          <Input type="text" name="amount" id="campaign_element_amount" value={this.state.amount} onChange={this.onChange} />
        </FormGroup>

        <FormGroup>
          <Label for="campaign_element_height">{fields.getIn(['height', 'caption'])}</Label>
          <Input type="text" name="height" id="campaign_element_height" value={this.state.height} onChange={this.onChange} />
        </FormGroup>

        <FormGroup>
          <Label for="campaign_element_width">{fields.getIn(['width', 'caption'])}</Label>
          <Input type="text" name="width" id="campaign_element_width" value={this.state.width} onChange={this.onChange} />
        </FormGroup>

        <FormGroup>
          <Label for="campaign_element_print_colors">{fields.getIn(['print_colors', 'caption'])}</Label>
          <Input type="select" name="print_color_id" id="campaign_element_print_colors" value={this.state.print_color_id} onChange={this.onChange}>
            { fields.getIn(['print_colors', 'collection']).map(item => (
                <option key={`print_colors_key_${item.get(1)}`} value={item.get(1)}>{item.get(0)}</option>
              )
            )}
          </Input>
        </FormGroup>

        <FormGroup>
          <Label for="campaign_element_product_type">{fields.getIn(['product_type', 'caption'])}</Label>
          <Input type="select" name="product_type_id" id="campaign_element_product_type" value={this.state.product_type_id} onChange={this.onChange}>
            { fields.getIn(['product_type', 'collection']).map(item => (
                <option key={`product_type_key_${item.get(1)}`} value={item.get(1)}>{item.get(0)}</option>
              )
            )}
          </Input>
        </FormGroup>
        <Button color="primary" onClick={this.onSumbit}>{I18n.t('application.actions.create')}</Button>
      </Form>
    )
  }
}

export default CreateElementForm
