import React from 'react'
import ItemTypes from '../lib/item_types'

export default  class RowSettings extends React.Component {

  static type = ItemTypes.ROW

  onChange = (e) => {
    let quantityCells = parseInt(e.target.value)

    if (quantityCells > 12) { quantityCells = 12 }
    else if (quantityCells < 1) { quantityCells = 1 }

    let row = this.props.item
    if (row.cells.length == quantityCells) { return }
    if (!isNaN(quantityCells)) {
      if (row.cells.length > quantityCells) { row.cells.pop() }
      else { row.cells.push(ItemTypes.CELL.init) }
      this.updateItem(row)
    }
  }

  updateItem = (data) => {
    this.props.handlers.updateItem(ItemTypes.ROW.type, data, this.props.path)
  }

  render () {
    const row = this.props.item
    return (
      <div className="row-settings">
        <div className="form-group">
          <label className="label">Cells</label>
          <div className="default-form-input-wrapper">
            <input type="number" className="form-control q-cells-field" min="1" max="12" name="cells" value={row.cells.length} onChange={this.onChange} />
          </div>
        </div>
      </div>
    )
  }
}
