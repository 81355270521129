import ModalWindow from './modal_window'

const I18n = window.I18n

const ModalWithProjects = ({ name, projects, isOpen, closeModal }) => {
  if (projects.length  == 0) { return null }

  return (
    <ModalWindow header={`${name} (${projects.length}): `} onClose={closeModal} isOpen={!!isOpen} scrollable={true}>
      <table className="table table-sm">
        <thead>
          <tr>
            <th>{I18n.t('activerecord.attributes.project_ticket.location')}</th>
            <th>{I18n.t('activerecord.attributes.project_ticket.title')}</th>
            <th>{I18n.t('activerecord.attributes.project_ticket.created_at')}</th>
          </tr>
        </thead>
        <tbody>
          { projects.map((project) => (
              <tr key={`project-item-${project.id}`}>
                <td><a href={project.location_link}>{project.location_name}</a></td>
                <td><a href={project.link}>{project.title}</a></td>
                <td>{project.created_at}</td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </ModalWindow>
  )
}

export default ModalWithProjects
