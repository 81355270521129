import * as React from 'react'
import { FormRegistryShape, SurveyElementShape } from "./survey_answer_form"
import ElementWrapper from './elements/element_wrapper'
import Field from './field'
import Uploader from './fields/uploader'

interface ElementProps {
  visible: boolean
  element: SurveyElementShape
  fieldsRegistry: FormRegistryShape
  registerFormField: (any) => object
}

export default function Element({ element, registerFormField, fieldsRegistry, visible }: ElementProps) {
  if (!visible) return null

  const registry = fieldsRegistry.elements[element.id]
  const { answer } = element

  const answerForField = (field) => {
    if (!answer) return null
    if (!answer.fieldAnswers) return null

    return answer.fieldAnswers.find((fieldAnswer) => fieldAnswer.surveysFieldId === field.id)
  }

  return (
    <ElementWrapper element={element}>
      <fieldset className="col">
        {element.fields.map((field) => (
          <Field
            key={field.id}
            answer={answerForField(field)}
            field={field}
            fieldsRegistry={fieldsRegistry}
          />
        ))}
        {element.uploaderEnabled && <Uploader element={element} name={registry.uploaderName} fieldName={registry.uploaderFieldName} />}
        {answer && answer.id && <input type="hidden" value={answer.id} {...registerFormField(registry.answerIdName)} />}
        <input type="hidden" value={element.id} {...registerFormField(registry.elementIdName)} />
      </fieldset>
    </ElementWrapper>
  )
}
