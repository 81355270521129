import React from 'react'
import moment from 'moment'
import ReactHover, { Trigger, Hover } from 'react-hover'

const DAYS_TO_RENDER_BEFORE_TERM = 1
const DAYS_TO_RENDER_AFTER_TERM = 3
const MINIMAL_DAYS_TO_RENDER = 20

// Helper class for manipulating a calendar abstraction.
class CalendarHelper {

  constructor (startDate, endDate) {
    this.startDate = moment(startDate)
    this.endDate = moment(endDate)
  }

  // Beginning of the calendar
  start () {
    return this.startDate.clone().subtract(DAYS_TO_RENDER_BEFORE_TERM, 'day')
  }

  // End of the calendar
  end () {
    const firstDay = this.start()
    const lastDay = this.endDate.clone().add(DAYS_TO_RENDER_AFTER_TERM, 'days')

    const termLength = lastDay.diff(firstDay, 'days') + 2
    if (termLength < MINIMAL_DAYS_TO_RENDER) {
      return firstDay.add(MINIMAL_DAYS_TO_RENDER, 'days')
    }
    return lastDay
  }

  // Actual amount of days in the calendar
  totalDays () {
    return this.end().diff(this.start(), 'days') + 2
  }

  months () {
    const lastMonth = this.end().endOf('month')
    let currentMonth = this.start().startOf('month')
    const months = [currentMonth]
    while (currentMonth.isBefore(lastMonth, 'month')) {
      currentMonth = currentMonth.clone().add(1, 'month')
      months.push(currentMonth)
    }

    return months
  }

  days () {
    const result = []
    const lastDay = this.end().add(1, 'day')
    let currentDay = this.start()

    do {
      result.push(currentDay)
      currentDay = currentDay.clone().add(1, 'day')
    } while (currentDay.isBefore(lastDay))

    return result
  }

  colspanFor (date) {
    if (this.start().month() === date.month()) {
      return date.daysInMonth() - this.start().date() + 1
    }
    return date.daysInMonth()
  }
}

function CalendarHeadRows (props) {
  const { startDate, endDate } = props
  const cal = new CalendarHelper(startDate, endDate)
  return (
    <>
      <tr>
        <FirstColumns />
        { cal.months().map(m => (
          <th key={m.format('MMM')} colSpan={cal.colspanFor(m)}>{m.format('MMM')}</th>
        )) }
      </tr>
      <tr className="rent-list-table-header-days">
        <FirstColumns />
        { cal.days().map(d => (
          <th key={d.format('MMM-DD')}>{d.format('DD')}</th>
        )) }
      </tr>
      <tr className="rent-list-table-header-days">
        <FirstColumns />
        { cal.days().map(d => (
          <th key={d.format('MMM-DD')}>{d.format('dd')}</th>
        )) }
      </tr>
    </>
  )
}

function CalendarElementColumns (props) {
  const {
    element, startDate, endDate, rentedItems, rentList,
  } = props
  const cal = new CalendarHelper(startDate, endDate)
  const { id } = element

  const cellColor = (day) => {
    const NOT_RENTED = 'transparent'
    const RENTED_IN_CURRENT_LIST = 'green'
    const RENTED_IN_OTHER_LIST = 'lightcoral'

    if (!rentedItems) { return NOT_RENTED }

    const items = rentedItems[id]
    if (!items) { return NOT_RENTED }
    const rentListId = rentList ? rentList.id : 0
    for (const item of items) {
      const list = item.rentList

      if (day.isBetween(list.startDate, list.endDate, 'day', '[]')) {
        return list.id == rentListId ? RENTED_IN_CURRENT_LIST : RENTED_IN_OTHER_LIST
      }

    }
    return NOT_RENTED
  }

  const eventName = (day) => {
    if (!rentedItems) { return }

    const items = rentedItems[id]
    if (!items) { return }
    const rentListId = rentList ? rentList.id : 0
    for (const item of items) {
      const list = item.rentList

      const end = moment(list.endDate)
      if (day.isBetween(list.startDate, end, 'day', '[]')) {
        if (list.id !== rentListId) {
          return `${list.companyName} - ${list.eventName}`
        }
        return
      }
    }
  }

  return (
    <>
      { cal.days().map((d) => {
        const color = cellColor(d)
        if (color === 'transparent') {
          return <td key={d.format('YY-MM-DD')} />
        }

        const event = eventName(d)
        if (!event) {
          return <td key={d.format('YY-MM-DD')} style={{ backgroundColor: color }} />
        }

        return (
          <td className="rent-list-table-hoverable-cell" key={d.format('YY-MM-DD')} style={{ backgroundColor: color }}>
            <ReactHover options={{ followCursor: true, shiftX: 20, shiftY: 0 }}>
              <Trigger type="trigger">
                <div className="rent-list-table-hoverable-cell-trigger">
                  &nbsp;
                </div>
              </Trigger>
              <Hover type="hover">
                <div className="rent-list-table-hoverable-cell-hover">
                  { event }
                </div>
              </Hover>
            </ReactHover>
          </td>
        )
      }) }
    </>
  )
}

function FirstColumns () {
  return (
    <th />
  )
}


export { CalendarHeadRows, CalendarElementColumns, CalendarHelper }
