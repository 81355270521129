import * as React from 'react'
import { WidgetFactoryInterface } from '../survey_answer_form'

export default function Label({ field }: WidgetFactoryInterface) {
  return (
    <li className="form-group">
      <label className="label">{field.name}</label>
      <div className="default-form-input-wrapper">
        <p>{field.defaultValue}</p>
      </div>
    </li>
  )
}
