import { connect } from 'react-redux'
import { assignInstallers } from '../../../../actions/project_ticket_actions'

const mapStateToProps = (state) => {
  return {
    errors: state.getIn(['projects', 'bulk_assign_installers', 'errors']),
    ready_to_reload_page: state.getIn(['projects', 'bulk_assign_installers', 'ready_to_reload_page']),
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    assignInstallers: (projectTicketIds, installerIds) => dispatch(assignInstallers(projectTicketIds, installerIds)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
