import React from 'react'
import FormModeContext from './form_mode_context'
import { getElementItemComponentByType } from '../lib/item_types'

export default class Element extends React.Component {
  static contextType = FormModeContext

  render () {
    const { element, path } = this.props
    const ElementItem = getElementItemComponentByType(element.type)
    
    return (
      <ElementItem element={element} path={path} mode={this.context.mode} />
    )
  }
}
