import React from 'react'
import { connect } from 'react-redux'
import AlbumName from './views/albums/name'
import Photo from './views/albums/photo'

class FavoriteAlbum extends React.Component {

  render () {
    const { photos, title } = this.props

    if (photos.length == 0) { return null }

    return (
      <div className="album" id="favorite_album">
        <AlbumName title={title} id="favorite_album_name" />
        <div className="gallery">
          { photos.map((photo) => <Photo key={photo.id} 
              {...photo} 
              albumTitle={this.props.title} 
              favorite= {false} 
            />            
          )}
        </div>
      </div>
    )
  }
}

export default connect((state, props) => {
  const favoritePhotos = props.uploaderIds.reduce((result, uploaderId) => {
    result = result.concat(
      state.getIn(['uploader', uploaderId, 'files']).filter(f => f.getIn(['favorite', 'enabled'])).toJS()
    )
    return result
  }, [])

  return ({
    title: props.title,
    photos: favoritePhotos
  })
}, {})(FavoriteAlbum)
