import { connect } from 'react-redux'

function mapStateToProps (state) {
  return {
    comments: state.getIn(['overview', 'comments']),
  }
}

function mapDispatchToProps (dispatch) {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
