import React from 'react'
import PropTypes from 'prop-types'


function AddressField ({
  field, disabled, name, value, onChange,
}) {
  const { I18n } = window
  let label = I18n.t(`activerecord.attributes.address.${field}`)
  if (field === 'line2') {
    label = I18n.t('elements.order_form.address_name')
  }
  return (
    <div className="form-group">
      <label className="label">{label}</label>
      <div className="default-form-input-wrapper">
        <input
          className="form-control"
          type="text"
          disabled={disabled}
          name={name}
          value={value}
          onChange={onChange}
        />
      </div>
    </div>

  )
}

AddressField.propTypes = {
  field: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default AddressField
