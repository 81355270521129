import React from 'react'

const I18n = window.I18n

export default class NextButton extends React.Component {

  render () {
    return (
      <button type="button" className="next btn btn-secondary" onClick={this.props.onClick}>
        {I18n.t('application.actions.next')}
      </button>
    )
  }
}
