import  WaitingUpdatesChannel from 'acable_channels/waiting_updates'
import notificationBlocker from 'libs/notifications_blocker'
import axios from 'axios'

const $ = window.$

const channelData = () => ({
  channel: 'PageDataChangedChannel',
  page: 'campaigns#index'
})

const checkChanges = (url) => {
  return axios({
    url: `${url}.json`,
    method: 'get',
    responseType: 'json',
    data: { }
  })
}

export default function () {
  // not need to show this type user notification
  // it because the user present on the page and the page will reload in case updates
  notificationBlocker.addCatchFor('campaign_wait_copying')

  const $waitCopying = $('#wait_copying')
  const count = $waitCopying.data('count') || 0
  const url   = $waitCopying.data('url')
  if (count) {
    new WaitingUpdatesChannel(channelData())

    // for case when channel was open after when message was sent - as resut it was missed
    checkChanges(url).then((response) => {
      const dataCount = parseInt(response.data?.count)
      if (!isNaN(dataCount) && count != dataCount) {
        window.location.reload(true)
      }
    })
  }
}
