export default ({ value, min, max, onChange }) => {
  return (
    <div className="text-center">
      <input
        className="form-control d-inline-block"
        type="number"
        name="campaign[items_attributes][][amount]"
        value={value}
        min={min}
        max={max}
        onChange={onChange}
      />
    </div>
  )
}
