import React from 'react'

export default ({ element, path, mode }) => {
  const { label, value, id, fieldAnswer } = element

  const inputId = `chk-${id || path.slice(-1)}`
  const isChecked = (fieldAnswer && value == fieldAnswer ? true : false)

  return (
    <div className="form-check">
      <input type="checkbox" id={inputId} name="fields_answers_attributes[][value]"
        className="form-check-input" defaultValue={value}
        disabled={mode.isReadOnly()}
        defaultChecked={isChecked}
      />
      <input type="hidden" name="fields_answers_attributes[][field_id]" value={id} />
      { label && <label htmlFor={inputId} className="form-check-label">{label}</label> }
    </div>
  )
}
