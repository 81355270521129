import { EventEmitter } from 'fbemitter'
import { remove, uniqWith, isEqual, isNumber, debounce } from 'lodash'
import { valueBasedOnMinMaxIsValid } from 'views/campaigns/helpers/min_max_validator'

class AmountValidator {
  constructor (amount, options) {
    this._amount = parseInt(amount) || 0
    this._minAmount = parseInt(options.minAmount) || ''
    this._maxAmount = parseInt(options.maxAmount) || ''
    this._tagsAmount = options.tagsAmount || []
    this._errorsCounter = 0
  }

  isSuccess () {
    return !this._errorsCounter
  }

  validatedMinMax () {
    const result = valueBasedOnMinMaxIsValid(this._amount, this._minAmount, this._maxAmount)

    if (result) { return true }

    this._errorsCounter++
    return false
  }

  validateTagsAmountsDuplicates () {
    const result = this._tagsAmount.length < 2

    if (result) { return true }

    this._errorsCounter++
    return false
  }

  validateTagsAmountsConflicts () {
    const result = isNumber(this._amount) && (this._tagsAmount.length == 0 || this._tagsAmount.includes(this._amount))

    if (result) { return true }

    this._errorsCounter++
    return false
  }
}

class CampaignAmountsStore extends EventEmitter {

  static CONFIG = {
    errorTypes: {
      minMax: 'MinMax',
      tagsAmountsDuplicates: 'TagsAmountsDuplicates',
      tagsAmountsConflicts: 'TagsAmountsConflicts'
    }
  }

  constructor () {
    super()
    this._amounts = {}
    this._errors  = { minMax: [], tagsAmountsDuplicates: [], tagsAmountsConflicts: [] }
    this._queueProcessedElementIds = []
  }

  get config () { return CampaignAmountsStore.CONFIG }

  setAmount (elementId, locationId, amount, validateOptions = null) {
    this._queueProcessedElementIds.push(elementId)
    this._amounts[elementId] = Object.assign({}, this._amounts[elementId] || {}, { [locationId]: parseInt(amount) })
    if (validateOptions) {
      this._validate(amount, { elementId, locationId }, validateOptions)
    }
    debounce(() => this._performFinalActions(), 1)()
  }

  setNewAmountThroughEvent (elementId, value) {
    Object.keys(this._amounts[elementId] || {}).forEach(locationId => {
      this.emit(`UpdateAmount-${elementId}-${locationId}`, value)
    })
  }

  getAmount (elementId, locationId) {
    const elementsAmounts =  this._amounts[elementId] || {}
    return elementsAmounts[locationId] || 0
  }

  totalFor (elementId) {
    const elementAmounts = this._amounts[elementId] || {}

    return Object.values(elementAmounts).reduce((result, val) => result + val, 0)
  }

  totalPrintsFor (elementId, pdfPagesCount) {
    const elementAmounts = this._amounts[elementId] || {}

    return Object.values(elementAmounts).reduce((result, val) => result + val, 0) * pdfPagesCount
  }

  _updatedElementLoationAmount (elementId, locationId) {
    this.emit(`updatedElementLoationAmount-${elementId}-${locationId}`)
  }

  _validate (amount, data, options) {
    const validator = new AmountValidator(amount, options)

    const errors = []

    if (validator.validatedMinMax()) {
      this._removeError('minMax', data)
    } else {
      this._errors.minMax.push(data)
      this._errors.minMax = uniqWith(this._errors.minMax, isEqual)
      errors.push(this.config.errorTypes.minMax)
    }

    if (validator.validateTagsAmountsDuplicates()) {
      this._removeError('tagsAmountsDuplicates', data)
    } else {
      this._errors.tagsAmountsDuplicates.push(data)
      this._errors.tagsAmountsDuplicates = uniqWith(this._errors.tagsAmountsDuplicates, isEqual)
      errors.push(this.config.errorTypes.tagsAmountsDuplicates)
    }

    if (validator.validateTagsAmountsConflicts()) {
      this._removeError('tagsAmountsConflicts', data)
    } else {
      this._errors.tagsAmountsConflicts.push(data)
      this._errors.tagsAmountsConflicts = uniqWith(this._errors.tagsAmountsConflicts, isEqual)
      errors.push(this.config.errorTypes.tagsAmountsConflicts)
    }

    this.emit(`ValidationResult-${data.elementId}-${data.locationId}`, errors)
  }

  _removeError (type, { elementId, locationId }) {
    remove(this._errors[type], (err) => (
      err.elementId == elementId && err.locationId == locationId)
    )
  }

  _performFinalActions () {
    this._updateTotal()
    this._showErrors()
  }

  _updateTotal () {
    while(this._queueProcessedElementIds.length) {
      this.emit(`TotalWasChanged-${this._queueProcessedElementIds.shift()}`)
    }
  }

  _showErrors () {
    const { minMax, tagsAmountsDuplicates, tagsAmountsConflicts } = this._errors

    this.emit('UpdateViewAmountMessagesArea', this._errors)
  }
}

export default new CampaignAmountsStore()
