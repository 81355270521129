import _ from 'underscore'
import CustomDatatableWithSearch from 'libs/datatables/custom_datatable_with_search'
export default function () {
  $(function() {
    $('.js-project-search-installer').on('change keyup', (e) => {
      let searchTerm = e.target.value.toLowerCase()
      $('.main .list-group .list-group-item').each(function(){
        const title = $(this).find('.title').text()
        const description = $(this).find('.description').text()
        if ( _.any([description, title], c => c.toLowerCase().includes(searchTerm)) ) {
          $(this).show()
        } else {
          $(this).hide()
        }
      })
    })

    new CustomDatatableWithSearch($('#projects'), $('#search'))
  })
}
