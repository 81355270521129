import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const { I18n } = window

const ProjectItem = ({ item }) => {
  const { projectGroup, title, href, statusName, statusCssClass, deadline } = item

  const statusClasses = classNames(['status_tag', 'badge', statusCssClass])

  return (
    <div className="card mb-2">
      <div className="card-body">
        <h5 className="card-title">
          <a href={href} target="_blank">
            {title}
          </a>
        </h5>
        <h6 className="card-subtitle">
          <span className={statusClasses}>{statusName}</span>
        </h6>
        <div>
          <b>{I18n.t('activerecord.models.project_group.one')}</b>
          <a className="ml-2" href={projectGroup.href} target="_blank">
            {projectGroup.name}
          </a>
        </div>

        <p className="card-text">
          <small className="text-muted">{deadline}</small>
        </p>
      </div>
    </div>
  )
}

ProjectItem.propTypes = {
  item: PropTypes.object.isRequired,
}

export default ProjectItem
