const $ = window.$

const initHideHandler = ($control, $container) => {
  const handleState = () => $control.is(':checked') && $container.hide()
  handleState()
  $control.on('click', (e) => handleState())
}

const initShwoHandler = ($control, $container) => {
  const handleState = () => $control.is(':checked') && $container.show()
  handleState()
  $control.on('click', (e) => handleState())
}

export default function() {

  const postorderPermissionsCont = $('.row#who_can_send_postorders')
  initHideHandler($('#campaign_postorder_enabled_false'), postorderPermissionsCont)
  initShwoHandler($('#campaign_postorder_enabled_true'), postorderPermissionsCont)

}
