import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Select from 'components/shared/base_react_select'
import SmModalWindow from '../../shared/modal_window'

const { I18n } = window

function ElementsNewOrderButton ({ locations }) {
  const [dialogOpened, setDialogOpened] = useState(false)
  const [location, setLocation] = useState(locations[0])
  const buttonTitle = I18n.t('elements.order_form.create')
  const url = location ? `/locations/${location.value}/elements_orders/new` : '#'
  const modalContent = (
    <div className="text-left">
      <Select
        options={locations}
        value={locations.filter(o => location == o)}
        onChange={v => setLocation(v)}
      />
    </div>
  )
  const modalButtons = (
    <div className="buttons-container">
      {location && (
      <a href={url} className="btn btn-primary">
        {buttonTitle}
      </a>
      )}
    </div>
  )
  return (
    <>
      <button className="btn btn-secondary" type="button" onClick={() => setDialogOpened(true)}>{buttonTitle}</button>
      <SmModalWindow
        title={I18n.t('elements.order_form.global_create_popup_title')}
        visible={dialogOpened}
        onClose={(e) => { setDialogOpened(false) }}
        content={modalContent}
        buttons={modalButtons}
        windowID="new_global_order"
      />
    </>
  )
}

ElementsNewOrderButton.propTypes = {
  locations: PropTypes.array.isRequired,
}

export default ElementsNewOrderButton
