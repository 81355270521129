import PropTypes from 'prop-types'
import LocationFilter from './location_filter'
import StatusFilter from './status_filter'

const Header = ({name, filtersData, selectedFilters, handleChangeFilter, colSize}) => {
  return (
    <>
      <div className="row overview-board-header">
        <div className="col-4 text-left">
          <h1>{name}</h1>
        </div>
        <div className="col-4">
          <LocationFilter options={filtersData.locations} selected={selectedFilters.locations} handleChange={handleChangeFilter} />
        </div>
        <div className="col-4">
          <StatusFilter options={filtersData.statuses} selected={selectedFilters.statuses}  handleChange={handleChangeFilter} />
        </div>
      </div>
    </>
  )
}

Header.propTypes = {
  name: PropTypes.string,
  filtersData: PropTypes.object,
  selectedFilters: PropTypes.object,
  handleChangeFilter: PropTypes.func.isRequired,
  colSize: PropTypes.number,
}

export default Header
