import React from 'react'
import { findDOMNode } from 'react-dom'
import classNames from 'classnames'
import { useDrop } from 'react-dnd'
import { flow } from 'lodash'

import ItemTypes from '../lib/item_types'
import connect from './cell_connect'
import ElementItem from './draggable_element'

const canMoveElement = (path) => {
  return path && path.length == 2
}

const CellItem = (
  { path, cell, selectedItem, deleteAllowed, deleteCellItem, updateCellItem, deleteElementItem, moveElementItem, onClickGear }
) => {

  const index = path[path.length - 1]

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ItemTypes.ELEMENT.type,
    drop: (item) => { moveElementItem(item.path, path) },
    canDrop: (item) => !item.toPath,
    collect: monitor => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  })

  const cellClasses = ['col', 'cell-item']
  if (cell == selectedItem) { cellClasses.push('selected') }

  const elements = (cell.elements || []).reduce((result, element, index) => {
    if (element._destroy) { return result }
    result.push(
      <ElementItem key={`element-item-${index}`}
        element={element} path={[...path, index]}
        moveElementItem={moveElementItem}
        selectedItem={selectedItem}
        onClickDelete={deleteElementItem}
        onClickGear={onClickGear}
      />
    )
    return result
  }, [])

  if (canDrop && isOver) { cellClasses.push('dnd-target-is-ready-to-accept') }

  return (
    <div ref={drop} className={classNames(cellClasses)}>
      <div className="cell-item-header">
        <i className="fa fa-cog" onClick={() => onClickGear(ItemTypes.CELL.type, path)}></i>
        <span className="flag">{ItemTypes.CELL.name(index)}</span>
        {deleteAllowed && <i className="fa fa-trash" onClick={() => deleteCellItem(path)}></i>}
      </div>
      {elements}
    </div>
  )
}


export default connect(CellItem)
