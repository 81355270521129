import React from 'react'
import store from 'store'
import { Provider } from 'react-redux'
import AmountCellItem from './amount_cell_item'

export default class AmountCellItemApp extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <AmountCellItem {...this.props}/>
      </Provider>
    )
  }
}
