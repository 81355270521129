import React from 'react'

export default ({ element, mode }) => {
  const { caption } = element
  
  if (mode.isReadOnly()) {
    return null
  }

  return (
    <React.Fragment>
      <input type="submit" className="btn btn-primary" value={caption} />
    </React.Fragment>
  )
}
