import { Component } from 'react'

let basket = null

export class Basket extends Component {

  constructor (props) {
    super(props)
    basket = this
    this.state = {
      count: props.count,
      containerClass: this.containerClassName(props.count)
    }
  }

  handleCountUpdate = count => this.setState({ count, containerClass: this.containerClassName(count) })

  containerClassName = count => count === 0 ? 'hidden' : 'basket-extra-amounts'

  handleCancelClick = e =>
    $.ajax({
      url: this.props.cancelUrl,
      type: 'delete',
      dataType: 'json',
      success: resp => window.location = resp.location,
    })

  handlePreviewClick = (e) => window.location = this.props.indexUrl

  render () {
    return (
      <div className={this.state.containerClass}>
        <h3>{`${this.props.title}: ${this.state.count}`}</h3>
        <button onClick={this.handlePreviewClick}>{this.props.previewCaption}</button>
        <button className="cancel" onClick={this.handleCancelClick}>{this.props.cancelCaption}</button>
      </div>
    )
  }
}

export class Control extends Component {
  constructor (props) {
    super(props)
    this.state = {
      value: props.value,
      btnClassName: props.value === 0 ? 'hidden' : 'reset-control',
      method: props.method,
      url: props.url,
    }
  }
  handleValueChange = (e) => {
    const value = e.target.value
    if (value === '') { return this.setState({ value }) }
    this.sendData(value)
  }

  handleReset = e => this.sendData(0)

  sendData = (value) => {
    $.ajax({
      url: this.state.url,
      type: this.state.method,
      data: { value, element_id: this.props.element_id },
      dataType: 'json',
      success: (resp) => {
        let newStateValues = {}
        newStateValues.value        = parseInt(resp.value)
        newStateValues.btnClassName = value === 0 ? 'hidden' : 'reset-control'
        if (resp.url) { newStateValues.url = resp.url }
        if (resp.method) { newStateValues.method = resp.method }
        this.setState(newStateValues)
        // It is ugly hack.
        // We might replace with store, but I'm sorry, dont have time to replace within current task
        basket.handleCountUpdate(resp.count)
      }
    })
  }

  render () {
    return (
      <div className="element-ea">
        <input type="text" onChange={this.handleValueChange} value={this.state.value} />
        <button className={this.state.btnClassName} onClick={this.handleReset} />
      </div>
    )
  }
}
