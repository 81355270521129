import React, { useEffect } from 'react'
import { initUploader } from 'actions/uploader_actions'
import store from 'store'
import { Provider } from 'react-redux'

import ProjectUploads from './project_uploads'
import SharedUploads  from './shared_uploads'

const MspSharedUploadsApp = ({ projectUploader, mspProjectUploader }) => {

  store.dispatch(initUploader(projectUploader.buttonId, projectUploader))
  store.dispatch(initUploader(mspProjectUploader.buttonId, mspProjectUploader))

  return (
    <Provider store={store}>
      <div className="row">
        <div className="col-12 project-uploads mb-4">
          <ProjectUploads {...projectUploader} targetKey={mspProjectUploader.buttonId} />
        </div>
        <div  className="col-12 shared-uploads">
          <SharedUploads {...mspProjectUploader} targetKey={projectUploader.buttonId} />
        </div>
      </div>
    </Provider>
  )
}

export default MspSharedUploadsApp
