const { $ } = window as any

export default class LoadingIndicator {
  table: any

  filterInputs: any

  sortingCells: any

  pager: any

  constructor(table, filterInputs1) {
    this.table = table
    this.filterInputs = filterInputs1
    this.sortingCells = this.table.find('thead.filters tr[role=row] th.sorting')
    this.pager = this.table.parents('.dataTables_wrapper').find('.datatables-pager')
  }

  show(index) {
    $(this.filterInputs.get(index)).parent().find('.loading').show()
    this._disable(this.sortingCells)
    this._disable(this.pager)
  }

  hide() {
    this.table.find('tr.filters th .loading').hide()
    this._enable(this.sortingCells)
    this._enable(this.pager)
  }

  _disable(elements) {
    elements.css('pointer-events', 'none')
  }

  _enable(elements) {
    elements.css('pointer-events', 'auto')
  }
}
