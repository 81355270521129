import React from 'react'
import ProjectGroupsGraphs from './project_group_list'
import MspWorkflowsGraphs from './msp_workflows_graphs'
import connect from './graphs_connect'
import classNames from 'classnames'

const I18n = window.I18n

class Graphs extends React.Component {

  state = { group: null, workflow: null }

  assignGroup = (group = null) => this.setState(prevState => Object.assign({}, prevState, { group }))

  assignMspWorkflow = (workflow = null) => this.setState(prevState => Object.assign({ }, prevState, { workflow }) )

  closeModal = () => this.setState({ group: null, workflow: null })

  render () {
    const { containerClassName, mspWorkflows, projectGroups, ModalWithProjects, projects, getProjects } = this.props

    if (mspWorkflows.size == 0 && projectGroups.size == 0) {
      return (
        <div className="row justify-content-center">
          <div className="col-5 pt-3 pb-3">{I18n.t('overview.components.empty_message')}</div>
        </div>
      )
    }

    const { group, workflow } = this.state

    return (
      <div className={classNames(containerClassName, 'row')}>
        <ModalWithProjects name={group?.name || workflow?.name} isOpen={group?.id || workflow?.id} closeModal={this.closeModal} projects={projects} />
        <ProjectGroupsGraphs projectGroups={projectGroups} getProjects={getProjects} assignGroup={this.assignGroup} />
        <MspWorkflowsGraphs  mspWorkflows={mspWorkflows} getProjects={getProjects} assignMspWorkflow={this.assignMspWorkflow} />
      </div>
    )
  }
}

export default connect(Graphs)
