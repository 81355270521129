import React, { useState } from 'react'
import SmModalWindow from 'components/shared/modal_window'
import Spinner from 'components/shared/spinner'
import ResourceUrl from './resource_url'
import { format } from 'date-fns'

const { I18n } = window

export default function SharingLinkModal({
  dialogOpened,
  windowTitle,
  resourceUrlTitle,
  toggleDialog,
  isFetching,
  currentLink,
  expiresAtFieldTitle,
  expiresAtFieldHint,
  usernameFieldTitle,
  saveSharingLink,
  deleteSharingLink,
  successMessage,
  errorMessage,
  createSharingLinkTitle,
  updateSharingLinkTitle,
}) {
  const initialExpiresAt = format(currentLink.expiresAt, 'yyyy-MM-dd')
  const saveSharingLinkUrl = currentLink.saveUrl
  const [expiresAt, setExpiresAt] = useState(initialExpiresAt)
  const [username, setUsername] = useState(currentLink.user.name)

  const saveButtonTitle = currentLink.id ? updateSharingLinkTitle : createSharingLinkTitle

  const modalContent = (
    <div className="">
      {successMessage && (
        <div className="alert alert-success" role="alert">
          {successMessage}
        </div>
      )}
      {errorMessage && (
        <div className="alert alert-danger" role="alert">
          {errorMessage}
        </div>
      )}
      <div className="form-group">
        <label className="label" htmlFor="sharing-link-user-name">
          {usernameFieldTitle}
        </label>
        <input
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          type="text"
          className="form-control"
          id="sharing-link-user-name"
        />
      </div>
      <div className="form-group">
        <label className="label" htmlFor="sharing-link-expires-at">
          {expiresAtFieldTitle}
          <small className="d-block">{expiresAtFieldHint}</small>
        </label>
        <input
          value={expiresAt}
          onChange={(e) => setExpiresAt(e.target.value)}
          type="date"
          className="form-control"
          id="sharing-link-expires-at"
        />
      </div>
      <ResourceUrl currentLink={currentLink} resourceUrlTitle={resourceUrlTitle} />
    </div>
  )
  const sharingLinksData = {
    sharing_link: {
      expires_at: expiresAt,
      user_attributes: {
        name: username,
        id: currentLink.user.id,
      },
    },
  }
  const modalButtons = (
    <div className="modal-buttons">
      <button className="btn btn-secondary mr-2" type="button" onClick={() => toggleDialog(false, currentLink)}>
        {I18n.t('application.actions.cancel')}
      </button>
      {currentLink.id && (
        <button className="btn btn-danger mr-2" type="button" onClick={() => deleteSharingLink(currentLink.id)}>
          {isFetching ? (
            <Spinner className="ml-3 mr-3" viewType="inline" size="1" />
          ) : (
            I18n.t('application.actions.delete')
          )}
        </button>
      )}
      <button
        className="btn btn-primary"
        type="button"
        onClick={() => saveSharingLink(saveSharingLinkUrl, sharingLinksData)}
      >
        {isFetching ? <Spinner className="ml-3 mr-3" viewType="inline" size="1" /> : saveButtonTitle}
      </button>
    </div>
  )

  return (
    <SmModalWindow
      title={windowTitle}
      visible={dialogOpened}
      onClose={(e) => {
        toggleDialog(false, currentLink)
      }}
      content={modalContent}
      buttons={modalButtons}
      windowID="resource_sharing_window"
    />
  )
}
