import { useState } from 'react'
import Spinner from 'components/shared/spinner'
import ajax from 'axios'
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Form, FormGroup, Label, Input, FormText
} from 'reactstrap'

const { I18n } = window

const AssignHandoverFormButton = ({ projectId, handoverForms, formId }) => {

  const [isOpen, setOpenFlag] = useState(false)
  const [selectedFormId, setFormId] = useState(formId)
  const [isReqInProgress, setProcessingStateOfReq] = useState(false)

  const changeFormId = (e) => setFormId(e.target.value)

  const assignHandoverForm = () => {
    setProcessingStateOfReq(true)
    ajax({
      url: `/project_tickets/${projectId}/assign-handover-form.json`,
      method: 'patch',
      data: { form_id: selectedFormId }
    }).then(resp => {
      setOpenFlag(false)
      setProcessingStateOfReq(false)
      window.location.href = resp.data.redirect_to
    })
  }

  const toggle = () => setOpenFlag(prevVal => !prevVal)

  return (
    <div className="assign-handover-form">
      <Button color="secondary" onClick={toggle}>
        {I18n.t('handover_forms.subject_actions.assign_form')}
      </Button>
      <Modal isOpen={isOpen} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>
          {I18n.t('project_tickets.titles.assign_handover_form_modal_window')}
          {isReqInProgress && <Spinner viewType="inline" size="1" />}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="handover_form_id">
                {I18n.t('activerecord.models.handover_forms/form.one')}
              </Label>
              <div className="row">
                <div className="col-8">
                  <Input type="select" id="handover_form_id" name="workflow" onChange={changeFormId} value={selectedFormId}>
                    <option value=""></option>
                    { handoverForms.map(form => (
                        <option key={`handover-form-option-${form.id}`} value={form.id}>
                          {form.name}
                        </option>
                      ))
                    }
                  </Input>
                </div>
                <div className="col-4">
                  <Button color="primay" className="btn btn-primary" onClick={assignHandoverForm} disabled={isReqInProgress}>
                    {I18n.t('application.actions.apply')}
                  </Button>
                </div>
              </div>
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  )
}

AssignHandoverFormButton.defaultProps = {
  handoverForms: [],
  formId: ''
}

export default AssignHandoverFormButton
