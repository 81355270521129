import React from 'react'
import store from 'store'
import { Provider } from 'react-redux'
import { initUploader } from 'actions/uploader_actions'
import Photos from './photos'

export default function App (props) {
  store.dispatch(initUploader(props.uploaderOptions.buttonId, props.uploaderOptions))
  return (
    <Provider store={store}>
      <Photos {...props} />
    </Provider>
  )
}

