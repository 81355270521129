import { connect } from 'react-redux'
import { togglePhoto, resetLinks, saveLinks } from 'actions/project_actions'

function mapStateToProps (state) {
  return {
    projectId: state.getIn(['project', 'id']),
    links: state.getIn(['project', 'links']),
    photos: state.getIn(['project', 'photos']),
    reservedLinks: state.getIn(['project', 'transient', 'reservedLinks']),
    activeElement: state.getIn(['element', 'transient', 'activeElement']),
  }
}

function mapDispatchToProps (dispatch) {
  return {
    togglePhoto: (photoId, selected) => dispatch(togglePhoto(photoId, selected)),
    resetLinks: () => dispatch(resetLinks()),
    saveLinks: (projectId, elementId, photoIds) => dispatch(saveLinks(projectId, elementId, photoIds)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
