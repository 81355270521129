import ItemTypes from '../lib/item_types'
import CssCollector from './css_collector'
import RowSettings from './row_settings'
import CellSettings from './cell_settings'
import ElementSettings from './element_settings'

const determinateItemSettingsComponent = (type) => {
  switch(type) {
    case ItemTypes.ROW.type:
      return RowSettings
    case ItemTypes.CELL.type:
      return CellSettings
    case ItemTypes.ELEMENT.type:
      return ElementSettings
  }
}

export default ({ type,  item, path,  handlers }) => {
  const ItemSettings = determinateItemSettingsComponent(type)

  if (!item) { return null }

  const [index] = path.slice(-1)

  const header = `Settings ${ItemSettings.type.name(path.join(' | '))}`

  return (
    <div className="card settings-bar">
      <div className="card-header">
        {header}
      </div>
      <div className="card-body">
        <div className="row align-items-center">
          <div className="col-1 mx-auto">
            <i className="fa fa-chevron-right" onClick={handlers.onClose}></i>
          </div>
          <div className="col-11">
            <form className="default-form default-form-left-labeled">
              { ItemSettings && item &&
                <ItemSettings item={item} path={path} handlers={handlers} CssCollector={CssCollector} />
              }
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
