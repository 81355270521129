import LocationsSelectorApp from './locations_selector_app'
import ElementsSelectorApp from './elements_selector_app'
import CreateProjectsBtn from './create_projects_btn'
import UpdateTableBtn from './update_table_btn'
import NextBtn from './next_btn'
import WaitProcessing from './wait_processing'
import Summary from './summary'

export default {
  LocationsSelectorApp,
  ElementsSelectorApp,
  Summary,
  CreateProjectsBtn,
  UpdateTableBtn,
  NextBtn,
  WaitProcessing
}
