import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Select from 'components/shared/base_react_select'

const { I18n } = window

const RecipientList = ({ recipients, onChangeClick }) => (
  <>
    {`${I18n.t('comments.will_be_emailed_to')}:`}
    <br />
    <i>
      {recipients.map(r => r.label).join(', ')}
    </i>
    { recipients.map(r => (
      <input
        type="hidden"
        name="current_commment_recipients[]"
        value={r.value}
        key={r.value}
      />
    )) }
    <button type="button" className="ml-2 btn btn-secondary btn-sm" onClick={onChangeClick}>
      {I18n.t('comments.change')}
    </button>
  </>
)

const RecipientSelect = ({ selectedRecipients, availableRecipients }) => (
  <>
    { `${I18n.t('comments.email_to_people')}:` }
    <Select
      name="current_commment_recipients[]"
      defaultValue={selectedRecipients}
      options={[...availableRecipients, ...selectedRecipients]}
      isMulti
    />
  </>
)

const CommentRecipients = ({ recipients, users }) => {
  const [editing, setEditing] = useState(false)

  return (
    <div className="comment-recipients recipients">
      { !editing && <RecipientList recipients={recipients} onChangeClick={() => setEditing(true)} /> }
      { editing && <RecipientSelect selectedRecipients={recipients} availableRecipients={users} /> }
    </div>
  )
}

CommentRecipients.propTypes = {
  recipients: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
}
export default CommentRecipients
