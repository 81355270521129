const destroyBeforeCaching = () => {
  document.addEventListener("turbolinks:before-cache", () => {
    let dataTable =  $($.fn.dataTable.tables(true)).DataTable()
    if (dataTable != null) {
      dataTable.destroy()
      dataTable = null
    }
  })
}

const initSearch = (table, $search, colIndex = null) => {
  $search.on('keyup change', function () {
    if (colIndex) {
      table.api().columns(colIndex).search(this.value).draw()
    }
    else {
      table.api().search(this.value).draw()
    }
  })
}

export default {
  destroyBeforeCaching,
  initSearch
}
