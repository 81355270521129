import React, { useState } from 'react'
import store from 'store'
import { Provider } from 'react-redux'
import { Button } from 'reactstrap'
import ModalWindow from './modal_window'

function DuplicateButtonApp({ buttonTitle, windowTitle, projectId, projectGroupId, projectGroups }) {
  const [modalFormIsActive, setModalFormIsActive] = useState(false)
  const toggleModal = () => setModalFormIsActive(!modalFormIsActive)

  return (
    <Provider store={store}>
      <div>
        <Button className="btn btn-secondary" onClick={toggleModal}>
          {buttonTitle}
        </Button>
        <ModalWindow
          isOpen={modalFormIsActive}
          toggleModal={toggleModal}
          projectId={projectId}
          projectGroupId={projectGroupId}
          projectGroups={projectGroups}
          windowTitle={windowTitle}
        />
      </div>
    </Provider>
  )
}

export default DuplicateButtonApp
