import { createSelector } from 'reselect'
import { snakeCase, nestedKeysAsSnakeCase } from 'libs/helpers'

export const prepareDataForSend = (data, keyIndex = 0) => {
  const KEYS = ['cells', 'elements']
  const key = KEYS[keyIndex]

  return data.reduce((itemsResult, item, index) => {
    const preparedItem = { }
    if (item.id) { preparedItem.id = item.id }
    if (item._destroy) { preparedItem._destroy = item._destroy }
    preparedItem.position = index
    
    const additionalItemAttrs = Object.keys(item).reduce((itemOptions, optionKey) => {
      if (!['id', 'position', '_destroy', 'options', key].includes(optionKey)) {
        itemOptions[snakeCase(optionKey)] = item[optionKey]
      }
      return itemOptions
    }, {}) || {}

    preparedItem.options = nestedKeysAsSnakeCase(
      Object.assign({}, item.options || {}, additionalItemAttrs)
    )

    if (key && item[key]) {
      preparedItem[`${key}_attributes`] = prepareDataForSend(item[key], keyIndex + 1)
    }
    itemsResult.push(preparedItem)
    return itemsResult
  }, [])
}

const getRows = data => data.rows
const getPath = data => data.path
const isCorrectIndex = (idx) => idx != undefined && idx != null && (parseInt(idx) || idx == 0)

export const itemByPath = createSelector(
  [getRows, getPath],
  (rows, path) => {
    const [rowIndex, cellIndex, elementPosition] = path || []
    if (!rows) { return null }
    if (isCorrectIndex(rowIndex) && !isCorrectIndex(cellIndex)) { return rows[rowIndex] }
    if (isCorrectIndex(rowIndex) && isCorrectIndex(cellIndex) && !isCorrectIndex(elementPosition)) {
      return rows[rowIndex].cells[cellIndex]
    }
    if (isCorrectIndex(rowIndex) && isCorrectIndex(cellIndex) && isCorrectIndex(elementPosition)) {
      return rows[rowIndex].cells[cellIndex].elements[elementPosition]
    }

    return null
  }
)
