import React from 'react'
import store from 'store'
import { Provider } from 'react-redux'
import RentList from './rent_list'

export default function RentListApp (props) {
  return (
    <Provider store={store}>
      <RentList {...props} />
    </Provider>
  )
}
