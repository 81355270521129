import React from 'react'
import PropTypes from 'prop-types'

function StockLevelWarning ({ elements }) {
  const { I18n } = window
  if (elements.size === 0) {
    return null
  }
  const stockLevelBelowZero = elements.some(el => el.getIn(['template', 'stock_level']) < 0)
  if (stockLevelBelowZero) {
    return (
      <div className="stock-level-warning alert alert-warning">
        {I18n.t('elements.order_form.stock_level_warning')}
      </div>
    )
  }
  return null
}

StockLevelWarning.propTypes = {
  elements: PropTypes.object.isRequired,
}

export default StockLevelWarning
