import React from 'react'

export default ({ element, path, mode }) => {
  const { label, placeholder, id, fieldAnswer } = element
  const inputId = `text-input-${id || path.slice(-1)}`

  return (
    <div className="form-group">
      { label && <label htmlFor={inputId} className="label">{label}</label> }
      <div className="default-form-input-wrapper">
        <input type="text" id={inputId} className="form-control"
          name="fields_answers_attributes[][value]"
          readOnly={mode.isReadOnly()}
          placeholder={placeholder}
          defaultValue={fieldAnswer}
        />
        <input type="hidden" name="fields_answers_attributes[][field_id]" defaultValue={id} />
      </div>
    </div>
  )
}
