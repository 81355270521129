declare const $: any

class AdjustAmountTable {
  private amountInputs: any

  private timerID: ReturnType<typeof setTimeout> | null = null

  constructor() {
    this.amountInputs = $('.element_amount')
    this.initToggleZeroAmountButtons()
    this.initResetButtons()
    this.initAmountInputs()
  }

  private initToggleZeroAmountButtons() {
    $('.zero-amounts-toggle').each((_index, el) => {
      const $el = $(el)
      const labelShow = $el.data('showLabel')
      const labelHide = $el.data('hideLabel')

      const $container = $el.closest('.card')
      const $table = $container.find('table.table')

      this.toggleZeroAmounts($table, $el.data('showZeroAmounts'))

      $el.on('click', () => {
        const showZeroAmounts = $el.data('showZeroAmounts')
        this.toggleZeroAmounts($table, !showZeroAmounts)
        $el.data('showZeroAmounts', !showZeroAmounts)
        $el.text(showZeroAmounts ? labelShow : labelHide)
      })
    })
  }

  private toggleZeroAmounts(table: any, showZeroAmounts: boolean) {
    const $rows = table.find('tbody tr')
    $rows.each((_index, row) => {
      const $row = $(row)
      if (showZeroAmounts || parseInt($row.find('.element_amount').val(), 10) !== 0) {
        $row.removeClass('d-none')
      } else {
        $row.addClass('d-none')
      }
    })
  }

  private initResetButtons() {
    this.amountInputs.each((_index, element) => {
      this.updateResetAmountButton(element)
    })

    $('.reset_amount').click((e) => {
      const $el = $(e.currentTarget).parent().find('.element_amount')
      $el.val($el.data('defaultAmount')).trigger('change')
    })
  }

  private initAmountInputs() {
    // const that = this
    this.amountInputs.on('change keyup', (e) => {
      let val: string | undefined

      if (e.type === 'keyup') {
        const { keyCode } = e as KeyboardEvent
        if (keyCode === 13) {
          const nextInput = this.amountInputs.eq(this.amountInputs.index(e.currentTarget) + 1)
          if (nextInput.length) {
            nextInput.focus(function () {
              $(this).select()
            })
            nextInput.focus()
            return
          }
        }

        if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105) || keyCode === 8) {
          val = $(e.currentTarget).val() as string
        }
      } else {
        val = $(e.currentTarget).val() as string
      }

      if (val) {
        const $status = $(e.currentTarget).parent().find('.status')
        const options = {
          url: $(e.currentTarget).data('url'),
          method: 'post',
          data: {
            amount: val,
            _method: 'PUT',
          },
          error: () => {
            alert('Error occurred during updating amount!')
          },
          success: (resp: any) => {
            if (resp.errors.length > 0) {
              const $alert = $('#validation_alert')
              $('.modal-body', $alert).html(resp.errors.join('<br>'))
              $alert.modal()
              $(e.currentTarget).val(resp.amount)
            }
            $status.removeClass('loading')
            this.updateResetAmountButton(e.currentTarget)
          },
        }

        $status.addClass('loading')

        const ajaxReq = () => $.ajax(options)
        if (this.timerID) {
          clearTimeout(this.timerID)
        }
        this.timerID = setTimeout(ajaxReq, 1000)
      }
    })
  }

  private updateResetAmountButton(el: HTMLElement) {
    const $el = $(el)
    const amount = parseInt($el.val(), 10)
    const defaultAmount = parseInt($el.data('defaultAmount'), 10)
    const button = $el.parent().find('button.reset_amount')

    if (!Number.isNaN(defaultAmount) && defaultAmount !== amount) {
      button.removeClass('hidden')
    } else {
      button.addClass('hidden')
    }
  }
}

export default () => {
  new AdjustAmountTable()
}
