import store from 'stores/forms_data_store'
import DataTableSpinner from 'libs/datatables/spinner'
import RequestPool from 'libs/datatables/request_pool'

export default function () {
  const reqPool = new RequestPool()
  const spinner = new DataTableSpinner()

  const $headerTitle = $('h1.content-header-title')
  const $table = $('#custom_report')
  const initOptions = $table.data('ops')

  function fetchData (dtData, callback, settings) {
    let order = dtData.order[0]
    let data = {
      draw: dtData.draw,
      order: { col: dtData.columns[order.column].data, dir: order.dir },
      offset: dtData.start,
      filters: store.forms['by-type'].formRef.filtersData()
    }
    reqPool.abortAll()
    let request = $.ajax({
      url: $table.data('url'),
      dataType: 'json',
      data: data,
      method: 'get',
      beforeSend: function(jqXhr, settings) {
        if (!spinner.presentIn($headerTitle)) $headerTitle.append(spinner.html())
      },
      complete: function (request, status) {
        $(spinner.selectors(), $headerTitle).remove()
        reqPool.remove(request)
      },
      success: function (response) {
        callback(response)
      }
    })
    reqPool.add(request)
  }
  const options = {
    info: false,
    searching: false,
    lengthChange: false,
    data: $table.data('records'),
    columns: $table.data('columns'),
    serverSide: true,
    processing: true,
    ajax: fetchData,
    pageLength: parseInt(initOptions.pageLength),
    deferLoading: parseInt(initOptions.total),
    paging: true,
    fnDrawCallback: function(settings) {
      let api = new $.fn.dataTable.Api(settings)
      let pagination = $(this).closest('.dataTables_wrapper').find('.dataTables_paginate')
      pagination.toggle(api.page.info().pages > 1)
    }
  }
  let table = $table.DataTable(options)

  let listener = store.addListener('FilterClicked', function () {
    table.ajax.reload()
  })
}
