import React from 'react'
import { HorizontalBar } from 'react-chartjs-2'
import config from '../../config'

const tooltipCustomTitle = (workflow, tooltipItems, data) => {
  const item = tooltipItems[0]
  return `[${item.yLabel}] ${workflow.steps_names[item.index]}`;
}

const options =  {
  responsive: true,
  tooltips: {
    enabled: true
  },
  hover :{
    animationDuration:0
  },
  scales: {
    xAxes: [{
      ticks: {
        beginAtZero:true,
      },
      scaleLabel:{
        display:false
      },
      gridLines: {
      },
      stacked: true
    }],
    yAxes: [{
      gridLines: {
        display:false,
        color: "#fff",
        zeroLineColor: "#fff",
        zeroLineWidth: 0
      },
      stacked: true
    }]
  },
  legend:{
    position: 'bottom',
  }
}


export default class StackedBarGraph extends React.Component {

  graphData = (workflow) => {
    return {
      labels: workflow.labels,
      datasets: [{
        label: config.statuses[0].title(),
        backgroundColor: config.statuses[0].color,
        data: workflow.not_yet_started_count
      },
      {
        label: config.statuses[1].title(),
        backgroundColor: config.statuses[1].color,
        data: workflow.ongoing_count
      },
      {
        label: config.statuses[2].title(),
        backgroundColor: config.statuses[2].color,
        data: workflow.action_required_count
      },
      {
        label: config.statuses[3].title(),
        backgroundColor: config.statuses[3].color,
        data: workflow.completed_count
      },
    ]}
  }

  getElementAtEvent = ([element, _]) => {
    const status = element._chart.options.meta.statusKeys[element._datasetIndex]
    const stepId = this.props.workflow.steps_ids[element._index]
    this.props.getProjects({ multi_step_workflow_step_id: stepId, status })
    this.props.assignMspWorkflow(Object.assign({}, this.props.workflow, {workflow_step_id: stepId }))
  }

  extendedOptions = (workflow) => {
    return Object.assign({}, options, {
      tooltips: {
        callbacks: {
          title: (tooltipItems, data) => tooltipCustomTitle(workflow, tooltipItems, data)
        }
      }, meta: { statusKeys: config.statusKeys }
    })
  }

  render () {
    const { workflow } = this.props

    return (
      <div className="col-4">
        <div className="overview-graph-container">
          <div className="overview-graph-container-titleContainer">
            <a href="javascript:void(0)">{workflow.name}</a>
          </div>
          <div className="overview-graph-container-bodyContainer">
            <div className="overview-graph-container-companyLogoContainer">
              <img src={workflow.company_logo} alt={workflow.company_name} />
            </div>
            <div className="overview-graph-container-chart-container">
              <HorizontalBar data={this.graphData(workflow)} options={this.extendedOptions(workflow)} getElementAtEvent={this.getElementAtEvent} />
            </div>
          </div>
        </div>
      </div>
    )
  }

}
