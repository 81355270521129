import React from 'react'
import RowItem from './row_item'

const I18n = window.I18n

export default class TableWithExtraAmountInputs extends React.Component {

  render () {
    return (
      <table className="table table-bordered table-hover table-striped">
        <thead>
          <tr>
            <th>{I18n.t('activerecord.models.elements/base_element.one')}</th>
            <th>{I18n.t('campaigns.others.thumbnail')}</th>
            <th>{I18n.t('campaign_postorders.table_heads.location_element_amount')}</th>
            <th>{I18n.t('campaign_postorders.table_heads.ordered_amount')}</th>
            <th>{I18n.t('campaign_postorders.table_heads.order_extra')}</th>
          </tr>
        </thead>
        <tbody>
          { this.props.items.map( (item) =>
            <RowItem
              campaignElementId={item.get('campaignElementId')}
              key={`extra-amount-${item.get('campaignElementId')}`}
              item={item}
              updateItem={this.props.updateItem}
            />
          )}
        </tbody>
      </table>
    )
  }
}
