import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import classNames from 'classnames'

import ItemTypes from '../lib/item_types'
import ElementItem from './element_item'

export default (props) => {

  const ref = useRef(null)

  const index = props.path[props.path.length - 1]

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ItemTypes.ELEMENT.type,
    drop: (item) => { props.moveElementItem(item.path, item.toPath) },
    canDrop: (item) => item.path && item.path.length == 3 && item.toPath && item.toPath.length == 3,
    hover: (item, monitor) => {
      if (!ref.current) { return; }
      if (props.path.length == item.path.length && props.path.every(i => item.path.includes(i))) {
        return;
      }
      item.toPath = props.path
    },
    collect: monitor => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  })

  const [{isDragging}, drag] = useDrag({
    item: { id: props.element.id, type: ItemTypes.ELEMENT.type, path: props.path, index: index },
		collect: monitor => ({
			isDragging: !!monitor.isDragging(),
		}),
  })

  const dragElementStyles = {}

  const elementClasses = ['draggable-element']
  if (isOver && canDrop) { elementClasses.push('dnd-target-is-ready-to-accept') }

  drag(drop(ref))
  return (
    <div ref={ref} className={classNames(elementClasses)} >
      <ElementItem {...props} />
    </div>
  )
}
