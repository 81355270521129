import CustomDatatableWithSearch from 'libs/datatables/custom_datatable_with_search'

export default function () {
  $(function() {
    $('#expenses-table tbody tr').on('click', (e) => {
      e.stopPropagation()
      const href = $(e.target).parents('tr').find('a').attr('href')
      window.location.href = href
    })

    const options = {disableCompactMode: true}
    new CustomDatatableWithSearch($('#expenses-table'), $('#expenses .search'), options)
  })
}
