import { connect } from 'react-redux'

function mapStateToProps (state) {
  return {
    statusChanges: state.getIn(['overview', 'statusChanges']),
  }
}

function mapDispatchToProps (dispatch) {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
