import React from 'react'

const LnkSplitter = () => (<React.Fragment>&nbsp;|&nbsp;</React.Fragment>)

export const ToSplitWrappedItems = (props) => {
  const children = props.children.filter((item) => item != null && item.props.visible)

  const Items = children.reduce((result, item, index) => {
    result.push(item)
    if (children[index + 1])  { result.push(<LnkSplitter key={`${item.key}-splitter`} />) }
    return result
  }, [])

  return Items
}

export default LnkSplitter
