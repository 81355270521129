import { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export default class Photo extends Component {
  static propTypes = {
    photo: PropTypes.object,
  }

  onClick = (e) => {
    const { onClick, selected, photo } = this.props

    onClick(photo.get('id'), !selected)
  }

  render () {
    const { photo, selected } = this.props
    return (
      <div
        onClick={this.onClick}
        className="project-attach-photo-container"
        style={{ backgroundImage: `url(${photo.get('large_url')})` }}
      >
        <div className={classNames('project-attach-photo-indicator', 'fa', { 'fa-check': selected, 'selected': selected })} />
      </div>
    )
  }
}
