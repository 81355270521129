import React from 'react'
import RowItem from './row_item'

const I18n = window.I18n

export default class SourceBox extends React.Component {

  render () {
    const { items, selectItem, selectAll, showRegions } = this.props
    return (
      <table className="table table-bordered table-hover table-striped table-sm">
        <thead className="">
          <tr>
            <th>{I18n.t('activerecord.attributes.elements/base_element.location_number')}</th>
            <th>{I18n.t('activerecord.models.location.other')} ({items.size})</th>
            {showRegions && <th className="w-30">{I18n.t('activerecord.models.regions/region.one')}</th>}
            <th>{I18n.t('activerecord.attributes.elements/base_element.location_address')}</th>
            <th className="w-20">
              <a onClick={selectAll} className="action-control">
                {I18n.t('application.actions.select_all')}
                &nbsp;
                <i className="fa fa-angle-double-right"></i>
              </a>
            </th>
          </tr>
        </thead>
        <tbody>
          {items.map((item) => <RowItem item={item} key={`item-key-${item.get('id')}`} action={selectItem} showRegions={showRegions} />)}
        </tbody>
      </table>
    )
  }
}
