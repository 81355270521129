import React from 'react'
import store from 'store'
import { Provider } from 'react-redux'
import ElementsSelector from './elements_selector'
import { initElements } from 'actions/campaigns/elements_selector_actions'
import { selectByTags } from 'actions/campaigns/common_actions'

import { setUploaderOptions } from 'actions/shared'

import ByTagsSelector from './by_tags_selector'

export default class ElementsSelectorApp extends React.Component {

  constructor (props) {
    super(props)
    store.dispatch(initElements({
      id: this.props.id, step: this.props.step,
      isReady: this.props.is_ready,
      elements: this.props.elements,
      tags: this.props.tags,
      elementFormData: this.props.element_form_data
    }))

    if (this.props.selectedTags.length) {
      store.dispatch(selectByTags(this.props.selectedTags, 'elements', true))
    }
  }

  render () {
    return (
      <Provider store={store}>
        <ElementsSelector ByTagsSelector={ByTagsSelector} />
      </Provider>
    )
  }
}
