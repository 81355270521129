import * as React from 'react'

type HiddenFieldsProps = {
  id: number
  locationId: number
  campaignElementId: number
}
export default function HiddenFields(props: HiddenFieldsProps) {
  const { id, locationId, campaignElementId } = props
  return (
    <>
      { id && <input type="hidden" name="campaign[items_attributes][][id]" value={id} /> }
      <input type="hidden" name="campaign[items_attributes][][location_id]" value={locationId} />
      <input type="hidden" name="campaign[items_attributes][][campaign_element_id]" value={campaignElementId} />
    </>
  )
}
