import React from 'react'
import store from 'store'
import { Provider } from 'react-redux'
import { initOverviewData } from 'actions/multi_step/workflow_overview_board_actions'
import OverviewBoard from './board'

export default (props) => {
  store.dispatch(initOverviewData(props))

  return (
    <Provider store={store}>
      <OverviewBoard />
    </Provider>
  )
}
