import React from 'react'
import PropTypes from 'prop-types'
import Header from './header'
import MainArea from './main_area'
import Footer from './footer'
import connect from './connect'

const Board = ({ name, steps, stepsProjects, filtersData, selectFilterOption, emptyMultiStepProjects }) => {

  const selectedFilters = Object.keys(filtersData).reduce((result, type) => {
    result[type] = filtersData[type].filter(item => item.selected)
    return result
  }, { locations: [], statuses: [] })

  const colSize = steps.length ? Math.round(12 / steps.length) : 0

  return (
    <div className="overview-board">
      <Header
        name={name}
        filtersData={filtersData}
        selectedFilters={selectedFilters}
        handleChangeFilter={selectFilterOption}
        colSize={colSize}
      />
      <MainArea
        steps={steps}
        stepsProjects={stepsProjects}
        selectedFilters={selectedFilters}
        emptyMultiStepProjects={emptyMultiStepProjects}
        colSize={colSize}
      />
      <Footer />
    </div>
  )
}

Board.propTypes = {
  name: PropTypes.string,
  steps: PropTypes.array,
  stepsProjects: PropTypes.object,
  filtersData: PropTypes.object,
  emptyMultiStepProjects: PropTypes.array,
  selectFilterOption: PropTypes.func.isRequired
}

export default connect(Board)
