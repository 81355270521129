import { useState } from 'react'
import { Button, Form } from 'reactstrap';
import { merge } from 'lodash'
import { boxBracketKeyToNestedObjectKey } from 'libs/helpers'
import FORMS, { FormViewSelector } from './forms'

const { I18n } = window

const isValidFormData = (data, activeFormType) => {
  switch (activeFormType) {
    case 'selectGroupForm':
      return !!data.selectGroupForm.projects?.project_group_id
    case 'createGroupForm':
      const group = data.createGroupForm.group
      return !!(group.name && group.workflow_id)
  }
}

const ModalForm = (props) => {

  const [initData, updateInitData] = useState({
    selectGroupForm: { groups: props.groups },
    createGroupForm: { workflows: props.workflows }
  })
  const [activeFormType, changeFormType] = useState('selectGroupForm')
  const [formsData, changeFormsData] = useState({
    selectGroupForm: { projects: {} },
    createGroupForm: { group: { name: props.campaignName } }
  })

  const [isReadyForSubmit, setNewReadyState] = useState(isValidFormData(formsData, activeFormType))

  const switchForm = (e) => changeFormType(e.target.value)
  const updateFormData = (e) => {
    const data = boxBracketKeyToNestedObjectKey({ [e.target.name]: e.target.value })
    changeFormsData(prevData => Object.assign({}, prevData, { [activeFormType]: merge(prevData[activeFormType], data) }))
  }

  const validateFormDataResult = isValidFormData(formsData, activeFormType)
  if (validateFormDataResult != isReadyForSubmit) { setNewReadyState(validateFormDataResult) }

  const onSumbit = () => props.sendData(props.url, formsData[activeFormType])

  const FormView = FORMS[activeFormType]

  return (
    <Form>
      <FormViewSelector onChange={switchForm} activeFormType={activeFormType} />
      <FormView initData={initData[activeFormType]} data={formsData[activeFormType]} onChange={updateFormData} />
      <Button color="primary" disabled={!isReadyForSubmit} onClick={onSumbit}>{I18n.t('application.actions.create')}</Button>
    </Form>
  )
}

export default ModalForm
