import { EventEmitter } from 'fbemitter'

class FormsDataStore extends EventEmitter {

  constructor () {
    super()
    this.forms = { 'by-type': { } }
  }

  init (mainKey, data) {
    this.forms[mainKey] = $.extend(true, {}, data)
  }

  assign(mainKey, fieldKey, data) {
    this.forms[mainKey][fieldKey] = data
  }

  get (mainKey, fieldKey) {
    return this.forms[mainKey][fieldKey]
  }

  formDataFor(keypath) {
    let mainKey, fieldKey
    [mainKey, fieldKey] = keypath.split('.')
    if (mainKey && fieldKey) return this.forms[mainKey][fieldKey]
    else return formData[fieldKey]
  }

  trigger (eventName) {
    this.emit(eventName)
  }
}

export default new FormsDataStore
