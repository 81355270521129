const I18n = window.I18n

// TODO (atanych): the good idea to use Immutable.Record for models
class MyFile {
  constructor (attrs) {
    this.id = attrs.id
    this.status = attrs.status
    this.path = attrs.path
    this.name = attrs.name
    this.companyName = attrs.company_name
  }

  // TODO (atanych): in future we might create presenter level abstraction
  getIcon () {
    if (this.status === MyFile.FAILED) { return 'fa-exclamation' }
    if (this.status === MyFile.COMPLETED) { return 'fa-file-text-o' }
    if (this.status === MyFile.IN_PROGRESS) { return 'fa-spinner fa-fw fa-spin' }
  }

  getName () {
    if (this.status === MyFile.COMPLETED) { return this.name }
    return I18n.t(`my_files.index.file_name.${this.status}`)
  }

  isCompleted () {
    return this.status === MyFile.COMPLETED
  }

  isInProgress () {
    return this.status === MyFile.IN_PROGRESS
  }

  update (attrs) {
    _.each(attrs, (value, name) => { this[name] = value })
  }
}

MyFile.FAILED = 'failed'
MyFile.COMPLETED = 'completed'
MyFile.IN_PROGRESS = 'in_progress'
export default MyFile
