import { Component } from 'react'
import PropTypes from 'prop-types'
import SmModalWindow from 'components/shared/modal_window'
import Photo from './photo'

const MODAL_WINDOW_ID = 'attach-photo-to-element'
export default class AttachPhotoToElementModal extends Component {
  
  static propTypes = {
    photos: PropTypes.object.isRequired,
    links: PropTypes.object.isRequired,
    projectId: PropTypes.number,
    activeElement: PropTypes.number,
    reservedLinks: PropTypes.any,
    togglePhoto: PropTypes.func.isRequired,
    resetLinks: PropTypes.func.isRequired,
    saveLinks: PropTypes.func.isRequired,
  }

  componentDidMount () {
    const { resetLinks } = this.props

    $(`#${MODAL_WINDOW_ID}`).on('hidden.bs.modal', () => {
      if (this.props.reservedLinks) { resetLinks() }
    })
  }

  isSelectedPhoto (photoId) {
    const { links, activeElement } = this.props
    return links.find(link => link.get('attachment_id') === photoId && link.get('element_id') === activeElement)
  }

  onSubmit = () => {
    const { saveLinks, projectId, links, activeElement } = this.props
    const photoIds = links.filter(link => (
      link.get('element_id') === activeElement
    )).map(link => link.get('attachment_id'))
    saveLinks(projectId, activeElement, photoIds)
  }

  renderContent () {
    const { photos, togglePhoto } = this.props
    if (!photos.size) { return <div>{I18n.t('attach_photo_to_element_modal.no_photos')}</div> }

    return photos.map(photo => <Photo photo={photo} key={photo.get('id')} onClick={togglePhoto} selected={this.isSelectedPhoto(photo.get('id'))} />)
  }

  renderButtons () {
    return (
      <div>
        <button data-dismiss="modal" aria-label="Close" className="btn btn-light mr-2">{I18n.t('attach_photo_to_element_modal.cancel')}</button>
        <button onClick={this.onSubmit} className="btn btn-primary">{I18n.t('attach_photo_to_element_modal.submit')}</button>
      </div>
    )
  }

  render () {
    return (
      <SmModalWindow
        title={I18n.t('attach_photo_to_element_modal.title')}
        content={this.renderContent()}
        buttons={this.renderButtons()}
        windowID={MODAL_WINDOW_ID}
      />
    )
  }
}
