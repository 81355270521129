import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { List } from 'immutable'
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import connect from './connect'
import SelectedColumns from './selected_columns'
import AvailableColumns from './available_columns'

class EnabledColumns extends Component {
  static propTypes = {
    selectedColumns: PropTypes.instanceOf(List).isRequired,
    allColumns: PropTypes.array.isRequired,
    inputName: PropTypes.string.isRequired,
    values: PropTypes.array
  }

  constructor(props) {
    super(props)
    this.translations = {}
    this.props.allColumns.forEach(col => this.translations[col[1]] = col[0])
  }
  componentDidMount() {
    if (this.props.values) {
      this.props.loadSelected(this.props.values)
    }
  }

  render() {
    return (
      <DndProvider backend={HTML5Backend}>
        <div className="enabled-columns">
          <SelectedColumns
            selectedColumns={this.props.selectedColumns}
            dropToSelected={this.props.dropToSelected}
            dropToAvailable={this.props.dropToAvailable}
            translate={this.translate.bind(this)}
          />

          <AvailableColumns
            availableColumns={this.availableColumns()}
            dropToSelected={this.props.dropToSelected}
            dropToAvailable={this.props.dropToAvailable}
            translate={this.translate.bind(this)}
          />
          { this.hiddenInputs() }
        </div>
      </DndProvider>
    )
  }

  availableColumns() {
    const columns = this.props.allColumns
      .map( col => col[1] )
      .filter( col => !this.props.selectedColumns.includes(col))
    return columns
  }

  translate(name) {
    return this.translations[name]
  }

  hiddenInputs() {
    return this.props.selectedColumns.map( (column) => {
      return (
        <input name={this.props.inputName} value={column} key={column} type="hidden" />
      )
    })
  }
}

export default connect(EnabledColumns)
