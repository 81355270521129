import { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import ItemTypes from '../../lib/item_types'

const ElementListItem = (props) => {
  const { element, cellPath, index, path, handlers } = props
  const { showSettings, deleteElementItem, moveElementItem } = handlers

  const ref = useRef(null)

  const [, drop] = useDrop({
    accept: ItemTypes.ELEMENT.type,
    hover(item, monitor) {
      if (!ref.current) { return  }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) { return }
      const hoverBoundingRect = ref.current.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) { return }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) { return }

      moveElementItem(cellPath.concat(dragIndex), cellPath.concat(hoverIndex))
    },
  })
  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.ELEMENT.type, index, path },
    end: (item) => {
      // console.log('endItem: ', item)
      // moveElementItem(item.path, cellPath.concat(item.index))
    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    }),
  })
  const opacity = isDragging ? 0.6 : 1

  drag(drop(ref))

  return (
    <li key={`${element.type}-${index}`} ref={ref} className="element-item" style={{opacity: opacity}}>
      <i className="fa fa-arrows-v">
        &nbsp;{element.label || element.caption || getElementDefaultName(element.type)}
      </i>
      <div className="controls">
        <i className="fa fa-cog" onClick={() => showSettings(ItemTypes.ELEMENT.type, path)}></i>
        <i className="fa fa-trash" onClick={() => deleteElementItem(path)}></i>
      </div>
    </li>
  )
}

export default ElementListItem
