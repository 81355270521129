import React, { Component } from 'react'
import ModalWindow from './modal_window'
import ModalWithProjects from './modal_with_projects'
import StatusSummary from './status_summary'
import CommentList from './comment_list'
import StatusChangeList from './status_change_list'
import Graphs from './graphs'

export default class Overview extends Component {
  render () {
    return (
      <div className="overview-app-container">
        <Graphs containerClassName="overview-app-graphs-container" ModalWithProjects={ModalWithProjects} />
        <div className="overview-app-bottomContainer">
          <table>
            <thead>
              <tr>
                <td>{I18n.t('overview.components.recent_comments')}</td>
                <td>{I18n.t('overview.components.last_status_updates')}</td>
              </tr>
            </thead>
            <tbody>
            <tr className="overview-app-lastRow">
              <td><CommentList ModalWindow={ModalWindow} /></td>
              <td><StatusChangeList ModalWindow={ModalWindow} /></td>
            </tr>
            </tbody>
          </table>
          <StatusSummary ModalWithProjects={ModalWithProjects} />
        </div>
      </div>
    )
  }
}
