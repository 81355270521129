import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { WidgetFactoryInterface } from '../survey_answer_form'

export default function Number({ field, fieldName }: WidgetFactoryInterface) {
  const { required } = field
  const fieldId = fieldName.replace(/[\[\]]/g, '_')
  const { register } = useFormContext()

  return (
    <li className={`number input form-group numeric ${required ? 'required' : 'optional'}`}>
      <label htmlFor={fieldId} className="label">
        {field.name}
      </label>
      <div className="default-form-input-wrapper">
        <input id={fieldId} className=" form-control" step="any" type="number" {...register(fieldName)} />
      </div>
    </li>
  )
}
