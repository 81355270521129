const $ = window.$

export default function () {
  $('a#save_and_go_to_overview').on('click', (e) => {
    e.preventDefault()
    const $form = $(e.currentTarget).closest('form')
    $("<input type='hidden' name='step_action' value='save_and_go_to_overview' />").appendTo($form)
    $form.submit()
  })
}
