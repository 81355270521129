import ElementListItem from './element_list_item'

export default ({ list, handlers, path }) => {
  return (
    <ul className="cell-elements">
      { list.map((element, index) => (
          <ElementListItem key={`element-list-item-${index}`}
            element={element}
            index={index}
            cellPath={path}
            path={path.concat(index)}
            handlers={handlers}
          />
      ))}
    </ul>
  )
}
