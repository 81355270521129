export default function () {
  document.addEventListener('DOMContentLoaded', () => {
    const button = document.getElementById('confirm-order-button')
    if (button) {
      button.addEventListener('click', (e) => {
        e.preventDefault()
        document.querySelector('[id^="edit_order_request"]').submit()
      })
    }
  })
}
