import { connect } from 'react-redux'
import { loadProjects, types } from 'actions/projects/send_links_button_actions'
import { createLoadingSelector } from 'libs/requests_status_handlers'

const loadingSelector = createLoadingSelector([types.LOAD_PROJECTS])

export default connect(
  (state) => ({
    isFetching: loadingSelector(state),
    availableProjects: state.getIn(['projects', 'send_links', 'availableProjects']),
  }),
  (dispatch, ownProps) => ({
    loadProjects: (ids) => dispatch(loadProjects(ownProps.projectsUrl, ids)),
  })
)
