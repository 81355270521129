import React from 'react'

export default ({ element }) => {
  const { src, width } = element
  return (
    <React.Fragment>
      <img src={src} width={width} />
    </React.Fragment>
  )
}
