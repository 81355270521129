import OverviewLocalStore from 'local_storages/overview'

class BaseDataManager {

  get ids () { return (OverviewLocalStore.getIn(this.key) || []) }

  get itemSelector () { return `.${this.itemClass}` }

  rmFromLocalStore = (id) => {
    OverviewLocalStore.deleteIn(this.key, id)
  }

  addToLocalStore = (id) => {
    OverviewLocalStore.pushIn(this.key, id)
  }

  sync = () => {
    this.ids.forEach(id => {
      $(`.${this.itemClass} .overview-project-group-checkbox[data-id=${id}]`).prop('checked', true)
    })
  }
}

class ProjectGroupDataManager extends BaseDataManager {
  constructor () {
    super()
    this.type = 'ProjectGroup'
    this.key  = 'projectGroupIds'
    this.stateKey = 'projectGroups'
    this.itemClass = 'project-group-item'
  }
}

class MspWorkflowDataManager extends BaseDataManager {
  constructor () {
    super()
    this.type = 'MultiStep::Workflow'
    this.key  = 'mspWorkflowIds'
    this.stateKey = 'mspWorkflows'
    this.itemClass = 'msp-workflow-item'
  }
}

class PageDataManager {

  constructor () {
    this.projectGroupDataManager = new ProjectGroupDataManager()
    this.mspWorkflowDataManager  = new MspWorkflowDataManager()
    this.items = [
      this.projectGroupDataManager,
      this.mspWorkflowDataManager
    ]
  }

  sync = () => {
    this.items.forEach(dm => dm.sync())
  }

  initRequired = () => {
    return this.items.reduce((r, dm) => {
      r = r + dm.ids.length
      return r
    }, 0)
  }

  ids = () => this.items.reduce((result, dm) => {
    result[dm.key] = dm.ids
    return result
  }, {})

  getBy (isMyItem) { return this.items.find(i => isMyItem(i)) }
}

export default (new PageDataManager())
