import Select from 'components/shared/base_react_select'
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap'

export default ({ name, workflow_id, location_id, locations, workflows, onChange }) => {

  const changeSelectedLocation = (opt) =>  {
    onChange({ location_id: opt.value })
  }

  const changeSelectedWorkflow = (opt) => {
    onChange({ workflow_id: opt.value })
  }

  return (
    <Form>
      <FormGroup>
        <Label for="msp_name">Name</Label>
        <Input type="text" id="msp_name" value={name} onChange={(e) => onChange({ name: e.target.value})} />
      </FormGroup>
      <FormGroup className="location-selector">
        <Label for="msp_location">Location</Label>
        <Select
          value={location_id}
          options={locations}
          onChange={changeSelectedLocation}
        />
      </FormGroup>
      <FormGroup className="workflow-selector">
        <Label for="msp_workflow">Workflow</Label>
        <Select
          value={workflow_id}
          options={workflows}
          onChange={changeSelectedWorkflow}
        />
      </FormGroup>
    </Form>
  )
}
