import React from 'react'
import ItemTypes from '../../lib/item_types'

const I18n = window.I18n

export default class ParagraphSettings extends React.Component {

  onChange = (e) => {
    const { path, handlers, item } = this.props
    handlers.updateItemAttr(e, item, ItemTypes.ELEMENT.type, path)
  }

  updateStyles = (e) => {
    const { name, value, checked } = e.target
    const styles = Object.assign({}, this.props.item.styles || {}, { [name]: (checked ? value : 0) })
    const target = { name: 'styles', value: styles }
    this.onChange({ target })
  }

  render () {
    const { text, styles } = this.props.item
    const checkedBoldStyle   = (styles?.bold && styles.bold == 'bold' ? true : false)
    const checkedItalicStyle = (styles?.italic && styles.italic == 'italic' ? true : false)

    return (
      <div className="paragraph-settings">
        <div className="form-group">
          <label className="label">{I18n.t('application.titles.text')}</label>
          <textarea className="form-control" name="text" value={text} onChange={this. onChange} />
        </div>
        <div className="form-group">
          <div className="form-check">
            <input type="checkbox" id="bold-style-chk" name="bold" className="form-check-input"
              defaultValue="bold"
              checked={checkedBoldStyle}
              onChange={this.updateStyles}
            />
            <label htmlFor="bold-style-chk" className="form-check-label">
              {I18n.t('handover_forms.elements.paragraph.bold_style')}
            </label>
          </div>

          <div className="form-check">
            <input type="checkbox" id="italic-style-chk" name="italic" className="form-check-input"
              defaultValue="italic"
              checked={checkedItalicStyle}
              onChange={this.updateStyles}
            />
            <label htmlFor="italic-style-chk" className="form-check-label">
              {I18n.t('handover_forms.elements.paragraph.italic_style')}
            </label>
          </div>
        </div>
      </div>
    )
  }

}
