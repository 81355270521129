const KEY_PREFIX = 'overview' + (typeof UUID == 'undefined' ? '' : UUID)
const OverviewStore = {
  pushIn (key, value) {
    const prevState = JSON.parse(localStorage.getItem(`${KEY_PREFIX}/${key}`)) || []
    const newState = new Set(prevState.concat(value))
    this.update(key, [...newState])
  },
  deleteIn (key, value) {
    const prevState = JSON.parse(localStorage.getItem(`${KEY_PREFIX}/${key}`)) || []
    const newState = prevState.filter(p => p !== value)
    this.update(key, newState)
  },

  getIn (key) {
    return JSON.parse(localStorage.getItem(`${KEY_PREFIX}/${key}`))
  },

  update (key, value) {
    localStorage.setItem(`${KEY_PREFIX}/${key}`, JSON.stringify(value))
  },
}

export default OverviewStore
