import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { List } from 'immutable'
import { DropTarget } from 'react-dnd';
import Column from './column'

const I18n = window.I18n

const selectedColumnsTarget = {
  drop(props, monitor, component) {
    // do not return anything if other component already handled 'drop'
    if (monitor.didDrop()) {
      return
    }

    return {
      isSelected: true
    }
  },
}

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  }
}

class SelectedColumns extends Component {
  static propTypes = {
    selectedColumns: PropTypes.instanceOf(List).isRequired,
    translate: PropTypes.func.isRequired,
    dropToSelected: PropTypes.func.isRequired,
    dropToAvailable: PropTypes.func.isRequired,
  }

  render() {
    const { selectedColumns, connectDropTarget, isOver, translate } = this.props
    let className = 'enabled-columns-selected'
    if (isOver) {
      className += ' drop-target-active'
    }

    return connectDropTarget && connectDropTarget(
      <div className={className} >
        {this.dropZoneTitle()}
        {selectedColumns.map( (column, position) => {
          return <Column name={column} position={position} translate={translate}
                    key={column} isSelected={ () => true }
                    dropToSelected={this.props.dropToSelected}
                    dropToAvailable={this.props.dropToAvailable}
                 />
        })}
      </div>
    )
  }

  dropZoneTitle() {
    if (this.props.selectedColumns.size == 0) {
      return (
        <span className="enabled-columns-dropzone-title">
          {I18n.t('elements.groups.form.drop_zone_title')}
        </span>
      )
    }
  }
}

export default DropTarget('enabled-columns', selectedColumnsTarget, collect)(SelectedColumns)
