const I18n = window.I18n

const Variant = ({ title, val, onChange }) => {
  if (!val) { return null }

  const onClick = (v) => onChange({ target: { value: v }})

  return (
    <div className="">
      <a href="javascript:void(0)" className="amount-variant" onClick={(e) => onClick(val)}>
        {title}:&nbsp;{val}
      </a>
    </div>
  )
}

export default ({ min, max, tagsAmount, onChange }) => {
  return (
    <div className="my-auto">
      <Variant val={min} title={I18n.t('application.titles.minimum')} onChange={onChange} />
      <Variant val={max} title={I18n.t('application.titles.maximum')} onChange={onChange} />
      {tagsAmount.map((val, index) => (
          <Variant key={`tag-amount-${val}-${index}`} val={val} title={I18n.t('campaigns.others.tag_amount')} onChange={onChange} />
        )
      )}
    </div>
  )
}
