import Interfaces from 'components/shared/selector_interfaces'
// import { css } from '@emotion/react'
// import styles from './styles'

// const CustomOption = (props) => {
//   const { children, className, cx, getStyles, isDisabled, isFocused, isSelected, innerRef, innerProps, data } = props
//   console.log(css(getStyles('option', props)), getStyles('option', props))
//   const cssClasses = cx(css(getStyles('option', props)), {
//     'option': true,
//     'option--is-disabled': isDisabled,
//     'option--is-focused': isFocused,
//     'option--is-selected': isSelected,
//   }, className)
//
//   return (!isDisabled ? (
//     (
//       <div className={cssClasses} {...innerProps}>
//         <div>
//           <span className={styles['item-id']}>{data.value}&nbsp;{data.prefix}</span>
//           <span className={styles['item-name']}>{data.name}</span>
//           <span className={styles['other-item-parts']}>&nbsp;&nbsp;{data.otherLabel}</span>
//         </div>
//       </div>
//     )
//   ) : null)
// }

class ElementItemInterface extends Interfaces.ElementItemInterface {

  extractBaseItemAttrs (item) {
    return Object.assign(super.extractBaseItemAttrs(item), {
      campaignElementID: item.campaignElementID
    })
  }

  extendOptionItem (item) {
    return Object.assign(super.extendOptionItem(item), { prefix: item.prefix  })
  }

  // customOptionComponent = () => CustomOption
}

export default ElementItemInterface
