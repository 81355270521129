import Select from 'components/shared/base_react_select'

export default function ({itemInterface, values, onChange, placeholder, options}) {
  return (
    <Select
      // components={ { Option: itemInterface.customOptionComponent() }}
      value={ values }
      options={ itemInterface.extendSelectOptions(options) }
      onChange={onChange}
      placeholder={placeholder}
    />
  )
}
