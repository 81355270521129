import CustomDatatableWithSearch from 'libs/datatables/custom_datatable_with_search'
import _ from 'underscore'

export default function () {
  $(function() {
    initDatatables()
  })
}

function initDatatables() {
  const store = window.stores.button_group_store

  // filtering by company_category and country on select company page
  $.fn.dataTableExt.afnFiltering.push( function( oSettings, aData, iDataIndex ) {
    const countries = store.activeIds.countries || []
    const categories = store.activeIds.companyCategories || []

    const filteredByCountries = countries.length > 0
    const filteredByCategories = categories.length > 0
    if (!filteredByCountries && !filteredByCategories) { return true }

    const rowData = oSettings.aoData[iDataIndex]
    const { dataset } = rowData.anCells[0]

    const rowCategories = dataset.companycategories.split('|')
    const rowCountries = dataset.countries.split('|')

    const countryMatches = filteredByCountries ?
      _.any(countries, country => $.inArray(country.toString(), rowCountries) >= 0) : true

    const categoryMatches = filteredByCategories ?
      _.any(categories, category => $.inArray(category.toString(), rowCategories) >= 0) : true

    return countryMatches && categoryMatches
  })

  const options = { order: [[0, 'asc']], disableCompactMode: true, stateSave: false }
  const changeCompanyTable = new CustomDatatableWithSearch(
    $('#change-company-table'), $('#search'), options
  )
  const listener = store.addListener('change', function() {
    const api = changeCompanyTable.api()
    // trigger filter function
    return api.draw()
  })
}
