import { connect } from 'react-redux'
import { createLoadingSelector } from 'libs/requests_status_handlers'
import { createLocationAlbum, types } from 'actions/uploader_actions'

const isReqStateInProgress = createLoadingSelector([types.CREATE_ALBUM])

export default connect ((state, props) => {
  return ({
    isReqStateInProgress: isReqStateInProgress(state)
  })
}, {createLocationAlbum})
