import React from 'react'
import store from 'store'
import { Provider } from 'react-redux'
import RentListOverview from './rent_list_overview'

export default function RentListOverviewApp (props) {
  return (
    <Provider store={store}>
      <RentListOverview {...props} />
    </Provider>
  )
}
