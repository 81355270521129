import React from 'react'
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';

const I18n = window.I18n

const isValidFormData = (data) => data.mspProjectId && data.workflowStepId

class AssignForm extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      mspProjectId: this.props.current.get('mspProjectId'),
      workflowStepId: this.props.current.get('workflowStepId')
    }
  }

  onProjectSelect = (e) => {
    const mspProjectId = e.target.value
    this.setState(Object.assign(this.state, { mspProjectId: mspProjectId }))
    if (parseInt(mspProjectId)) { this.props.loadStepsForMspProject(mspProjectId) }
  }

  onStepSelect = (e) => {
    this.setState(Object.assign(this.state, { workflowStepId: e.target.value }))
  }

  onSumbit = (e) => {
    if (this.state.workflowStepId == this.props.current.get('workflowStepId')) {
      this.props.toggleModal()
    }
    else this.props.assignStep(
      this.props.current.get('projectId'),
      this.state.mspProjectId,
      this.state.workflowStepId
    )
  }

  render () {
    const { mspProjects, mspSteps, current } = this.props
    const isReadyForSubmit = isValidFormData(this.state)
    return (
      <Form>
        <FormGroup>
          <Label for="msp_project_selector">
            {I18n.t('activerecord.models.multi_step/project.one')}
          </Label>
          <Input type="select" id="msp_project_selector" onChange={this.onProjectSelect} value={this.state.mspProjectId}>
            <option value="">{I18n.t('application.actions.select')}...</option>
            { mspProjects.map((project) =>
                <option key={`mspProjectkey-${project.get('id')}`}
                  value={project.get('id')}
                >
                  {project.get('name')}
                </option>
              )
            }
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="msp_step_selector">
            {I18n.t('activerecord.models.multi_step/step.one')}
          </Label>
          <Input type="select" id="msp_step_selector" value={this.state.workflowStepId} onChange={this.onStepSelect}>
            <option value=''>{I18n.t('application.actions.select')}...</option>
            { parseInt(this.state.mspProjectId) && mspSteps.map(step =>
                <option
                  key={`key-${step.get('workflowStepId')}`}
                  value={step.get('workflowStepId')}
                >
                  {`${step.get('position')}. ${step.get('name') || ''}`}
                </option>
              )
            }
          </Input>
        </FormGroup>
        <Button color="primary" disabled={!isReadyForSubmit} onClick={this.onSumbit}>{I18n.t('application.actions.apply')}</Button>
      </Form>
    )
  }
}

export default AssignForm
