import ChangesMonitor from './helpers/changes_monitor'
import SaveAndGoToOverviewBtnHandler from './helpers/save_and_go_to_overview_btn_handler'
import PostorderPermissionsVisibilityHandler from './helpers/postorder_permissions_visibility_handler'

const $ = window.$

export default function () {
  ChangesMonitor(true)
  SaveAndGoToOverviewBtnHandler()
  PostorderPermissionsVisibilityHandler()
  $('select#campaign_surplus').change( (e) => {
    const surplusIsZero = $(e.currentTarget).val() == '0'
    $('#campaign_round_amount_total').prop('checked', !surplusIsZero).prop('disabled', surplusIsZero)
  })

}
