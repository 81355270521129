import React from 'react'
import { Map, List } from 'immutable'
import classNames from 'classnames'
import { SourceBox, TargetBox } from './boxes'

const I18n = window.I18n

export default class LocationsContent extends React.Component {

  splitLocationsOnTwoTypes = () => {
    let sourceList = List(), targetList = List()

    this.props.locations.forEach((location) => {
      if (location.get('selected')) {
        targetList = targetList.push(location)
      }
      else if (location.get('visible')) {
        sourceList = sourceList.push(location)
      }
    })

    return Map({ source: sourceList, target: targetList })
  }

  selectByTags = (tags) => {
    this.props.actions.selectByTags(tags, 'locations')
  }

  filterBy = (e) => {
    this.props.actions.filterBy(e.target.value)
  }

  filterByTags = (regions) => {
    this.props.actions.filterByTags(regions)
  }

  render () {
    const boxesRowClasses = classNames(['row', 'locations-selector-boxes-row'])
    const locations = this.splitLocationsOnTwoTypes()
    const { actions, tags, regions, ByTagsSelector } = this.props

    return (
      <div className={boxesRowClasses}>
        <div className="col-6">
          <div className="d-flex">
            <div className="col-6 mb-2">
              <input type="text" className='search form-control' id='search' placeholder={I18n.t('application.titles.filter')} onChange={this.filterBy}/>
            </div>
            <div className="col-6 tags-selector">
              <ByTagsSelector items={tags.toJS()} selectTag={this.filterByTags} />
            </div>
          </div>
          <div className={classNames(['col-12', 'locations-selector-scrollable-box-cont'])}>
            <SourceBox items={locations.get('source')} selectItem={actions.selectItem} selectAll={actions.selectAll} showRegions={regions.toJS().length > 0}  />
          </div>
        </div>
        <div className={classNames(['col-6', 'locations-selector-scrollable-box-cont'])}>
          <TargetBox items={locations.get('target')} removeItem={actions.removeItem} removeAll={actions.removeAll} />
        </div>
      </div>
    )
  }
}
