import React from 'react'
import DateTimeInput from 'components/shared/date_time_input'

export default ({ element, mode }) => {
  const { label, id, fieldAnswer } = element

  return (
    <div className="form-group">
      { label && <label className="label">{label}</label> }
      <div className="default-form-input-wrapper date-picker">
        <DateTimeInput name="fields_answers_attributes[][value]" value={fieldAnswer} clearable={!mode.isReadOnly()} readOnly={mode.isReadOnly()} />
        <input type="hidden" name="fields_answers_attributes[][field_id]" value={id} />
      </div>
    </div>
  )
}
