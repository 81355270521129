const CONFIG = {
  statuses: [
    {
      name: 'not_yet_started_count',
      scope: 'not_started',
      color: '#B13827',
      title: () => I18n.t('overview.components.statuses.not_yet_started'),
      included: ['initiated', 'unscheduled', 'on_hold'],
      secondaryColor: 'white',
    },
    {
      name: 'ongoing_count',
      scope: 'ongoing',
      color: '#F1CF29',
      title: () => I18n.t('overview.components.statuses.ongoing'),
      included: ['in_progress', 'offer_accepted', 'rescheduled', 'booked', 'overdue', 'open', 'design_approved'],
      secondaryColor: 'black',
    },
    {
      name: 'action_required_count',
      scope: 'action_required',
      color: '#4C7D75',
      title: () => I18n.t('overview.components.statuses.action_required'),
      included: ['pending_assessment', 'design_pending_approval', 'offer_pending_approval', 'approved', 'rejected'],
      secondaryColor: 'black',
    },
    {
      name: 'completed_count',
      scope: 'completed',
      color: '#b2b0b0',
      title: () => I18n.t('overview.components.statuses.completed'),
      included: ['completed'],
      secondaryColor: 'black',
    },
  ],
}

const statusKeys = CONFIG.statuses.reduce((result, status) => {
  result.push(status.name.replace('_count', ''))
  return result
}, [])

CONFIG.statusKeys = statusKeys

export default CONFIG
