import React from 'react'
import TextAligment from './text_aligment'

const LIST = {
  textAligment: TextAligment
}

const Control = (props) => {
  const TypedView = LIST[props.name]
  return <TypedView {...props} />
}

export default function (props) {
  const controls = props.controls || []

  const onChange = (e) => {
    props.onChange({ [e.target.name]: e.target.value })
  }

  return (
    <React.Fragment>
      <h3>Styles</h3>
      { controls.map(name => <Control key={`control-${name}`} name={name} value={props[name]} onChange={onChange} />) }
      <hr/>
    </React.Fragment>
  )
}
