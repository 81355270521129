import React from 'react'

export default ({ element }) => {
  const { text, styles } = element
  const textLines = (text || '').split("\n")
  const lastLineIndex = textLines.length - 1

  const paragraphStyle = {}
  if (styles?.bold)   { paragraphStyle.fontWeight  = 'bold' }
  if (styles?.italic) { paragraphStyle.fontStyle   = 'italic' }

  return (
    <p style={paragraphStyle}>
      { textLines.map((line, index) => (
          <React.Fragment key={`paragraph-line-${index}`}>
            {line}{ index != lastLineIndex && <br /> }
          </React.Fragment>
        ))
      }
    </p>
  )
}
