import { connect } from 'react-redux'
import { addElement } from '../../../../actions/elements_order'

function mapStateToProps (state) {
  return {
    currentElement: state.getIn(['elementsOrder', 'currentElement']),
    currentTemplate: state.getIn(['elementsOrder', 'currentTemplate']),
    currentTemplateData: state.getIn(['elementsOrder', 'currentTemplateData']),
    currentReplaceElementId: state.getIn(['elementsOrder', 'currentReplaceElementId']),
  }
}

function mapDispatchToProps (dispatch) {
  return {
    addElement: (element, key) => {
      dispatch(addElement(element, key))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
