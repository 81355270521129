import { Fragment } from 'react'
import { FormGroup, Label, Input } from 'reactstrap';

const I18n = window.I18n

export default ({ onChange, initData, data }) => {
  const { groups } = initData
  const projectGroupId = data['projects']['project_group_id'] || ''

  return (
    <Fragment>
      <FormGroup>
        <Label for="projects_group">{I18n.t('activerecord.models.project_group.one')}</Label>
        <Input type="select" id="projects_group" name="projects[project_group_id]" onChange={onChange} value={projectGroupId}>
          <option value=""></option>
          { groups.map(group =>
              <option key={`projects-group-${group.value}`} value={group.value}>
                {group.label}
              </option>
            )
          }
        </Input>
      </FormGroup>
    </Fragment>
  )
}
