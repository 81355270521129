import * as React from 'react'

type SelectedLocationsInfoProps = {
  selectedProjects: number[]
  availableProjects: any[]
}

const { I18n } = window as any

export default function SelectedLocationsInfo(props: SelectedLocationsInfoProps) {
  const { selectedProjects, availableProjects } = props

  const projectsCountHint = I18n.t('project_groups.send_links.projects_count_hint').replace(
    '%{amount}',
    selectedProjects.length
  )
  const selectedLocationNames = selectedProjects.map((projectId) => {
    if (!availableProjects) {
      return null
    }
    const project = availableProjects.find((p) => p.get('id') === projectId)
    return project.get('location_name')
  })
  return (
    <div>
      <div>
        <i className="fa fa-exclamation-triangle mr-1 text-warning" />
        {`${projectsCountHint}:`}
      </div>
      <ul>
        {selectedLocationNames.map((locationName) => (
          <li key={locationName}>{locationName}</li>
        ))}
      </ul>
    </div>
  )
}
