import React from 'react'
import connect from './connect'
import { LocationsContent, TagsContent } from './contents'
import SaveButton from '../common/save_button'
import SaveAndGoButton from '../common/save_and_go_button'
import NextButton from '../common/next_button'
import BackButton from '../common/back_button'
import ByTagsSelector from '../by_tags_selector'

const I18n = window.I18n

const t = {
  locationsTabTitle: I18n.t('activerecord.models.location.other'),
  tagsTabTitle: I18n.t('activerecord.models.tag.other')
}

class LocationsSelector extends React.Component {

  handleSave = (e, stepAction = null, next = null) => {
    const locationIDs = this.props.locations.reduce((result, iLocation) => {
      if (iLocation.get('selected')) { result.push(iLocation.get('id')) }
      return result
    }, [])
    const tagIDs = this.props.tags.reduce((result, iTag) => {
      if (iTag.get('selected')) { result.push(iTag.get('value')) }
      return result
    }, [])
    if (locationIDs.length || tagIDs.length) { this.props.saveSelected(this.props.id, locationIDs, tagIDs, stepAction, next) }
  }

  handleSaveAndGoToOverview = (e) => {
    this.handleSave(e, 'save_and_go_to_overview')
  }

  handleNext = (e) => {
    this.handleSave(e, null, true)
  }

  handleBack = () => {
    this.props.stepBack(this.props.id)
  }

  actions = () => {

    return {
      removeItem: this.props.removeItem,
      removeAll: this.props.removeAll,
      selectItem: this.props.selectItem,
      selectAll: this.props.selectAll,
      selectByTags: this.props.selectByTags,
      filterBy: this.props.filterBy,
      filterByTags: this.props.filterByTags
    }
  }

  render () {
    const { locations, tags, regions, isReady } = this.props

    return (
      <div className="locations-selector">
        <LocationsContent locations={locations} actions={this.actions()} tags={tags} regions={regions} ByTagsSelector={ByTagsSelector} />
        <div className="row justify-content-start">
          <div className="col-1">
            <BackButton onClick={this.handleBack} />
          </div>
          <div className="col-0">
            <SaveButton onClick={this.handleSave} />
          </div>
          {isReady && <div className="col-3">
            <SaveAndGoButton onClick={this.handleSaveAndGoToOverview} />
          </div>}
          <div className="col-1">
            <NextButton onClick={this.handleNext} />
          </div>
        </div>
      </div>
    )

  }
}

export default connect(LocationsSelector)
