import MspStepButtonApp from './msp_step_button_app'
import MspSharedUploadsApp from './msp_shared_uploads_app'
import DuplicateButtonApp from './duplicate_button_app'
import SendLinksButtonApp from './send_links_button_app'
import AssignHandoverFormButton from './assign_handover_form_button'
import CommentRecipients from './comment_recipients'
import RescheduleButtonApp from './reschedule_button_app'
import SelectProjectApp from './select_project_app'
import SelectProjectTicketApp from './select_project_ticket_app'
import FileCollaborationToolApp from './file_collaboration_tool_app'

export default {
  MspStepButtonApp,
  MspSharedUploadsApp,
  DuplicateButtonApp,
  SendLinksButtonApp,
  AssignHandoverFormButton,
  CommentRecipients,
  RescheduleButtonApp,
  SelectProjectApp,
  SelectProjectTicketApp,
  FileCollaborationToolApp,
}
