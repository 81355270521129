import * as React from 'react'
import { SelectedFiltersType } from './types'

type SelectedFiltersProps = {
  selectedFilters: SelectedFiltersType
  setSelectedFilters: (filters: SelectedFiltersType) => void
}

export default function SelectedFilters(props: SelectedFiltersProps) {
  const { selectedFilters, setSelectedFilters } = props
  return (
    <div className="d-flex flex-wrap">
      {Object.entries(selectedFilters).map(([filterName, filterValues]) =>
        filterValues.map((filterValue) => (
          <span key={filterValue.value} className="projects-report-selected-filter ml-2">
            <button
              type="button"
              className="projects-report-selected-filter-remove-button mr-2"
              onClick={() => {
                if (selectedFilters[filterName].length === 1) {
                  const { [filterName]: _, ...newSelectedFilters } = selectedFilters
                  setSelectedFilters(newSelectedFilters)
                } else {
                  setSelectedFilters({
                    ...selectedFilters,
                    [filterName]: selectedFilters[filterName].filter((f) => f.value !== filterValue.value),
                  })
                }
              }}
            >
              &times;
            </button>
            {filterValue.label}
          </span>
        ))
      )}
    </div>
  )
}
