import { Component } from 'react'
import PropTypes from 'prop-types'
import Spinner from 'components/shared/spinner'

export default class SpinnerOverlay extends Component {
  static propTypes = {
    isActive: PropTypes.bool
  }

  static defaultProps = {
    isActive: false
  }

  render () {
    if (this.props.isActive == false) {
      return this.props.children
    }
    return (
      <div className="spinner-overlay-wrapper">
        <div className="spinner-overlay-foreground">
          <Spinner />
        </div>
        {this.props.children}
      </div>
    )
  }
}
