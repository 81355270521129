import React from 'react'
// preview || readyForFill || readOnly
export class FormMode {
  constructor(initMode) {
    if (!['readOnly', 'preview', 'readyForFill'].includes(initMode)) {
      throw `${initMode} - is not supported mode!`
    }
    this._mode = initMode
  }

  get mode () { return this._mode }

  isReadOnly = () => this._mode == 'readOnly'
  isPreview  = () => this._mode == 'preview'
  isReadyForFill = () => this._mode == 'readyForFill'

}
const FormModeContext = React.createContext({ mode: new FormMode('readOnly') })
export default FormModeContext
