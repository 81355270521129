import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { WidgetFactoryInterface } from '../survey_answer_form'

export default function Select({ field, fieldName }: WidgetFactoryInterface) {
  const { choices, required } = field
  const { register } = useFormContext()
  const fieldId = fieldName.replace(/[\[\]]/g, '_')

  return (
    <li className={`select input form-group ${required ? 'required' : ''}`}>
      <label htmlFor={fieldId} className="label">
        {field.name}
        {required && <abbr title="required">*</abbr>}
      </label>
      <div className="default-form-input-wrapper">
        <select {...register(fieldName)} id={fieldId} className="form-control">
          <option value="" />
          {choices.map((choice) => (
            <option value={choice.id} key={choice.id}>
              {choice.title || choice.name}
            </option>
          ))}
        </select>
      </div>
    </li>
  )
}
