import React from 'react'

const { I18n } = window

export const RemoveLnk = ({ onClick }) => {
  return (
    <a
      className="action-control"
      href=""
      onClick={(e) => {
        e.preventDefault()
        onClick(e)
      }}
    >
      {I18n.t('application.actions.remove')}
    </a>
  )
}

export const CloneLnk = ({ onClick }) => {
  return (
    <a
      className="action-control"
      href=""
      onClick={(e) => {
        e.preventDefault()
        onClick(e)
      }}
    >
      {I18n.t('application.actions.clone')}
    </a>
  )
}
