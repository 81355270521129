import React from 'react'

import FormContent from './items'

export default class Preview extends React.Component {

  render () {
    return (
      <div className="handover-form">
        <form className="default-form default-form">
          <FormContent rows={this.props.rows} mode='preview' />
        </form>
      </div>
    )
  }
}
