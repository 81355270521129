import * as React from 'react'
import RowItem from './preview/row_item'

type PreviewPropsType = {
  elements: any[]
  removeItem: any
  updateItem: any
  makeCopy: any
  clickTagsSelectorControl: any
  imageActions: any
}

export default function Preview(props: PreviewPropsType) {
  const { elements, removeItem, updateItem, makeCopy, clickTagsSelectorControl, imageActions } = props
  const { I18n } = window as any
  return (
    <table className="table table-bordered table-hover table-striped table-sm">
      <thead>
        <tr>
          <th className="w-25">
            {I18n.t('activerecord.models.elements/base_element.one')} ({elements.length})
          </th>
          <th className="w-10">{I18n.t('activerecord.attributes.elements/base_element.min_amount')}</th>
          <th className="w-10">{I18n.t('activerecord.attributes.elements/base_element.max_amount')}</th>
          <th className="w-15">{I18n.t('activerecord.attributes.elements/base_element.dimensions')}</th>
          <th className="w-15">{I18n.t('campaigns.others.pdf')}</th>
          <th className="w-15">{I18n.t('campaigns.others.note')}</th>
          <th className="w-15">{I18n.t('activerecord.attributes.elements/common_element.tags')}</th>
          <th className="w-10">{I18n.t('application.actions.title')}</th>
        </tr>
      </thead>
      <tbody>
        {elements.map((el) => (
          <RowItem
            id={el.elementID}
            key={el.key()}
            item={el}
            updateItem={updateItem}
            removeItem={removeItem}
            makeCopy={makeCopy}
            clickTagsSelectorControl={clickTagsSelectorControl}
            imageActions={imageActions}
          />
        ))}
      </tbody>
    </table>
  )
}
