import SmReactSelect from './shared/sm_react_select'
import SmPhotoswipe from './shared/photoswipe/sm_photoswipe'
import SortableSelectInput from './shared/sortable_select_input'
import PhotoswipeGalleryLink from './shared/photoswipe/photoswipe_gallery_link'
import PhotoswipeImageLink from './shared/photoswipe/photoswipe_image_link'
import PhotoswipeLink from './shared/photoswipe/photoswipe_link'

import CarBrandSelectorApp from './order_requests/car_brand_selector_app'
import OrderInformationApp from './order_requests/order_information_app'
import CreateAlbumButtonApp from './create_album_button_app'

import AttachableLinks from './shared/attachable_links'
import AttachmentCategory from './shared/attachment_category'
import ButtonGroup from './shared/button_group'
import DatatablesGlobalSearch from './shared/datatables_global_search'
import SwitchableStateIndicator from './shared/switchable_state_indicator'
import ProjectFormControls from './shared/project_form_controls'
import ElementGroupFormControls from './shared/element_group_form_controls'
import DateTimeInput from './shared/date_time_input'
import DownloadAllAttachments from './shared/download_all_attachments'
import SpinnerOverlay from './shared/spinner_overlay'
import ElementsOrder from './elements/order'
import ElementsNewOrderButton from './elements/new_order_button'
import Elements from './elements'
import ElementsRentListApp from './elements/rent_list_app'
import ElementsRentListOverviewApp from './elements/rent_list_overview_app'
import WorkingHours from './admin/working_hours'
import List from './my_files/list'
import HeaderLink from './my_files/header_link'
import ElementPhotosList from './project_app/element_photos_app'
import ManageElementPhotos from './project_app/manage_element_photos_app'
import Project from './project_app'
import { Basket, Control } from './extra_amount'
import Forms from './forms'
import UploaderV2 from './uploader_v2_app'
import UploaderItems from './uploader_v2_app/items'
import AlbumsUploaderV2App from './uploader_v2_app/albums_app'
import UploaderOptionsInitializer from './uploader_v2_app/options_initializer'
import Campaigns from './campaigns'
import CampaignPostorders from './campaign_postorders'
import OverviewApp from './overview_app'
import ProjectsChartApp from './projects_chart_app'
import ToggleButton from './shared/toggle_button'
import ProjectComponents from './projects'
import FormBuilder from './form_builder'
import ElementsSelector from './shared/elements_selector'
import MultiStep from './multi_step'
import SharingLinksApp from './sharing_links_app'
import Surveys from './surveys'
import Reports from './reports'
import LocationOverviewInputApp from './admin/locations/location_overview_input_app'

export default {
  AttachableLinks,
  AttachmentCategory,
  ButtonGroup,
  Campaigns,
  CampaignPostorders,
  CarBrandSelectorApp,
  CreateAlbumButtonApp,
  DatatablesGlobalSearch,
  DateTimeInput,
  DownloadAllAttachments,
  ElementGroupFormControls,
  ElementPhotosList,
  Elements,
  ElementsNewOrderButton,
  ElementsOrder,
  ElementsSelector,
  ElementsRentListApp,
  ElementsRentListOverviewApp,
  ExtraAmount: {
    Basket,
    Control,
  },
  FormBuilder,
  Forms,
  LocationOverviewInputApp,
  MyFiles: {
    List,
    HeaderLink,
  },
  ManageElementPhotos,
  MultiStep,
  OrderInformationApp,
  OverviewApp,
  PhotoswipeGalleryLink,
  PhotoswipeImageLink,
  PhotoswipeLink,
  ProjectFormControls,
  ProjectsChartApp,
  SmPhotoswipe,
  SmReactSelect,
  SortableSelectInput,
  Surveys,
  SpinnerOverlay,
  SwitchableStateIndicator,
  WorkingHours,
  Project,
  Projects: ProjectComponents,
  Reports,
  SharingLinksApp,
  ToggleButton,
  Uploader: {
    Items: UploaderItems,
    V2: UploaderV2,
    AlbumsApp: AlbumsUploaderV2App
  },
  UploaderOptionsInitializer,
}
