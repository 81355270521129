import * as React from 'react'
import { useState } from 'react'
import Filter from './filter'
import ReplaceFilter from './replace_filter'

type FilterTabsProps = {
  locationId: number
  accessControl: any
}

const FILTER_TABS = ['add', 'replace']
const { I18n } = window

export default function FilterTabs(props: FilterTabsProps) {
  const { accessControl, locationId } = props
  // extract hash from url
  const { hash } = window.location
  const defaultTab = hash && FILTER_TABS.includes(hash.slice(1)) ? hash.slice(1) : FILTER_TABS[0]
  const [activeTab, setActiveTab] = useState(defaultTab)
  return (
    <>
      <ul className="nav nav-tabs">
        {FILTER_TABS.map((tab) => (
          <li key={tab} className="nav-item">
            <a
              className={`nav-link ${activeTab === tab ? 'active' : ''}`}
              onClick={() => setActiveTab(tab)}
              href={`#${tab}`}
            >
              {I18n.t(`elements.order_form.filter_tabs.${tab}`)}
            </a>
          </li>
        ))}
      </ul>
      {activeTab === 'add' && <Filter accessControl={accessControl} />}
      {activeTab === 'replace' && <ReplaceFilter accessControl={accessControl} locationId={locationId} />}
    </>
  )
}
