const I18n = window.I18n

export default ({ counters, isPreview, onClickAddRow, toggleWorkAreaMode, onClickSave, undoChange, redoChange }) => {

  const { past, future } = counters

  return (
    <div className="row main-buttons justify-content-end">
      <div className="col-12 text-right">
        { !isPreview &&
          <a className="btn btn-secondary btn-sm" onClick={onClickAddRow}>
            {I18n.t('handover_forms.actions.add_new_row')}
          </a>
        }
        { isPreview &&
          <button type="button" className="btn btn-secondary btn-sm" onClick={() => toggleWorkAreaMode('')}>
            {I18n.t('handover_forms.actions.back_to_edit_mode')}
          </button>
        }
        { !isPreview &&
          <button type="button" className="btn btn-secondary btn-sm" onClick={() => toggleWorkAreaMode('preview')}>
            {I18n.t('application.actions.preview')}
          </button>
        }
        <button type="button" className="btn btn-secondary btn-sm" onClick={undoChange} disabled={past == 0}>
          <i className="fa fa-undo"></i>&nbsp;
          {I18n.t('application.actions.undo')}&nbsp;{past > 0 && past}
        </button>
        <button type="button" className="btn btn-secondary btn-sm" onClick={redoChange} disabled={future == 0}>
          {future > 0 && future}&nbsp;{I18n.t('application.actions.redo')}&nbsp;
          <i className="fa fa-repeat"></i>
        </button>
        <button type="button" className="btn btn-sm btn-primary" onClick={onClickSave}>
          {I18n.t('application.actions.save')}
        </button>
      </div>
    </div>
  )
}
