import { EventEmitter } from 'fbemitter'

class ButtonGroupStore extends EventEmitter {
  constructor () {
    super()
    this.activeIds = {}
  }

  toggle (id, groupId) {
    if (!(groupId in this.activeIds)) {
      this.activeIds[groupId] = []
    }
    const idx = this.activeIds[groupId].indexOf(id)
    if (idx >= 0) {
      this.activeIds[groupId].splice(idx, 1)
    } else {
      this.activeIds[groupId].push(id)
    }
    this.emit('change')
  }

  isActive (id, groupId) {
    if (!(groupId in this.activeIds)) {
      return false
    }
    return this.activeIds[groupId].includes(id)
  }
}

export default new ButtonGroupStore()
