import React, { Fragment } from 'react'
import { findDOMNode } from 'react-dom'
import classNames from 'classnames'
import DraggableRow from './builder_items/draggable_row'
import { DropTarget } from 'react-dnd'

import ItemTypes from './lib/item_types'
import { itemByPath } from './lib/helpers'
import MainButtons from './main_buttons'
import SettingsBar from './settings_bar'
import Preview from './preview'
import { prepareDataForSend } from './lib/helpers'

const I18n = window.I18n

const BuilderTarget = {}
const BUILDER_INIT_STATE = Object.freeze({ mode: '', type: '', path: [] })

class Builder extends React.Component {
  state = BUILDER_INIT_STATE

  toggleWorkAreaMode = (mode) => {
    this.setState(prevState => Object.assign({}, prevState, { mode }))
  }

  showSettings = (type, path) => {
    this.setState({ type, path })
  }

  hideSettings = () => {
    this.setState(BUILDER_INIT_STATE)
  }

  updateFormAttr = (e) => {
    const attrs = Object.assign({}, this.props.data.attrs, { [e.target.name]: e.target.value })
    this.props.updateFormAttrs(attrs)
  }

  updateItemAttr = (e, item, type, path) => {
    const data = Object.assign({}, item, { [e.target.name]: e.target.value })
    this.updateItem(type, data, path)
  }

  updateItem = (type, data, path) => {
    // this.setState(prevState => Object.assign(prevState, { item: data }))
    switch (type) {
      case ItemTypes.ROW.type:
        this.props.updateRowItem(data, path)
        break
      case ItemTypes.CELL.type:
        this.props.updateCellItem(data, path)
      case ItemTypes.ELEMENT.type:
        this.props.updateElementItem(data, path)
        break
    }
  }

  handleSave = () => {
    const data = { name: this.props.data.attrs.name }
    data['rows_attributes'] = prepareDataForSend(this.props.data.rows)
    this.props.saveForm(this.props.url, data)
  }

  addRowItem = () => {
    this.props.addRowItem(ItemTypes.ROW.init)
  }

  renderRows = (rows) => {
    const { moveRowItem, updateRowItem, deleteRowItem } = this.props

    return rows.reduce((result, row, index) => {
      if (row._destroy) { return result }
      result.push(
        <DraggableRow key={`row-${index}`}
          row={row}
          index={index}
          selectedItem={this.item()}
          updateRowItem={updateRowItem}
          moveRowItem={moveRowItem}
          deleteRowItem={deleteRowItem}
          showSettings={this.showSettings}
        />
      )
      return result
    }, [])
  }

  workAreaColClasses = () => {
    if (this.state.mode == 'preview') {
      return [classNames(['col-12', 'preview']), 'd-none', 'd-none']
    }

    const defaultPreviewClasses = classNames(['d-none', 'preview'])
    if (this.item()) {
      return [defaultPreviewClasses, classNames(['col-6', 'form-content']), classNames(['col-6', 'settings'])]
    }
    return [defaultPreviewClasses, classNames(['col-12', 'form-content']), classNames(['d-none', 'settings'])]
  }

  settingsBarHandlers = () => ({
    updateItem: this.updateItem,
    updateItemAttr: this.updateItemAttr,
    deleteElementItem: this.props.deleteElementItem,
    moveElementItem: this.props.moveElementItem,
    showSettings: this.showSettings,
    onClose: this.hideSettings
  })

  item = () => itemByPath({ rows: this.props.data.rows, path: this.state.path })

  render () {
    const { connectDropTarget, data } = this.props
    const { attrs, rows } = data
    const [previewClasses, formContentClasses, itemSettingsClasses] = this.workAreaColClasses()

    const isPreview = (this.state.mode == 'preview')

    return (
      <div className={classNames(['builder', 'card'])}>
        <div className="card-header">
          <div className="row">
            <div className="col-6">
              <div className="form-inline">
                <label>{I18n.t('handover_forms.builder_title')}</label>&nbsp;
                <input type="text" className="form-control" placeholder="Name" name="name" value={attrs?.name || ''} onChange={this.updateFormAttr} />
              </div>
            </div>
            <div className="col-6">
              <MainButtons isPreview={isPreview} counters={this.props.counters}
                onClickAddRow={this.addRowItem}
                toggleWorkAreaMode={this.toggleWorkAreaMode}
                onClickSave={this.handleSave}
                undoChange={this.props.undoChange}
                redoChange={this.props.redoChange}
              />
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row work-area">
            <div className={previewClasses}>{ isPreview && <Preview rows={rows} /> }</div>
            { connectDropTarget(
                <div className={formContentClasses}>{this.renderRows(rows)}</div>
              )
            }
            <div className={itemSettingsClasses}>
              <SettingsBar
                item={this.item()}
                path={this.state.path}
                type={this.state.type}
                handlers={this.settingsBarHandlers()} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default DropTarget(ItemTypes.ROW.type, BuilderTarget, connect => ({
  connectDropTarget: connect.dropTarget(),
}))(Builder)
