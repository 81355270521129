import React from 'react'
import Moment from 'moment'
import { extendMoment } from 'moment-range'
import { groupBy, isNull } from 'lodash'
import ChartHeader from './chart_header'
import ChartRow from './chart_row'

const moment = extendMoment(Moment)

function startDateFromProjects(projects) {
  const startDates = projects.map(p => p.start_date).filter(d => !isNull(d))
  if (!startDates.length) {
    const createdDates = projects.map(p => moment(p.created_at))
    return moment.min(createdDates).startOf('day')
  }
  return moment.min(startDates).startOf('day')
}

function endDateFromProjects(projects, startDate) {
  const endDates = projects.map(p => (isNull(p.deadline) ? null : moment(p.deadline))).filter(d => !isNull(d))
  if (!endDates.length) {
    const installerVisitDates = projects
      .map(p => (isNull(p.installer_visit_date) ? null : moment(p.installer_visit_date)))
      .filter(d => !isNull(d))

    if (!installerVisitDates.length) {
      startDate
        .clone()
        .add(1, 'week')
        .startOf('day')
    }
    return moment.max(installerVisitDates).startOf('day')
  }
  return moment.max(endDates).startOf('day')
}

function ProjectChart(props) {
  const projects = props.projects
  const projectGroups = props.project_groups
  const monthNames = props.month_names
  const weekdayNames = props.weekday_names

  const startDate = startDateFromProjects(projects)
  const endDate = endDateFromProjects(projects, startDate)

  const range = Array.from(
    moment()
      .range(startDate, endDate)
      .by('day', { excludeEnd: false })
  )
  const months = groupBy(range, date => [date.year(), date.month()])
  return (
    <div className="projects-chart">
      <table className="table table-bordered table-responsive">
        <thead>
          <ChartHeader months={months} monthNames={monthNames} weekdayNames={weekdayNames} />
        </thead>
        <tbody>
          {projectGroups.map(group => {
            const groupProjects = projects.filter(p => p.project_group_id === group.id)
            const groupStartDate = startDateFromProjects(groupProjects)
            const groupEndDate = endDateFromProjects(groupProjects, groupStartDate)

            return (
              <React.Fragment key={group.id}>
                <ChartRow name={group.name} startDate={groupStartDate} endDate={groupEndDate} range={range} />
                {groupProjects.map(project => {
                  const installationDate = project.installer_visit_date ? moment(project.installer_visit_date) : null
                  const deadline = project.deadline ? moment(project.deadline) : null
                  return (
                    <ChartRow
                      key={project.id}
                      name={` - ${project.title}`}
                      startDate={moment(project.start_date || project.created_at)}
                      endDate={deadline}
                      installationDate={installationDate}
                      range={range}
                    />
                  )
                })}
              </React.Fragment>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default ProjectChart
