import brandShow from './brands/show'

import companiesIndex from './companies/index'

import elementOrdersIndex from './element_orders/index'
import elementsIndex from './elements/index' // elements amounts

import installersIndex from './installers/index'

import pagesIndex from './pages/index'
import pagesShow from './pages/show'

import locationsIndex from './locations/index'
import locationsShow from './locations/show'

import projectsIndex from './project_tickets/index'
import projectsShow from './project_tickets/show'

import projectExpensesEdit from './project_expenses/edit'
import projectExpensesIndex from './project_expenses/index'

import reportsShow from './reports/show'

import AdditionalReports from './additional_reports'

import Overviews from './overviews'
import Campaigns from './campaigns'
import vmProjectsIndex from './vm/projects/index'

import userForm from './users/form'

import orderRequestsShow from './order_requests/show'

export default {
  additional: { reports: AdditionalReports },
  brands: {
    show: brandShow,
  },
  campaigns: {
    index: Campaigns.Index,
    new: Campaigns.FormFields,
    edit: Campaigns.FormFields,
    locations: Campaigns.ChangesMonitor,
    elements: Campaigns.ChangesMonitor,
    show: Campaigns.Show,
    summary: Campaigns.Summary
  },
  companies: {
    index: companiesIndex,
  },
  elements: {
    orders: {
      index: elementOrdersIndex,
    },
    index: elementsIndex,
  },
  installers: {
    index: installersIndex,
  },
  locations: {
    index: locationsIndex,
    show: locationsShow
  },
  overviews: {
    project_groups: Overviews.ProjectGroups,
    multi_step_workflows: Overviews.ProjectGroups,
    vendors: Overviews.Vendors
  },
  pages: {
    index: pagesIndex,
    show: pagesShow,
  },
  project: {
    tickets: {
      index: projectsIndex,
      show: projectsShow,
    },
    expenses: {
      edit: projectExpensesEdit,
      index: projectExpensesIndex,
      new: projectExpensesEdit,
      update: projectExpensesEdit
    },
    groups: {
      show: Overviews.ProjectGroupShow
    }
  },
  reports: {
    show: reportsShow,
  },
  users: {
    new: userForm,
    create: userForm
  },
  vendors: {
    show: Overviews.VendorInstallers
  },
  vm: {
    projects: {
      not_assigned: vmProjectsIndex,
      assigned: vmProjectsIndex,
      completed: vmProjectsIndex,
      expired: vmProjectsIndex
    }
  },
  order: {
    requests: {
      show: orderRequestsShow
    }
  }
}
