import _ from 'underscore'
import { capitalize } from 'libs/utils'

class ListSearch {
  /**
   * @param list
   * @param options
   *   Possible fields:
   *      searchField - Jquery object for connected filter field
   *      searchSelectors - The list of selectors which data should be participate in filter
   *      type - Right now we support input, checkbox
   *      name - The uniq name of option. Used as key in filteredBy object
   *      searchTerm - The value for filter. Is actual for checkbox
   */
  constructor (list, options) {
    this.options = options
    this.list = list.each((_i, item) => item.filteredBy = {})
    this.options.forEach(option => this[`bindTo${capitalize(option.type)}Field`](option))
  }

  filter () {
    this.list.each((_i, item) => {
      _.any(_.values(item.filteredBy)) ? $(item).hide() : $(item).show()
    })
  }

  bindToInputField ({ searchField, searchSelectors, name }) {
    searchField.on('change keyup', (e) => {
      const searchTerm = e.target.value.toLowerCase()
      this.list.each(function(_i, item) {
        const searchAmong = searchSelectors.map(selector => $(this).find(selector).text())
        item.filteredBy[name] = !_.any(searchAmong, c => c.toLowerCase().includes(searchTerm))
      })
      this.filter()
    })
  }

  bindToCheckboxField ({ searchField, searchSelectors, searchTerm, name }) {
    searchField.on('change', (e) => {
      this.list.each(function(_i, item) {
        const searchAmong = searchSelectors.map(selector => $(this).find(selector).text())
        item.filteredBy[name] = e.currentTarget.checked && !_.any(searchAmong, c => c.includes(searchTerm))
      })
      this.filter()
    })
  }
}

export default ListSearch
