import * as React from 'react'
import { ProjectReportFilterDefinitionType, SavedFilterType, SelectedFiltersType } from './types'
import ChangeSavedFilterButton from './change_saved_filter_button'
import useSavedFiltersController from './use_saved_filters_controller'
import I18n from '../../../../libs/i18n'

type SavedFilterProps = {
  savedFilters: SavedFilterType[]
  filter: SelectedFiltersType
  setFilter: (filters: SelectedFiltersType) => void
  availableFilters: ProjectReportFilterDefinitionType[]
  saveUserFilters: (filters: SavedFilterType[]) => void
  showCompletedLabel: string
  initialAvailableFilters: ProjectReportFilterDefinitionType[]
  completedStatusId: string
}

export default function SavedFilters(props: SavedFilterProps) {
  const {
    savedFilters,
    filter,
    setFilter,
    availableFilters,
    saveUserFilters,
    initialAvailableFilters,
    completedStatusId,
    showCompletedLabel,
  } = props
  const updateSavedFilters = (newSavedFilters) => {
    saveUserFilters(newSavedFilters)
  }

  const savedFiltersController = useSavedFiltersController({
    filter,
    setFilter,
    savedFilters,
    updateSavedFilters,
  })
  const { setCurrentSavedFilter } = savedFiltersController

  return (
    <div className="d-print-none">
      <div className="btn-group">
        <ChangeSavedFilterButton
          availableFilters={availableFilters}
          savedFiltersController={savedFiltersController}
          showCompletedLabel={showCompletedLabel}
          initialAvailableFilters={initialAvailableFilters}
          completedStatusId={completedStatusId}
        />
        <button
          type="button"
          className="btn btn-outline-secondary dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span className="caret" />
          <span className="sr-only">Toggle Dropdown</span>
        </button>
        <div className="dropdown-menu dropdown-menu-right">
          {savedFilters &&
            savedFilters.length > 0 &&
            savedFilters
              .sort((f) => (f.default ? -1 : 1))
              .map((savedFilter) => (
                <a
                  key={savedFilter.id}
                  className="dropdown-item"
                  href="#"
                  onClick={(e) => {
                    e.preventDefault()
                    setCurrentSavedFilter(savedFilter)
                    setFilter(savedFilter.filter)
                  }}
                >
                  {savedFilter.default ? I18n.t('custom_reports.projects.saved_filters.reset_to_default') : savedFilter.name}
                </a>
              ))}
        </div>
      </div>
    </div>
  )
}
