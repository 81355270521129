import MinMaxErrorsHelper from 'views/campaigns/helpers/min_max_validator'

const ErrorMsg = ({ msg }) => {
  if (!msg) { return null }

  return (
    <div className={MinMaxErrorsHelper.inlineErrorClass}>{msg}</div>
  )
}

const TagWithAmountInput = ({ tag, amount, changeTagAmount, min, max }) => {
  const { name, type, value } = tag

  const onChange = (e) => changeTagAmount(tag.value, e.target.value)

  const htmlTagId = `tag_amount_${value}`

  let errorMsg = ''
  if (!MinMaxErrorsHelper.valueBasedOnMinMaxIsValid(amount, min, max)) {
    errorMsg = MinMaxErrorsHelper.buildMessageFor(min, max)
  }

  return (
    <div className="col-2">
      <label htmlFor={htmlTagId}>{name}</label>
      <input type="number" id={htmlTagId} min={min} max={max} value={amount} onChange={onChange} name="amount" className="form-control" />
      <ErrorMsg msg={errorMsg} />
    </div>
  )
}

export default TagWithAmountInput
