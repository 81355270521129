import { Component } from 'react'
import PropTypes from 'prop-types'
import CableEmitter from 'emitters/cable_emitter'
import MyFileModel from 'models/my_file'
import MyFile from './my_file'

export default class MyFileList extends Component {
  static propTypes = {
    myFiles: PropTypes.array,
  }

  state = {
    myFiles: []
  }

  constructor (props) {
    super(props)
    // TODO (atanych): replace this structure with redux store
    this.state = this.buildState(props.myFiles)

    CableEmitter.addListener('MY_FILES.UPDATE', this.handleUpdate)
    CableEmitter.addListener('MY_FILES.CREATE', this.handleCreate)
  }

  handleUpdate = (newAttrs) => {
    const myFiles = this.state.myFiles.map((file) => {
      if (file.id !== newAttrs.id) { return file }
      file.update(newAttrs)
      return file
    })
    this.setState({ myFiles })
  }

  handleCreate = (newAttrs) => {
    const newFile = new MyFileModel(newAttrs)
    const myFiles = this.state.myFiles
    this.setState({ myFiles: [newFile].concat(myFiles) })
  }

  handleDelete = (id) => {
    ajax({url: `my_files/${id}`, method: 'delete'}).then((resp) => {
      this.setState(this.buildState(resp.data.my_files))
    })
  }

  buildState = (files) => {
    return { myFiles: files.map(file => new MyFileModel(file)) }
  }

  render () {
    const { myFiles } = this.state
    if (!myFiles.length) { return <div>{I18n.t('my_files.index.empty_message')}</div> }

    return (
      <section>
        <table className="table table-sm">
          <tbody>
            {myFiles.map(file => <MyFile file={file} key={file.id} onDelete={this.handleDelete} />)}
          </tbody>
        </table>
      </section>
    )
  }
}
