import React from 'react'
import ImageList from 'components/uploader_v2_app/views/thumbnails_collection/image_list'

export default class  RowItem extends React.Component {

  onChange = (e) => {
    this.props.updateItem(this.props.campaignElementId, { extraAmount: e.target.value })
  }

  itemAttrs = () => ({
    name:                  this.props.item.get('elementName'),
    extraAmount:           this.props.item.get('extraAmount'),
    orderedAmount:         this.props.item.get('orderedAmount'),
    locationElementAmount: this.props.item.get('locationElementAmount')
  })

  render () {
    const { name, locationElementAmount, orderedAmount, extraAmount } = this.itemAttrs()
    return (
      <tr>
        <td>{name}</td>
        <td className="thumbnails-collection">
          <ImageList images={this.props.item.get('images').toJS()} controlMenu={false} />
        </td>
        <td>{locationElementAmount}</td>
        <td>{orderedAmount}</td>
        <td>
          <input type="number" onChange={this.onChange} value={extraAmount} />
        </td>
      </tr>
    )
  }
}
