import * as React from 'react'
import { Provider } from 'react-redux'
import store from '../../../store'
import SelectProjectTicket from './select_project_ticket'

export default function SelectProjectTicketApp(props) {
  return (
    <Provider store={store}>
      <SelectProjectTicket {...props} />
    </Provider>
  )
}
