import React, { Fragment } from 'react'
import { findDOMNode } from 'react-dom'
import classNames from 'classnames'
import { DragSource, DropTarget } from 'react-dnd'
import { flow } from 'lodash'

import ItemTypes from '../lib/item_types'
import CellItem from './cell_item'

const RowSource = {
  beginDrag (props) {
    return { action: 'beginDrag', index: props.index }
  },
  endDrag (props, monitor) {
    props.moveRowItem(props.index, monitor.getItem().index);
    return { action: 'endDrag', index: props.index }
  }
}

const RowTarget = {
  drop(props, monitor, component) { },
  hover(props, monitor, component) {
    if (!component) { return null }

    const node = findDOMNode(component)
    if (!node) { return null }

    const dragIndex = monitor.getItem().index
    const hoverIndex = props.index
    if (dragIndex === hoverIndex) { return }

    const hoverBoundingRect = node.getBoundingClientRect();

    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    const clientOffset = monitor.getClientOffset();

    const hoverClientY = (clientOffset).y - hoverBoundingRect.top;

    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    monitor.getItem().index = hoverIndex;
  },
}

const RowItemControls = ({ index, onClickGear, onClickDelete, connectDragSource }) => {
  return (
    <div className="text-center">
      { connectDragSource(
          <div className="row-item-header">
            <i className="fa fa-arrows-v"></i>
            <span className="flag">{ItemTypes.ROW.name(index)}</span>
          </div>
        )
      }
      <i className="fa fa-cog" onClick={onClickGear}></i>
      <i className="fa fa-trash" onClick={onClickDelete}></i>
    </div>
  )
}

class DraggableRow extends React.Component {

  onClickGear = () => {
    this.props.showSettings(ItemTypes.ROW.type, [this.props.index])
  }

  onClickDelete = () => {
    this.props.deleteRowItem(this.props.index)
  }

  rowClasses = (row, selectedItem) => {
    const cssClasses = ['row', 'row-item']
    if (row == selectedItem) { cssClasses.push('selected') }
    return classNames(cssClasses)
  }

  render () {
    const { connectDragSource, isDragging, connectDragPreview, connectDropTarget,
      row, selectedItem
    } = this.props

    const rowClasses = ['row', 'row-item']
    if (row == selectedItem) { rowClasses.push('selected') }
    if (isDragging) { rowClasses.push('is-gragging') }

    let path = [this.props.index]

    const cells = row.cells.reduce((result, cell, index) => {
      if (cell._destroy) { return result }
      result.push(
        <CellItem key={`col-${index}`} cell={cell} path={[...path, index]}
          selectedItem={selectedItem}
          onClickGear={this.props.showSettings} deleteAllowed={row.cells.length > 1}
        />
      )
      return result
    }, [])

    return (
      connectDropTarget(connectDragSource(
        <div className={classNames(rowClasses)}>
          {cells}
          <div className="row-item-controls">
            <RowItemControls
              index={path[0]}
              onClickGear={this.onClickGear}
              onClickDelete={this.onClickDelete}
              connectDragSource={connectDragSource}
            />
          </div>
        </div>
      ))
    )
  }
}

export default flow(
  DragSource(
    ItemTypes.ROW.type,
    RowSource,
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      connectDragPreview: connect.dragPreview(),
      isDragging: monitor.isDragging(),
    }),
  ),
  DropTarget(ItemTypes.ROW.type, RowTarget, (connect) => ({
    connectDropTarget: connect.dropTarget(),
  }))
)(DraggableRow)
