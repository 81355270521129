import CustomDatatable from 'libs/datatables/custom_datatable'
import CompactModeToggle from 'libs/datatables/compact_mode_toggle'
import _ from 'underscore'

export default class CustomDatatableWithSearch extends CustomDatatable {
  constructor(table, search_field, settings = {}) {
    super()
    if (!$(table).length) { return }

    // fill in search input upon state loading
    let options = this.defaultOptions()
    const disableCompactMode = settings['disableCompactMode'] === true
    options['stateLoadCallback'] = settings => {
      const key = `DataTables_${settings.sInstance}_${location.pathname}`
      const state = JSON.parse(localStorage.getItem(key))
      if (state && state['search']) {
        const search = state['search']['search']
        $(search_field).val(search)
      }
      if (state && state['compactModeState'] && !disableCompactMode) {
        this._compactModeState = state['compactModeState']
      }
      return state
    }

    options['stateSaveParams'] = (settings, data) => {
      if (this.compactModeState() && !disableCompactMode) {
        data['compactModeState'] = this.compactModeState()
      }
      return data
    };

    options = $.extend(options, settings)
    this.datatable = table.dataTable(options)

    const dt = this.datatable;
    search_field.on('keyup change', function() { return dt.fnFilter(this.value) })

    if (!disableCompactMode) {
      this.restoreCompactModeState()
      this.appendCompactModeToggle(search_field.parent())
    }
    return this.datatable // return link to API
  }

  restoreCompactModeState() {
    const columns_count = this.datatable.api().columns().indexes().length
    let compactModeState = columns_count > 6 ?  'collapsed' : 'expanded'
    const state = this.datatable.api().state()

    if (state && state['compactModeState']) {
      compactModeState = state['compactModeState']
    }

    compactModeState === 'expanded' ?  this.expand() : this.collapse()
  }

  appendCompactModeToggle(container) {
    this.compactModeToggle = new CompactModeToggle(container, this.compactModeState())
    this.compactModeToggle.on('expanded', () => { this.expand() })
    this.compactModeToggle.on('collapsed', () => { this.collapse() })
  }

  updateCompactModeState(state) {
    this._compactModeState = state
    this.datatable.api().state.save()
  }

  compactModeState() {
    return this._compactModeState
  }

  expand() {
    this.datatable.api().columns().every(function() {
      // restore full header
      const header = this.header()
      const newContent = $(header).data('fullheader')
      $(header).html(newContent)

      // restore blank columns visibility
      this.visible(true)
    })
    this.updateCompactModeState('expanded')
  }

  collapse() {
    this.datatable.api().columns().every(function() {
      // truncate header
      const header = this.header()
      const content = $(header).html()
      const newContent = content.charAt(0).toUpperCase()
      $(header).html(newContent)
      $(header).data('fullheader', content)
      $(header).attr('title', content)

      // hide blank columns
      const data =  this.data()
      const blankColumn = _.all(data, cell=> cell.length === 0)
      blankColumn ? this.visible(false) : this.visible(true)
    })
    this.updateCompactModeState('collapsed')
  }
}
