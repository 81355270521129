export default class Spinner {
  cssClasses() {
    return "fa fa-spinner fa-spin"
  }
  selectors() {
    return `.${this.cssClasses().split(' ').join('.')}`
  }
  html(size = 'auto') {
    return $(`<i class="${this.cssClasses()}" style="font-size:${size};">`)
  }
  presentIn($contex) {
    return $(this.selectors(), $contex).length > 0
  }
}
