import { createSelector } from 'reselect'

export const getLinksByElement = createSelector(
  (links, elementId) => ({ links, elementId }),
  ({ links, elementId }) => links.filter(link => link.get('element_id') === elementId),
)


export const getPhotoByLink = createSelector(
  (photos, link) => ({ photos, link }),
  ({ photos, link }) => photos.find(photo => photo.get('id') === link.get('attachment_id')),
)
