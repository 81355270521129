export default class CompactModeToggle {
  constructor(appendTo, _state) {
    this.appendTo = appendTo
    this._state = _state
    this.cssClass = 'custom-datatable-compact-mode-switch'
    this._callbacks = {
      expanded() {},
      collapsed() {}
    }

    this.render()
  }

  html() {
    const iconClass = this.state() === 'expanded' ? 'fa-compress' : 'fa-expand'
    return $(`<a class='${this.cssClass} btn btn-secondary'><i class='fa ${iconClass}'></a>`)
  }

  on(event, callback) {
    return this._callbacks[event] = callback
  }

  state() {
    return this._state
  }

  switchState() {
    if (this._state === 'expanded') {
      this._state = 'collapsed'
    } else {
      this._state = 'expanded'
    }

    this._callbacks[this._state]()
    return this
  }

  setState(_state) {
    this._state = _state
    this._callbacks[this._state]()
    return this
  }

  render() {
    const oldSwitch = this.appendTo.find(`.${this.cssClass}`)
    oldSwitch.off('click')
    oldSwitch.remove()

    const html = this.html()
    this.appendTo.prepend(html)

    const toggle = this
    $(html).on('click', function() {
      toggle.switchState()
      toggle.render()
    })
  }
}


