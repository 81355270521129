import React from 'react'
import  WaitingUpdatesChannel from 'acable_channels/waiting_updates'
import { Line } from 'rc-progress'
import notificationBlocker from 'libs/notifications_blocker'

notificationBlocker.addCatchFor('campaign_waiting_update')

class TimeIterator {
  constructor (start, addition = 100) {
    this._addition = addition
    this._value = start
  }

  get next() {
    this._value = this._value + this._addition
    return this._value - this._addition
  }
}

const timeCounter = new TimeIterator(300, 200)

export default class WaitProcessing extends React.Component {

  constructor (props) {
    super(props)
    this.state = { value: '' }
    this.updatesChannel = new WaitingUpdatesChannel(this.channelData(), this.onReceivedHandler)
  }

  onReceivedHandler = (payload) => {
    if (payload.value || payload.value == 0) {
      setTimeout(() => this.setState({ value: payload.value }), timeCounter.next)
    }
    if (payload.location) { setTimeout(() => window.location.href = payload.location, timeCounter.next) }
  }

  channelData = () => ({
      channel: 'WaitingUpdatesChannel',
      room: 'Campaign',
      id: this.props.id
  })

  checkCampaignState () {
    const { id, step, stepAction } = this.props
    const stepActionParam = stepAction ? `&step_action=${stepAction}` : ''
    ajax({
      url: `/campaigns/${id}/wait-processing.json?step=${step}${stepActionParam}`,
      method: 'get'
    }).then(resp => {
      if (resp.data.location) { this.onReceivedHandler({ value: 100, location: resp.data.location}) }
    })
  }

  componentDidMount () {
    this.checkCampaignState()
  }

  render () {
    if (this.state.value === '') { return null }
    return (
      <div>
        <Line percent={this.state.value} strokeWidth="1" strokeColor="#007bff" />
        <div>{this.state.value}%</div>
      </div>
    )
  }
}
