import DataTableHelpers from 'libs/datatable_helpers'

DataTableHelpers.destroyBeforeCaching()

const $table  = $('#vendors_table')
const options = {
  pageLength: 20,
  bStateSave: false,
  info: false,
  dom: 't<"bottom"p>',
  autoWidth: false,
  stateSave: true,
  order: [[1, 'asc']]
}
export default function () {
  const table = $table.dataTable(options)
  DataTableHelpers.initSearch(table, $('#search input.search'))
}
