import { connect } from 'react-redux'
import { openImagesModal } from 'actions/project_actions'
import * as selectors from 'reducers/project/selectors'

function mapStateToProps (state) {
  return {
    projectLoaded: state.getIn(['project', 'transient', 'loaded']),
    projectStatus: state.getIn(['project', 'status']),
    links: state.getIn(['project', 'links']),
    photos: state.getIn(['project', 'photos']),
  }
}

function mapDispatchToProps (dispatch) {
  return {
    openImagesModal: elementId => dispatch(openImagesModal(elementId)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
