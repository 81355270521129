import React from 'react'
import PhotoswipeLink from 'components/shared/photoswipe/photoswipe_link'

class Photo extends React.Component {

  render () {
    const template = this.props.element.get('template')
    const thumbUrl = template.get('photo_thumb')
    const originalUrl = template.get('photo_original')
    const galleryId = `preview${this.props.element.get('id')}`

    return (
      <PhotoswipeLink src={ originalUrl } galleryId={ galleryId } >
        <img src={thumbUrl} />
      </PhotoswipeLink>
    )
  }
}

export default Photo
