import React from 'react'
import PropTypes from 'prop-types'
import { List } from 'immutable'
import _ from 'underscore'

import FilterSelect from './filter_select'
import TemplateSelect from './template_select'

const { I18n } = window

class Filter extends React.Component {
  static propTypes = {
    dictionaries: PropTypes.object,
    currentElementGroup: PropTypes.number,
    currentDepartment: PropTypes.number,
    currentElementSubGroup: PropTypes.number,
    currentTemplate: PropTypes.number,
    searchTerm: PropTypes.string,
    templates: PropTypes.object,
  }

  componentDidMount () {
    this.dispatchLoadTemplates()
  }

  componentDidUpdate (prevProps) {
    if (this.filterPropsChanged(prevProps)) {
      this.dispatchLoadTemplates()
    }
  }

  filterPropsChanged(prevProps) {
    const filterProps = ['currentElementGroup', 'currentElementSubGroup', 'currentDepartment']
    return _.any(filterProps, (p) => {
      if (_.isNaN(this.props[p]) && (_.isNaN(prevProps[p]) || _.isNull(prevProps[p]))) {
        return false
      }
      return this.props[p] != prevProps[p]
    })
  }

  dispatchLoadTemplates () {
    let params = {}
    if (this.props.currentElementGroup) {
      params.group = this.props.currentElementGroup
    }
    if (this.props.currentElementSubGroup) {
      params.sub_group = this.props.currentElementSubGroup
    }
    if (this.props.currentDepartment) {
      params.department = this.props.currentDepartment
    }
    this.props.loadTemplates(params)
  }

  templatesToOptionsArray() {
    if (this.props.templates) {
      let templates = this.props.templates.map( (t) => {
        return new List([t.get('name'), t.get('id')])
      })
      return templates
    }
    return new List()
  }

  filterOptions(options, filteredIds) {
    return options.filter(o => filteredIds.includes(o.get(1)))
  }

  render () {
    const selectSize = 13
    return (
      <div className="order-form-filter">
        <div className="order-elements-template-filters">
          <FilterSelect
            elementId="element_group"
            label={ I18n.t('activerecord.models.elements/group.one') }
            blankLabel = {I18n.t('elements.order_form.blank_element_group')}
            size={selectSize}
            selectedValue={this.props.currentElementGroup}
            handleChange={this.props.selectElementGroup}
            selectOptions={this.props.dictionaries.elementGroups}
          />

          <FilterSelect
            elementId="element_sub_group"
            label={ I18n.t('activerecord.models.elements/sub_group.one') }
            blankLabel = {I18n.t('elements.order_form.blank_element_subgroup')}
            size={selectSize}
            selectedValue={this.props.currentElementSubGroup}
            handleChange={this.props.selectElementSubGroup}
            selectOptions={this.filterOptions(this.props.dictionaries.elementSubGroups, this.props.filtered.elementSubGroups)}
            showOverlay={this.props.templatesLoading}
          />

          <FilterSelect
            elementId="element_department"
            label={ I18n.t('activerecord.models.lists/department.one') }
            blankLabel = {I18n.t('elements.order_form.blank_department')}
            size={selectSize}
            selectedValue={this.props.currentDepartment}
            handleChange={this.props.selectDepartment}
            selectOptions={this.filterOptions(this.props.dictionaries.departments, this.props.filtered.departments)}
            showOverlay={this.props.templatesLoading}
          />

          <FilterSelect
            elementId="element_template"
            label={ I18n.t('activerecord.models.elements/template.one') }
            blankLabel = {I18n.t('elements.order_form.choose_element')}
            size={selectSize}
            searchEnabled={true}
            searchTerm={this.props.searchTerm}
            selectedValue={this.props.currentTemplate}
            handleChange={this.props.loadTemplate}
            handleSearchChange={this.props.searchTemplate}
            selectOptions={this.templatesToOptionsArray()}
            showOverlay={this.props.templatesLoading || this.props.currentTemplateLoading}
          />
        </div>
      </div>
    )
  }
}

export default Filter
