import * as React from 'react'
import { List } from 'immutable'

import FilterSelect from '../filter/filter_select'

const { I18n } = window

type ReplaceFilterProps = {
  locationId: number
  dictionaries: any
  currentElementGroup: number
  currentDepartment: number
  currentElementSubGroup: number
  currentReplaceElementId: number
  searchTerm: string
  installedElements: any
  installedElementsLoading: boolean
  loadInstalledElements: (locationId: number, params: any) => void
  loadInstalledElement: (locationId: number, elementId: number) => void
  selectElementGroup: (elementGroup: number) => void
  selectElementSubGroup: (elementSubGroup: number) => void
  selectDepartment: (department: number) => void
  searchTemplate: (searchTerm: string) => void
  filtered: {
    departments: any
    elementSubGroups: any
  }
}

export default function ReplaceFilter(props: ReplaceFilterProps) {
  const {
    locationId,
    dictionaries,
    currentElementGroup,
    currentDepartment,
    currentElementSubGroup,
    currentReplaceElementId,
    searchTerm,
    installedElements,
    installedElementsLoading,
    loadInstalledElements,
    loadInstalledElement,
    selectElementGroup,
    selectElementSubGroup,
    selectDepartment,
    searchTemplate,
    filtered
  } = props

  const dispatchLoadTemplates = () => {
    const params = {}
    if (currentElementGroup) {
      params.group = currentElementGroup
    }
    if (currentElementSubGroup) {
      params.sub_group = currentElementSubGroup
    }
    if (currentDepartment) {
      params.department = currentDepartment
    }
    loadInstalledElements(locationId, params)
  }

  React.useEffect(() => {
    dispatchLoadTemplates()
  }, [currentElementGroup, currentElementSubGroup, currentDepartment])

  const installedElementsToOptionsArray = () => {
    if (!installedElements) {
      return new List()
    }

    return installedElements.map((t) => new List([t.get('name'), t.get('id')]))
  }

  const filterOptions = (options, filteredIds) => options.filter((o) => filteredIds.includes(o.get(1)))
  const selectSize = 13
  return (
    <div className="order-form-filter">
      <div className="order-elements-template-filters">
        <FilterSelect
          elementId="element_group"
          label={I18n.t('activerecord.models.elements/group.one')}
          blankLabel={I18n.t('elements.order_form.blank_element_group')}
          size={selectSize}
          selectedValue={currentElementGroup}
          selectOptions={dictionaries.locationGroups}
          handleChange={selectElementGroup}
        />

        <FilterSelect
          elementId="element_sub_group"
          label={I18n.t('activerecord.models.elements/sub_group.one')}
          blankLabel={I18n.t('elements.order_form.blank_element_subgroup')}
          size={selectSize}
          selectedValue={currentElementSubGroup}
          handleChange={selectElementSubGroup}
          selectOptions={filterOptions(dictionaries.locationSubGroups, filtered.elementSubGroups)}
          showOverlay={installedElementsLoading}
        />

        <FilterSelect
          elementId="element_department"
          label={I18n.t('activerecord.models.lists/department.one')}
          blankLabel={I18n.t('elements.order_form.blank_department')}
          size={selectSize}
          selectedValue={currentDepartment}
          handleChange={selectDepartment}
          selectOptions={filterOptions(dictionaries.locationDepartments, filtered.departments)}
          showOverlay={installedElementsLoading}
        />

        <FilterSelect
          elementId="element"
          label={I18n.t('activerecord.models.elements/base_element.one')}
          blankLabel={I18n.t('elements.order_form.choose_existing_element')}
          size={selectSize}
          searchEnabled
          searchTerm={searchTerm}
          selectedValue={currentReplaceElementId}
          handleChange={(elementId) => loadInstalledElement(locationId, elementId)}
          handleSearchChange={searchTemplate}
          selectOptions={installedElementsToOptionsArray()}
          showOverlay={installedElementsLoading}
        />
      </div>
    </div>

  )
}