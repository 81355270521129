import * as React from 'react'
import { useState } from 'react'
import { addYears, subYears } from 'date-fns'
// import DatePicker from 'react-datepicker'
import AddressField from './address_field.js'

// OrderMeta.defaultProps = {
//   deliveryDate: '',
//   deliveryAttention: '',
//   orderComment: '',
//   orderAddress: {},
// }
type AddressType = {
  id?: number
  line2: string
  line1: string
  zip: string
  city: string
  country: string
}
type OrderMetaProps = {
  inputName: (name: string) => string
  deliveryDate: string
  deliveryAttention: string
  deliveryPhoneNumber: string
  deliveryInfo: string
  orderComment: string
  availableCountries: string[]
  orderAddress: AddressType
  locationAddress: AddressType
}

function OrderMeta(props: OrderMetaProps) {
  const {
    inputName,
    deliveryDate,
    deliveryAttention,
    deliveryPhoneNumber,
    deliveryInfo,
    orderComment,
    orderAddress,
    locationAddress,
    availableCountries,
  } = props
  const { I18n } = window as any
  const [date, setDate] = useState(deliveryDate || '')
  const [attention, setAttention] = useState(deliveryAttention || '')
  const [addressSameAsLocation, setAddressSameAsLocaiton] = useState(!orderAddress?.id)
  const [phoneNumber, setPhoneNumber] = useState(deliveryPhoneNumber || '')
  const [info, setInfo] = useState(deliveryInfo || '')
  const [address, setAddress] = useState(orderAddress || {})
  const [comment, setComment] = useState(orderComment || '')

  const addressInputName = (field) => `elements_order[address_attributes][${field}]`
  const addressInputValue = (field) =>
    (addressSameAsLocation ? locationAddress && locationAddress[field] : address && address[field]) || ''

  return (
    <div className="mb-3 default-form-left-labeled order-meta">
      <div className="form-group">
        <label className="label" htmlFor={inputName('delivery_date')}>
          {I18n.t('elements.order_form.delivery_date')}
        </label>
        <div className="default-form-input-wrapper">
          <input
            type="date"
            className="form-control"
            name={inputName('delivery_date')}
            value={date}
            min={subYears(new Date(), 100).toISOString().slice(0, 10)}
            max={addYears(new Date(), 2).toISOString().slice(0, 10)}
            required
            onChange={(e) => setDate(e.target.value)}
          />
          {/* <DatePicker */}
          {/*   className="form-control" */}
          {/*   name={inputName('delivery_date')} */}
          {/*   selected={date} */}
          {/*   dateFormat="yyyy-MM-dd" */}
          {/*   minDate={subYears(new Date(), 100)} */}
          {/*   maxDate={addYears(new Date(), 2)} */}
          {/*   isClearable */}
          {/*   showMonthDropdown */}
          {/*   showYearDropdown */}
          {/*   required */}
          {/*   onChange={(newDate) => setDate(newDate)} */}
          {/* /> */}
        </div>
      </div>

      <div className="form-group">
        <label className="label">{I18n.t('elements.order_form.delivery_attention')}</label>
        <div className="default-form-input-wrapper">
          <input
            className="form-control"
            type="text"
            name={inputName('delivery_attention')}
            value={attention}
            onChange={(e) => setAttention(e.target.value)}
          />
        </div>
      </div>

      <div className="form-group">
        <label className="label" htmlFor="order-form-delivery-phone-number">
          {I18n.t('elements.order_form.delivery_phone_number')}
        </label>
        <div className="default-form-input-wrapper">
          <input
            id="order-form-delivery-phone-number"
            className="form-control"
            type="text"
            name={inputName('delivery_phone_number')}
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </div>
      </div>

      <div className="form-group">
        <label className="label" htmlFor="order-form-delivery-info">
          {I18n.t('elements.order_form.delivery_info')}
        </label>
        <div className="default-form-input-wrapper">
          <input
            id="order-form-delivery-info"
            className="form-control"
            type="text"
            name={inputName('delivery_info')}
            value={info}
            onChange={(e) => setInfo(e.target.value)}
          />
        </div>
      </div>

      <div className="form-group">
        <label className="label">{I18n.t('elements.order_form.comment')}</label>
        <div className="default-form-input-wrapper">
          <textarea
            className="form-control"
            type="text"
            name={inputName('comment')}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </div>
      </div>

      <div className="form-group">
        <h4 className="form-offset-right mt-4">{I18n.t('locations.delivery_address')}</h4>
      </div>

      <div className="form-group">
        <div className="form-check">
          <label className="form-check-label">
            <input
              type="checkbox"
              className="form-check-input"
              name={inputName('delivery_address_the_same_as_location')}
              checked={addressSameAsLocation === true}
              onChange={(e) => setAddressSameAsLocaiton(e.target.checked)}
            />
            {address && address.id && <input type="hidden" name={addressInputName('id')} value={address.id} />}
            {addressSameAsLocation && address && address.id && (
              <input type="hidden" name={addressInputName('_destroy')} value="1" />
            )}
            {I18n.t('elements.order_form.delivery_address_the_same_as_location')}
          </label>
        </div>
      </div>

      {['line2', 'line1', 'zip', 'city'].map((field) => (
        <AddressField
          key={field}
          field={field}
          disabled={addressSameAsLocation}
          name={addressInputName(field)}
          value={addressInputValue(field)}
          onChange={(e) => setAddress({ ...address, [field]: e.target.value })}
        />
      ))}
      <div className="form-group">
        <label className="label">{I18n.t('activerecord.attributes.address.country')}</label>
        <div className="default-form-input-wrapper">
          <select
            className="form-control"
            type="text"
            disabled={addressSameAsLocation}
            name={addressInputName('country')}
            value={addressInputValue('country')}
            onChange={(e) => setAddress({ ...address, country: e.target.value })}
          >
            {availableCountries.map((o) => (
              <option value={o[1]} key={o[1]}>
                {o[0]}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  )
}

export default OrderMeta
