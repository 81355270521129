import { valueBasedOnMinMaxIsValid, hideErrorMsg, showErrorMsg } from './helpers/min_max_validator'
import ChangesMonitor from './helpers/changes_monitor'
import SaveAndGoToOverviewBtnHandler from './helpers/save_and_go_to_overview_btn_handler'

const $ = window.$

export default function () {
  ChangesMonitor(true)
  SaveAndGoToOverviewBtnHandler()
  
  $('li#save_with_step_action input[type=submit]').on('click', (e) => {
    e.preventDefault()
    const $form = $(e.currentTarget).closest('form')
    $("<input type='hidden' name='step_action' value='only_save_on_summary' />").appendTo($form)
    $form.submit()
  })

  const $amountInputs = $('td.amount-cell input[type=number]')
  $amountInputs.on('keyup change input checkMinMax', (e) => {
    const $inp = $(e.currentTarget)
    const min = parseInt($inp.prop('min')), max = parseInt($inp.prop('max')),
      val = parseInt($inp.val())
    if (valueBasedOnMinMaxIsValid(val, min, max)) { hideErrorMsg($inp) }
    else { showErrorMsg($inp, min, max) }
  })

  $amountInputs.trigger('checkMinMax')
}
