import { Component } from 'react'
import store from 'store'
import { Provider } from 'react-redux'
import { loadProject } from 'actions/project_actions'
import AttachPhotoToElementModal from './attach_photo_to_element_modal'

export default class Project extends Component {
  componentDidMount () {
    store.dispatch(loadProject(this.props))
  }

  render () {
    return (
      <Provider store={store}>
        <AttachPhotoToElementModal />
      </Provider>
    )
  }
}
