import React from 'react'
import store from 'store'
import { Provider } from 'react-redux'
import { initAlbums } from 'actions/uploader_actions'
import Uploader from './uploader'
import FavoriteAlbum from './favorite_album'

const Uploaders = ({ ids }) => {
  return (
    <>
      { ids.map((buttonId, index) => (
        <Uploader key={`${buttonId}-{index}`} buttonId={buttonId} />
      ))}
    </>
  )
}

const AlbumsApp = (props) => {
  const { uploaderIds, uploaders, favAlbumTitle } = props

  store.dispatch(initAlbums(uploaders))

  return (
    <Provider store={store}>
      <FavoriteAlbum uploaderIds={uploaderIds} title={favAlbumTitle} />
      <Uploaders ids={uploaderIds} />
    </Provider>
  )
}

export default AlbumsApp
