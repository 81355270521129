import { Component } from 'react'
import store from 'store'
import { Provider } from 'react-redux'
import List from './list'

export default class App extends Component {
  render () {
    return (
      <Provider store={store}>
        <List {...this.props} />
      </Provider>
    )
  }
}
