const I18n = window.I18n

const CreateButton = ({onClick}) => {
  return (
    <a className="btn btn-secondary" href='javascript:void(0)' onClick={onClick}>
      {I18n.t("albums.actions.create")}
    </a>
  )
}

export default CreateButton