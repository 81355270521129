import React, { useState } from 'react'
import PropTypes from 'prop-types'
import store from 'store'
import { Provider } from 'react-redux'
import { Button } from 'reactstrap'
import ModalWindow from './modal_window'

function SendLinksButtonApp({
  buttonTitle,
  windowTitle,
  projectsUrl,
  actionUrl,
  projectGroupId,
}) {
  const [modalFormIsActive, setModalFormIsActive] = useState(false)
  const toggleModal = () => setModalFormIsActive(!modalFormIsActive)

  return (
    <Provider store={store}>
      <Button className="btn btn-secondary" onClick={toggleModal}>
        {buttonTitle}
      </Button>
      {modalFormIsActive && <ModalWindow
        isOpen={modalFormIsActive}
        toggleModal={toggleModal}
        windowTitle={windowTitle}
        buttonTitle={buttonTitle}
        actionUrl={actionUrl}
        projectGroupId={projectGroupId}
        projectsUrl={projectsUrl}
      />}
    </Provider>
  )
}

SendLinksButtonApp.propTypes = {
  buttonTitle: PropTypes.string.isRequired,
  windowTitle: PropTypes.string.isRequired,
  actionUrl: PropTypes.string.isRequired,
  projectGroupId: PropTypes.number.isRequired,
  projectsUrl: PropTypes.string.isRequired,
}

export default SendLinksButtonApp
