import * as React from 'react'

type TotalPriceProps = {
  total: number
}

const PRICE_CURRENCY = 'DKK'

export default function TotalPrice(props: TotalPriceProps) {
  const { total } = props
  const fixedTotal = total.toFixed(2)
  return (
    <span className="total-price">
      {fixedTotal} {PRICE_CURRENCY}
    </span>
  )
}
