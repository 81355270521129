import React from 'react'
import PropTypes from 'prop-types'
import SubmitButton from './submit_button'

const { I18n } = window

class Actions extends React.Component {
  static propTypes = {
    canEditOrder: PropTypes.bool.isRequired,
    canSendOrder: PropTypes.bool.isRequired,
    canApproveOrder: PropTypes.bool.isRequired,
    canCancelOrder: PropTypes.bool.isRequired,
    inputName: PropTypes.func.isRequired,
    enableValidation: PropTypes.func.isRequired,
    disableValidation: PropTypes.func.isRequired,
  }

  handleApprove (e) {
    this.props.disableValidation()
  }

  handleSave (e) {
    this.props.disableValidation()
  }

  handleSend (e) {
    this.props.enableValidation()
  }

  handleCancel (e) {
    this.props.disableValidation()
    if (!window.confirm(I18n.t('elements.actions.cancel_order_confirmation'))) {
      e.preventDefault()
    }
  }

  render () {
    const {
      canCancelOrder, canEditOrder, canSendOrder, canApproveOrder, inputName,
    } = this.props

    return (
      <div className="order-elements-form-actions">
        <SubmitButton
          enabled={canCancelOrder}
          className="send-order btn btn-secondary"
          name={inputName('status')}
          onClick={e => this.handleCancel(e)}
          value="cancelled"
          content={I18n.t('elements.actions.cancel_order')}
        />

        <SubmitButton
          enabled={canSendOrder}
          className="send-order btn btn-secondary"
          name={inputName('status')}
          onClick={e => this.handleSend(e)}
          value="approved"
          content={I18n.t('elements.actions.send_order')}
        />

        <SubmitButton
          enabled={canApproveOrder}
          className="approve-order btn btn-secondary"
          onClick={e => this.handleApprove(e)}
          name={inputName('status')}
          value="approved"
          content={I18n.t('elements.actions.approve_order')}
        />

        <SubmitButton
          enabled={canEditOrder}
          onClick={e => this.handleSave(e)}
          className="btn btn-primary"
          name="commit"
          content={I18n.t('elements.actions.save_order')}
        />
      </div>
    )
  }
}

export default Actions
