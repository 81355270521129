import DataTableHelpers from 'libs/datatable_helpers'
import store from 'store'
import { select, unselect, init } from 'actions/overview_actions'
import queryString from 'query-string'
import { sendExportRequest } from './helpers'
import pageDataManager from './project_groups/page_data_manager'

DataTableHelpers.destroyBeforeCaching()

const $table = $('#project-groups-table')
const options = {
  pageLength: 20,
  bStateSave: false,
  info: false,
  dom: 't<"bottom"p>',
  autoWidth: false,
  stateSave: true,
  order: [[1, 'asc']]
}

pageDataManager.sync()

export default function () {
  const table = $table.dataTable(options).on('draw.dt', function () {
    pageDataManager.sync()
  })
  DataTableHelpers.initSearch(table, $('input.search'))

  $(() => {
    $('#js-show-empty-project-groups').on('change', (e) => {
      const { checked } = e.target
      const fullUrl = queryString.parseUrl(window.location.href)
      let url = fullUrl['url']
      if (checked) {
        url = `${url}?show_empty_project_groups=true`
      }
      window.location.href = url
    })

    $('a.export').on('click', (e) => {
      e.preventDefault()

    let companyIds = []
    table.api().rows({ search: 'applied' }).nodes().each((item) => {
        companyIds.push($(item).data('company-id'))
      })

      const $link = $(e.currentTarget)
      const data = {
        overview: $link.data('overview'),
        overview_params: { type: $link.data('type'), company_ids: [...(new Set(companyIds))] }
      }
      sendExportRequest($link.prop('href'), data)
    })
    pageDataManager.sync()
    if (pageDataManager.initRequired()) { store.dispatch(init(pageDataManager.ids())) }

    $('#project-groups-table').on('change', '.overview-project-group-checkbox', (e) => {
      e.preventDefault()
      const itemId = $(e.currentTarget).data('id')
      const $itemRow = $(e.currentTarget).parents('tr')
      const itemDataManager = pageDataManager.getBy(i => $itemRow.hasClass(i.itemClass))
      // handle click on checkbox
      if (e.currentTarget.checked) {
        itemDataManager.addToLocalStore(itemId)
        store.dispatch(select(itemDataManager.type, [itemId], pageDataManager.ids()))
      } else {
        itemDataManager.rmFromLocalStore(itemId)
        store.dispatch(init(pageDataManager.ids()))
      }
    })
  })
}
