import { Provider } from 'react-redux'
import * as React from 'react'
import store from '../../../store'
import AnswerForm from './answer_form'

export default function SurveyAnswerFormApp(props) {
  return (
    <Provider store={store}>
      <AnswerForm {...props} />
    </Provider>
  )
}
