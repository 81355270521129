import React from 'react'
import store from 'stores/button_group_store'

if (window.stores === undefined) {
  window.stores = {}
}
// make store available in coffescript
window.stores.button_group_store = store

class ButtonGroup extends React.Component {

  constructor (props) {
    super(props)
    if (this.props.activeIds) {
      this.props.buttons.reduce((result, button) => {
        if (this.props.activeIds.includes(button[1])) { result.push(button[1]) }
        return result
      }, []).forEach(id => store.toggle(id, this.props.groupId))
    }
    this.listener = store.addListener('change', () => this.forceUpdate())
  }

  componentWillUnmount () {
    this.listener.remove()
  }

  handleClick (e, buttonId) {
    e.preventDefault()
    e.stopPropagation()
    store.toggle(buttonId, this.props.groupId)
  }

  render () {
    const buttons = this.props.buttons.map( (button) => {
      const [ buttonName, buttonId ] = button
      const activeClass = store.isActive(buttonId, this.props.groupId) ? 'active' : ''
      return(
        <button className={`btn btn-secondary ${activeClass}`} key={ buttonId } onClick={ (e) => this.handleClick(e, buttonId)}>
          { buttonName }
        </button>
      )
    })

    return (
      <div className="btn-group" aria-label="Basic example">
        { buttons }
      </div>
    )
  }
}

export default ButtonGroup
