import { useState , useEffect } from 'react'
import store from 'stores/campaign_summary_amounts_store'
import { ERROR_TYPE_TO_ICON_MSG }  from 'components/campaigns/summary/config'

const ErrorIconMsg = ({ type, count }) => {
  if (!count) { return null }

  const ErrorIconMsgView = ERROR_TYPE_TO_ICON_MSG[type]

  return (
    <div className="error-icon-msg-view">
      <ErrorIconMsgView count={count} type={type} />
    </div>
  )
}

const AmountsMessagesArea = ({}) => {

  const [errorsCounts, updateErrorsCounts] = useState({ MinMax: 0, TagsAmountsConflicts: 0, TagsAmountsDuplicates: 0 })

  useEffect(() => {
    const listener = store.addListener('UpdateViewAmountMessagesArea',  (errors) => {
      const { minMax, tagsAmountsDuplicates, tagsAmountsConflicts } = errors
      updateErrorsCounts({
        MinMax: minMax.length,
        TagsAmountsDuplicates: tagsAmountsDuplicates.length,
        TagsAmountsConflicts: tagsAmountsConflicts.length
      })
    })
    return () => listener.remove()
  }, [])

  return (
    <div className="errors">
      { Object.keys(errorsCounts).map(type => <ErrorIconMsg key={type} type={type} count={errorsCounts[type]} />) }
    </div>
  )
}

export default AmountsMessagesArea
