import * as React from 'react'
import { Button, Form, Input, Label } from 'reactstrap'
import SelectedLocationsInfo from './selected_locations_info'

const { I18n, $ } = window as any

type SendLinksFormProps = {
  actionUrl: string
  toggleModal: () => void
  selectedProjects: number[]
  setCurrentStep: (step: number) => void
  availableProjects: any[]
}

function SendLinksForm(props: SendLinksFormProps) {
  const { actionUrl, toggleModal, selectedProjects, setCurrentStep, availableProjects } = props
  const token = $('meta[name=csrf-token]').attr('content')

  const defaultSubject = I18n.t('project_groups.send_links.default_subject')
  const defaultMessage = I18n.t('project_groups.send_links.default_message')

  return (
    <div className="col">
      <Form action={actionUrl} method="POST">
        <input type="hidden" name="authenticity_token" value={token} />
        {selectedProjects.map((projectId) => (
          <input key={projectId} type="hidden" name="project_ids[]" value={projectId} />
        ))}
        <div className="input form-group optional">
          <label>{I18n.t('project_groups.send_links.subject')}</label>
          <Input name="subject" defaultValue={defaultSubject} />
        </div>
        <div className="input form-group optional">
          <label>{I18n.t('project_groups.send_links.message')}</label>
          <Input type="textarea" name="message" defaultValue={defaultMessage} />
        </div>
        <div className="input form-group optional">
          <label>{I18n.t('project_groups.send_links.attach_login_link.label')}</label>
          <div className="input form-check">
            <Label>
              <Input name="link_type" className="mr-1" type="radio" value="survey" defaultChecked={true} />
              {I18n.t('project_groups.send_links.attach_login_link.to_survey')}
            </Label>
          </div>
          <div className="input form-check">
            <Label>
              <Input name="link_type" className="mr-1" value="project" type="radio" />
              {I18n.t('project_groups.send_links.attach_login_link.to_project')}
            </Label>
          </div>
          <div className="input form-check">
            <Label>
              <Input name="link_type" className="mr-1" type="radio" value="do_not_attach" />
              {I18n.t('project_groups.send_links.attach_login_link.do_not_attach')}
            </Label>
          </div>
        </div>
        <hr />
        <SelectedLocationsInfo selectedProjects={selectedProjects} availableProjects={availableProjects} />
        <hr />
        <div className="d-flex justify-content-end">
          <Button color="secondary" type="button" onClick={toggleModal}>
            {I18n.t('application.actions.cancel')}
          </Button>
          <Button className="ml-2" color="secondary" type="button" onClick={() => setCurrentStep(0)}>
            {I18n.t('project_groups.actions.go_back')}
          </Button>
          <Button
            className="ml-2"
            color="primary"
            type="submit"
            onClick={(e) => {
              if (!confirm(I18n.t('project_groups.send_links.submit_confirmation'))) {
                e.preventDefault()
              }
            }}
          >
            {I18n.t('application.actions.send')}
          </Button>
        </div>
      </Form>
    </div>
  )
}

export default SendLinksForm
