import { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export default class StatusChange extends Component {
  static propTypes = {
    statusChange: PropTypes.object.isRequired,
  }

  onClick = () => {
    this.props.assignChange(this.props.statusChange)
  }

  render () {
    const { statusChange } = this.props
    return (
      <tr className="overview-status-summary-status-change-list-status-change-container">
        <td className="overview-status-summary-status-change-list-status-change-icon">
          <i className="fa fa-arrow-circle-right" onClick={this.onClick} />
        </td>
        <td className={classNames('overview-status-summary-status-change-list-status-change-body', 'small')}>
          {`${statusChange.get('old_status')} --> ${statusChange.get('new_status')}`}
        </td>
        <td className={classNames('overview-status-summary-status-change-list-status-change-projectInfo', 'small')}>
          {statusChange.get('location_name')}
        </td>
        <td className={classNames('overview-status-summary-status-change-list-status-change-projectInfo', 'small')}>
          {statusChange.get('company_name')}<br />
          {statusChange.get('project_id')}
        </td>
      </tr>
    )
  }
}
