import { Component } from 'react'
import store from 'store'
import { Provider } from 'react-redux'
import Overview from './overview'

export default class OverviewApp extends Component {
  render () {
    return (
      <Provider store={store}>
        <Overview {...this.props} />
      </Provider>
    )
  }
}
