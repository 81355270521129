export default class RequestPool {
  constructor() {
    this.requests = []
  }
  add(request) {
    this.requests.push(request)
  }
  remove(request) {
    this.requests = _.without(this.requests, request)
  }
  abortAll() {
    _.each(this.requests, request => request.abort())
    this.requests = []
  }
}
