import React, { Component } from 'react'
import PropTypes from 'prop-types'

const { I18n } = window

const ProjectStatusName = ({ name }) => {
  return (
    <div>{I18n.t(`activerecord.enums.project_status.name.${name}`)}</div>
  )
}

export default class Status extends Component {
  static propTypes = {
    status: PropTypes.object.isRequired,
    items: PropTypes.array.isRequired,
  }

  count = () => {
    const statusName = this.props.status.name

    // replace hash actual for mspWorkflow item
    return this.props.items.reduce((sum, item) => {
      const _sumVal = item[statusName.replace('_count', '_sum')]
      return sum + ( _sumVal || _sumVal == 0 ? _sumVal : item[statusName])
    }, 0)
  }

  onClick = () => {
    this.props.assignStatus(this.props.status)
  }

  render () {
    const { status } = this.props
    
    return (
      <div className="overview-status-summary-status-container">
        <div className="overview-status-summary-status-value" style={{ color: status.color }}>{this.count()}</div>
        <div className="overview-status-summary-status-title" onClick={this.onClick}>{status.title()}</div>
        <div className="overview-status-summary-status-desc" style={{ backgroundColor: status.color, color: status.secondaryColor }}>
          {status.included.map(status_item => <ProjectStatusName key={`key-${status_item}`} name={status_item} />)}
        </div>
      </div>
    )
  }
}
