import { useState } from 'react'
import ModalWindow from './modal_window'

const ChangeAmountControl = (props) => {

  const [isOpen, changeOpenState] = useState(false)

  const toggle = () => changeOpenState(prevState => !prevState)

  return (
    <>
      <i className="fa fa-sliders change-amount" aria-hidden="true" onClick={toggle}></i>
      <ModalWindow {...props} title={'Select amount for all element items'} isOpen={isOpen} toggle={toggle} />
    </>
  )
}

export default ChangeAmountControl
