import React from 'react'
import SignaturePad from 'signature_pad'
import uuidv4 from 'uuid'

export default class Signature extends React.Component {

  state = { value: '' }

  onChange = (e) => {
    this.setState({ value: this._signature.toDataURL() })
  }


  componentDidMount () {
    const container = window.document.getElementById(this.uniqContinerId)
    if (!container) { return }

    const canvas = container.querySelector("canvas")
    this._signature = new SignaturePad(canvas, {
      onEnd: this.onChange
    })
    if (this.props.element?.fieldAnswer) {
      this._signature.fromDataURL(this.props.element.fieldAnswer)
    }
  }

  get uniqContinerId () { return `signature_for_${this.props.element?.id || uuidv4()}` }

  render () {
    const { caption, id } = this.props.element
    const mode = this.props.mode

    if (this.props.mode.isReadOnly()) {
      return (
        <div className="signature-pad">
          <img src={this.props.element.fieldAnswer} />
        </div>
      )
    }

    return (
      <div className="form-group signature-pad">
        <label className="label">{caption}</label>
        <div className="default-form-input-wrapper">
          <div id={this.uniqContinerId}>
            <canvas />
            <input type="hidden" name="fields_answers_attributes[][value]" value={this.state.value} />
            <input type="hidden" name="fields_answers_attributes[][field_id]" value={id} />
          </div>
        </div>
      </div>
    )
  }
}
