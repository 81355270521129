import store from 'store'
import { Provider } from 'react-redux'
import ProjectsChart from './projects_chart'

export default function ProjectsChartApp (props) {
  return (
    <Provider store={store}>
      <ProjectsChart {...props} />
    </Provider>
  )
}
