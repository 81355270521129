import React, { Fragment } from 'react'
import StackedBarGraph from './stacked_bar_graph'

class MspWorkflowsGraphs extends React.Component {

  render () {
    const { mspWorkflows, getProjects, assignMspWorkflow } = this.props

    return (
      <Fragment>
        { mspWorkflows.map((mspWorkflow) =>
            <StackedBarGraph key={`w${mspWorkflow.id}`} workflow={mspWorkflow} getProjects={getProjects} assignMspWorkflow={assignMspWorkflow} />
          )
        }
      </Fragment>
    )
  }
}

export default MspWorkflowsGraphs
