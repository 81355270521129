class RoleDependentUserFields {
  constructor(typeSelect, container) {
    this.typeSelect = typeSelect
    this.container  = container

    this.locationInput  = container.find('.js-location')
    this.regionsInput   = container.find('.js-regions')
    this.districtIsnput = container.find('.js-districts')
    this.userTagsIsnput = container.find('.js-user-tags')

    this.bindSelectChange()
  }

  detachAll() {
    ReactRailsUJS.unmountComponents($(this.container).get(0))
    this.locationInput.detach()
    this.regionsInput.detach()
    this.districtIsnput.detach()
    this.userTagsIsnput.detach()
  }

  bindSelectChange() {
    this.typeSelect.on('change', (e) => {
      this.detachAll()

      switch (e.target.value) {
        case 'Users::LocationManager':
          this.locationInput.appendTo(this.container)
          ReactRailsUJS.mountComponents($(this.container).get(0))
          break
        case 'Users::RegionManager':
          this.regionsInput.appendTo(this.container)
          ReactRailsUJS.mountComponents($(this.container).get(0))
          break
        case 'Users::DistrictManager':
          this.districtIsnput.appendTo(this.container)
          ReactRailsUJS.mountComponents($(this.container).get(0))
          break
        case 'Users::BrandOwner':
          this.userTagsIsnput.appendTo(this.container)
          ReactRailsUJS.mountComponents($(this.container).get(0))
          break
      }
    })
  }
}

export default function () {
  $(function() {
    const typeSelect = $('.js-user-type')
    const container = $('.js-user-role-dependent-fields')

    new RoleDependentUserFields(typeSelect, container)
    typeSelect.change()
  })
}
