import * as React from 'react'
import { SavedFiltersControllerType } from './use_saved_filters_controller'
import FilterSelect from './filter_select'
import { ProjectReportFilterDefinitionType } from './types'
// import SavedFilters from '../job_plannings/filter/SavedFilters'
import Select from '../../../shared/base_react_select'
import SavedFiltersControls from './saved_filters_controls'
import ShowCompletedCheckbox from './show_completed_checkbox'

type SavedFilterFormProps = {
  availableFilters: ProjectReportFilterDefinitionType[]
  savedFiltersController: SavedFiltersControllerType
  closeModal: () => void
  showCompletedLabel: string
  initialAvailableFilters: ProjectReportFilterDefinitionType[]
  completedStatusId: string
}

export default function SavedFilterForm(props: SavedFilterFormProps) {
  const {
    savedFiltersController,
    closeModal,
    availableFilters,
    initialAvailableFilters,
    completedStatusId,
    showCompletedLabel,
  } = props

  const { formFilter, setFormFilter } = savedFiltersController

  return (
    <div className="projects-report-filter-modal-content popup-content">
      {availableFilters.map((filter) => (
        <div className="row" key={filter.name}>
          <div className="form-group col">
            <label htmlFor={`projects-report-filter-${filter.name}`}>{filter.label}</label>
            <Select
              id={`projects-report-filter-${filter.name}`}
              value={formFilter[filter.name]}
              options={filter.options}
              isMulti
              onChange={(selected) => {
                setFormFilter({
                  ...formFilter,
                  [filter.name]: selected,
                })
              }}
            />
          </div>
        </div>
      ))}
      <div className="row">
        <div className="form-group col">
          <ShowCompletedCheckbox
            showCompletedLabel={showCompletedLabel}
            selectedFilters={formFilter}
            setSelectedFilters={setFormFilter}
            initialAvailableFilters={initialAvailableFilters}
            completedStatusId={completedStatusId}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col">
          <hr />
          <SavedFiltersControls savedFiltersController={savedFiltersController} closeModal={closeModal} />
        </div>
      </div>
    </div>
  )
}
