import * as React from 'react'
import { Provider } from 'react-redux'
import store from '../../../../store'
import ProjectsReportFilters from './projects_report_filters'
import { ProjectReportFilterDefinitionType, SavedFilterType } from './types'

type ProjectsReportFiltersAppProps = {
  initialAvailableFilters: ProjectReportFilterDefinitionType[]
  initialSavedFilters: SavedFilterType[]
  completedStatusId: string
  showCompletedLabel: string
}

export default function ProjectsReportFiltersApp(props: ProjectsReportFiltersAppProps) {
  const { initialAvailableFilters, completedStatusId, showCompletedLabel, initialSavedFilters} = props
  return (
    <Provider store={store}>
      <ProjectsReportFilters
        initialAvailableFilters={initialAvailableFilters}
        completedStatusId={completedStatusId}
        showCompletedLabel={showCompletedLabel}
        initialSavedFilters={initialSavedFilters}
      />
    </Provider>
  )
}
