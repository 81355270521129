import { RadioButtonCollection } from '../service_parts'

const I18n = window.I18n

const getCaptionTrans = (key) => I18n.t(`handover_forms.cells_settings.text_aligment.options.${key}`)

const COLLECTION = [
  { caption: getCaptionTrans('center'),  value: 'text-center' },
  { caption: getCaptionTrans('left'),    value: 'text-left' },
  { caption: getCaptionTrans('right'),   value: 'text-right' },
  { caption: getCaptionTrans('justify'), value: 'text-justify' }
]

export default function ({ name, onChange, value }) {

  const isChecked = (val) => val == value

  return (
    <div className="form-group">
      <label className="label">{I18n.t('handover_forms.cells_settings.text_aligment.caption')}</label>
      <div className="default-form-input-wrapper">
        <RadioButtonCollection name={name} collection={COLLECTION} onChange={onChange} isChecked={isChecked} />
      </div>
    </div>
  )
}
