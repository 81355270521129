import { connect } from 'react-redux'
import {
  createMspProject, loadStepsForMspProject, assignStep
} from 'actions/projects/msp_step_button_actions'

export default connect((state) => ({
  current: state.getIn(['projects', 'mspData', 'current']),
  projects: state.getIn(['projects', 'mspData', 'projects']),
  workflows: state.getIn(['projects', 'mspData', 'workflows']),
  steps: state.getIn(['projects', 'mspData', 'steps'])
}), { createMspProject, loadStepsForMspProject, assignStep })
