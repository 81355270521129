import connect from './connect'
import TagWithAmountInput from './tag_with_amount_input'


const TagsWithAmountsRow = ({ allTags, item, visible, setTagAmount }) => {
  if (!visible) { return null }

  const tags = allTags.filter(tag => item.tagIDs.includes(tag.value) )

  const changeTagAmount = (tagId, val) => setTagAmount(item, tagId, val)

  return (
    <tr className="tags-with-amounts">
      <td colSpan="8">
        <div className="row">
          { tags.map(tag =>
            <TagWithAmountInput
              key={`tag-with-amount-${tag.value}`}
              tag={tag}
              min={item.minAmount}
              max={item.maxAmount}
              amount={item.tagsAmounts[tag.value] || ''}
              changeTagAmount={changeTagAmount}
            />
          )}
        </div>
      </td>
    </tr>
  )
}

export default connect(TagsWithAmountsRow)
