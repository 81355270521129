import React from 'react'
import store from 'store'
import { Provider } from 'react-redux'
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'

import connect from './connect'
import { initForm } from 'actions/form_builder_actions'

import Builder from './builder'
const BuilderWithData = connect(Builder)

import FinalForm from './final_form'
const FinalFormWithData = connect(FinalForm)

class BuilderApp extends React.Component {

  constructor (props) {
    super(props)
    store.dispatch(initForm(this.props))
  }

  render () {
    return (
        <DndProvider backend={HTML5Backend}>
          <Provider store={store}>
            <BuilderWithData />
          </Provider>
        </DndProvider>
    )
  }
}

export default {
  BuilderApp,
  FinalForm
}
