import React from 'react'
import store from 'store'
import { Provider } from 'react-redux'
import LocationsSelector from './locations_selector'
import { initBoxes } from 'actions/campaigns/locations_selector_actions'
import { selectByTags } from 'actions/campaigns/common_actions'

export default class LocationsSelectorApp extends React.Component {

  constructor (props) {
    super(props)
    store.dispatch(initBoxes({
      id: this.props.id,
      step: this.props.step,
      locations: this.props.locations,
      tags: this.props.tags,
      regions: this.props.regions,
      isReady: this.props.isReady,
    }))

    if (this.props.selectedTags.length) {
      store.dispatch(selectByTags(this.props.selectedTags, 'locations', true))
    }
  }

  render () {
    return (
      <Provider store={store}>
        <LocationsSelector />
      </Provider>
    )
  }
}
