import * as React from 'react'
import { WidgetFactoryInterface } from './survey_answer_form'
import Radio from './widgets/radio'
import Label from './widgets/label'
import String from './widgets/string'
import Number from './widgets/number'
import Text from './widgets/text'
import Boolean from './widgets/boolean'
import CheckBoxes from './widgets/check_boxes'
import Select from './widgets/select'
import WorkingHours from "./widgets/working_hours"
import AddressFields from "./widgets/address_fields"

export default function WidgetFactory(props: WidgetFactoryInterface) {
  const { field } = props
  if (field.type === 'Surveys::Fields::TextLabel') {
    return <Label {...props} />
  }
  switch (field.widget) {
    case 'radio':
      return <Radio {...props} />
    case 'string':
      return <String {...props} />
    case 'number':
      return <Number {...props} />
    case 'text':
      return <Text {...props} />
    case 'boolean':
      return <Boolean {...props} />
    case 'check_boxes':
      return <CheckBoxes {...props} />
    case 'select':
      return <Select {...props} />
    case 'working_hours':
      return <WorkingHours {...props} />
    case 'address_fields':
      return <AddressFields {...props} />
    default:
      return <div className="error">Unknown widget type: {field.widget}</div>
  }
}
