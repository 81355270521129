import React from 'react'
import classNames from 'classnames'

import ItemTypes from '../lib/item_types'

export default (({ element, path, selectedItem, onClickGear, onClickDelete }) => {
  const index = path.slice(-1)
  const name = element.label || element.caption || element.type

  const elementClasses = ['element-item']
  if (element == selectedItem) { elementClasses.push('selected') }

  return (
    <div className={classNames(elementClasses)}>
      {name}
      <div className="controls">
        <i className="fa fa-cog" onClick={() => onClickGear(ItemTypes.ELEMENT.type, path)}></i>
        <i className="fa fa-trash" onClick={() => onClickDelete(path) }></i>
      </div>
    </div>
  )
})
