import React from 'react'
import Toggle from 'react-toggle'
import "react-toggle/style.css"

const ajax = window.ajax
const I18n = window.I18n

function CheckedToggleButton (props) {
  return (
    <div>
      <Toggle
        checked
        {...props}
      />
      <span className="toggle-btn-text">{I18n.t('campaigns.documents.state.approved')}</span>
    </div>
  )
}

function UnCheckedToggleButton (props) {
  return (
    <div>
      <Toggle
        checked={false}
        {...props}
      />
      <span className="toggle-btn-text">{I18n.t('campaigns.documents.state.not_set')}</span>
    </div>
  )
}

export default class ToggleButton extends React.Component {

  constructor (props) {
    super(props)
    this.state = { [this.props.name]: this.props.checked }
  }

  isChecked = () => this.state[this.props.name]

  getToggleViewBasedOnState = () => {
    if (this.isChecked()) {
      return CheckedToggleButton
    }
    else return UnCheckedToggleButton
  }

  onChange = () => {
    const data = { format: 'json' }
    data[this.props.name] = !this.isChecked()
    ajax({
      url: this.props.url,
      method: 'patch',
      data: data
    }).then( (resp) => {
      const newState = { }
      newState[this.props.name] = resp.data.checked
      this.setState(newState)
    })
  }

  render () {
    const ToggleView = this.getToggleViewBasedOnState()
    return (
      <div className='toggle-button-container'>
        <ToggleView onChange={this.onChange} className="toggle-button-control" />
      </div>
    )
  }
}
