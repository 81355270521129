import Spinner from './spinner'

export default class CustomDatatable {
  defaultOptions() {
    // need to override classes to remove container-fluid (added by datatables.bootstrap4)
    let wrapperClasses = $.fn.DataTable.ext.classes.sWrapper
    wrapperClasses = wrapperClasses.replace('container-fluid', '')

    const spinner = new Spinner
    const $headerTitle = $('h1.content-header-title')

    const options = {
      paging: false,
      info: false,
      dom: 't',
      autoWidth: false,
      stateSave: true,
      classes: { sWrapper: wrapperClasses },
      language: {
        zeroRecords: I18n.t('application.empty_list'),
        decimal: I18n.t('number.format.separator')
      },
      columnDefs: [
        { targets: 'js-dt-disable-sorting', orderable: false },
        { targets: 'js-dt-actions', orderable: false },
        { targets: 'photos', orderable: false },
        { targets: 'js-dt-amount', orderDataType: 'dom-text-input' },
        { targets: 'js-dt-extra-amount', orderDataType: 'dom-text-input' }
      ],
      order: [[1, 'asc']], // suitable both for locations#index table and for elements table
      drawCallback(ops) {
        return $(spinner.selectors(), $headerTitle).remove();
      },
      preDrawCallback(ops) {
        if (!spinner.presentIn($headerTitle)) { return $headerTitle.append(spinner.html()) }
      }
    }
    return options
  }
}
