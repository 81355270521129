import React from 'react'
import ItemTypes from '../../lib/item_types'

const I18n = window.I18n

export default class SubmitBtnSettings extends React.Component {

  onChange = (e) => {
    const { path, handlers, item } = this.props
    handlers.updateItemAttr(e, item, ItemTypes.ELEMENT.type, path)
  }

  render () {
    const { caption } = this.props.item

    return (
      <div className="submit-btn-settings">
        <div className="form-group">
          <label className="label">{I18n.t('application.titles.caption')}</label>
          <input type="text" className="form-control" name="caption" value={caption || ''} onChange={this. onChange} />
        </div>
      </div>
    )
  }

}
