import ListSearch from 'libs/list_search'

export default function () {
  $(function() {
    const list = $('.main .list-group .list-group-item')
    const options = [{
      searchField: $('.js-project-search-vm'),
      searchSelectors: ['.title', '.description .location'],
      type: 'input',
      name: 'common_search_input',
    }, {
      searchField: $('.search-wrapper.assigned-to-me input'),
      searchSelectors: ['.my-project'],
      type: 'checkbox',
      searchTerm: 'true',
      name: 'assigned_to_me',
    }]
    new ListSearch(list, options)
  })

}
