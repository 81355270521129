import { connect } from 'react-redux'
import { initForm, saveForm, undoChange, redoChange, moveRowItem, addRowItem, updateRowItem, deleteRowItem,
  updateCellItem, updateElementItem, deleteElementItem, moveElementItem, updateFormAttrs
} from 'actions/form_builder_actions'

export default connect((state) => ({
  data: state.getIn(['formBuilder', 'present', 'data']).toJS(),
  counters: {
    past: state.getIn(['formBuilder', 'past']).size,
    future: state.getIn(['formBuilder', 'future']).size
  },
  url: state.getIn(['formBuilder', 'present', 'url'])
}), { initForm, saveForm, undoChange, redoChange, moveRowItem, addRowItem, updateRowItem, deleteRowItem,
  updateCellItem, updateElementItem, deleteElementItem, moveElementItem, updateFormAttrs
})
