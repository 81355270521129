import React from 'react'

import Row from './row'
import FormModeContext, { FormMode } from './form_mode_context'

export default class FormContent extends React.Component {

  renderRows = (rows) => {
    return rows.reduce((result, row, index) => {
      if (row._destroy) { return result }
      result.push(<Row key={`row-i-${index}`}
        row={row}
        path={[index]} />
      )
      return result
    }, [])
  }

  render () {
    const rows = this.renderRows(this.props.rows || [])

    return (
      <FormModeContext.Provider value={{ mode: new FormMode(this.props.mode) }}>
        {rows}
      </FormModeContext.Provider>
    )
  }
}
