import * as React from 'react'
import PhotoswipeImageLink from '../../../shared/photoswipe/photoswipe_image_link'
import { SurveyElementShape } from '../survey_answer_form'

export default function ElementWrapper({
  element,
  children,
}: {
  element: SurveyElementShape
  children: React.ReactNode
}) {
  const { image, description } = element
  return (
    <div className={`card mb-3 ${element.type === 'Surveys::Elements::LocationInfoElement' ? 'location-info-element' : ''}`}>
      <div className="card-header">
        <div className="group-title d-flex justify-content-between">{element.name}</div>
      </div>
      <div className="collapse-show pt-3">
        <div className="survey-element-description row">
          <div className="description col mt-2" dangerouslySetInnerHTML={{ __html: description }} />
        </div>
        {image && (
          <div className="image col mt-2">
            <PhotoswipeImageLink {...image} imgSrc={image.src} />
            {image.file_url}
          </div>
        )}
        {children}
      </div>
    </div>
  )
}
