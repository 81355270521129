import * as React from 'react'
import Actions from './actions'

type FormActionsProps = {
  accessControl: {
    canCancelOrder: boolean
    canEditOrder: boolean
    canSendOrder: boolean
    canApproveOrder: boolean
  }
  enableValidation: () => void
  disableValidation: () => void
  inputName: (name: string) => string
}
export default function FormActions(props: FormActionsProps) {
  const { accessControl, enableValidation, disableValidation, inputName } = props
  const { canCancelOrder, canEditOrder, canSendOrder, canApproveOrder } = accessControl

  return (
    <Actions
      canCancelOrder={canCancelOrder}
      canEditOrder={canEditOrder}
      canSendOrder={canSendOrder}
      canApproveOrder={canApproveOrder}
      enableValidation={enableValidation}
      disableValidation={disableValidation}
      inputName={inputName}
    />
  )
}
