import React from 'react'
import { pick } from 'lodash'
import connect from './connect'
import CommonElementSelector from './common_element_selector'
import LocationElementSelector from './location_element_selector'
import Preview from './preview'
import SaveButton from '../common/save_button'
import SaveAndGoButton from '../common/save_and_go_button'
import NextButton from '../common/next_button'
import BackButton from '../common/back_button'
import TagsModalSelector from './tags_modal_selector'
import AddNewElement from './add_new_element'

const I18n = window.I18n

const prepareCampaignData = ({ tags }) => {
  return ({
    element_tag_ids: tags.filter(t => t.get('selected')).map(t => (t.get('value') || t.get('id')))
  })
}

const splitElementsByGroups = (items) => {
  // notSelectedOther key that mean CommonElement and CampaignElement
  return items.reduce((result, item) => {
    if (item.isSelected) { result.allSelectedElements.push(item) }
    else {
      let typedItemKey = item.isLocation ? 'notSelectedLocationElements' : 'notSelectedOtherElements'
      result[typedItemKey].push(item.selectorAttrs())
    }
    return result
  }, { notSelectedLocationElements: [], notSelectedOtherElements: [], allSelectedElements: [] })
}

class ElementsSelector extends React.Component {

  handleSave = (e, stepAction = null, next = null) => {
    const campaignElementsData = this.props.elements.reduce((result, el) => {
      if (el.isSelected || el.isMarkedForDestory) { result.push(el.prepareDataForSend()) }
      return result
    }, [])

    if (campaignElementsData.length > 0) {
      this.props.saveSelected(this.props.id, prepareCampaignData(this.props), campaignElementsData, stepAction, next)
    }
  }

  handleNext = (e) => {
    this.handleSave(e, null, true)
  }

  handleBack = () => {
    this.props.stepBack(this.props.id)
  }

  saveAndGoToOverview = (e) => {
    this.handleSave(e, 'save_and_go_to_overview')
  }

  clickTagsSelectorControl = (item) => {
    this.props.assignActiveItem(item)
  }

  resetActiveItem = () => this.props.assignActiveItem(null)

  createElement = (data) => {
    this.props.createElement(this.props.id, data)
  }

  selectByTags = (tags) => {
    this.props.selectByTags(tags, 'elements')
  }

  render () {
    const { selectItems, isFetching, isReady, tags, ByTagsSelector, elements } = this.props
    const previewImageActions = pick(this.props, [
      'updateItemImageList', 'mergeItemImages', 'addNewImageToItem', 'updateItemImage', 'removeItemImage'
    ])
    const { notSelectedLocationElements, notSelectedOtherElements, allSelectedElements} = splitElementsByGroups(elements)

    return (
      <div className="elements-selector">
        <div className="row mb-3">
          <div className="col-12 text-left">
            <AddNewElement formData={this.props.elementFormData} createElement={this.createElement} isFetching={isFetching} />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-10">
            <CommonElementSelector items={notSelectedOtherElements} selectItems={selectItems} />
          </div>
          <div className="col-2 text-left">
            <button type="button" className="next btn btn-secondary" onClick={() => this.props.addAllElements({isLocation: false}) }>
              {I18n.t('campaigns.others.add_all_elements')}
            </button>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-10">
            <LocationElementSelector items={notSelectedLocationElements} selectItems={selectItems} />
          </div>
          <div className="col-2 text-left">
            <button type="button" className="next btn btn-secondary" onClick={() => this.props.addAllElements({isLocation: true}) }>
              {I18n.t('campaigns.others.add_all_elements')}
            </button>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-10">
            <ByTagsSelector items={tags.toJS()} selectTag={this.selectByTags} />
          </div>
          <div className="col-2 text-right"></div>
        </div>
        <TagsModalSelector item={this.props.activeItem} tags={this.props.tags.toJS()} selectTags={this.props.selectTags} resetActiveItem={this.resetActiveItem} />
        <div className="row">
          <div className="col-12">
            <Preview
              elements={allSelectedElements}
              removeItem={this.props.removeItem}
              updateItem={this.props.updateItem}
              imageActions={previewImageActions}
              makeCopy={this.props.makeCopy}
              clickTagsSelectorControl={this.clickTagsSelectorControl}
              uploaderOptions={this.props.uploaderOptions}
            />
          </div>
        </div>
        <div className="row justify-content-start">
          <div className="col-1">
            <BackButton onClick={this.handleBack} />
          </div>
          <div className="col-0">
            <SaveButton onClick={this.handleSave} />
          </div>
          {isReady && (
            <div className="col-3">
              <SaveAndGoButton onClick={this.saveAndGoToOverview} />
            </div>
          )}
          <div className="col-1">
            <NextButton onClick={this.handleNext} />
          </div>
        </div>
      </div>
    )
  }
}

export default connect(ElementsSelector)
