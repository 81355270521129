import { connect } from 'react-redux'
import {
  selectItem,
  selectAll,
  removeItem,
  removeAll,
  saveSelected,
  filterBy,
  filterByTags,
  nextStep,
  stepBack,
} from 'actions/campaigns/locations_selector_actions'
import { selectByTags } from 'actions/campaigns/common_actions'

export default connect(
  (state) => ({
    id: state.getIn(['campaigns', 'id']),
    locations: state.getIn(['campaigns', 'locations']),
    tags: state.getIn(['campaigns', 'tags']),
    regions: state.getIn(['campaigns', 'regions']),
    isReady: state.getIn(['campaigns', 'isReady']),
  }),
  {
    selectItem,
    removeItem,
    selectAll,
    removeAll,
    saveSelected,
    filterBy,
    filterByTags,
    selectByTags,
    nextStep,
    stepBack
  }
)
