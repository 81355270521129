import React from 'react'
import store from 'stores/state_indicator_store'

const type = 'FavoritePhoto'

class SwitchableStateIndicator extends React.Component {
  constructor (props) {
    super(props)
    this.record = { id: props.id, url: props.url }
    if (props.enabled) store.initRecord(type, this.record)
    this.listener = store.addListener('change' + type + props.id, () => this.forceUpdate())
  }

  onClick (e) {
    e.preventDefault()
    e.stopPropagation()
    if (store.isEnabledFor(type, this.record)) {
      store.remove(type, this.record)
    }
    else  {
      store.add(type, this.record)
    }
    return false;
  }

  currentClassName () {
    if (store.isEnabledFor(type, this.record))
      return 'favorite ' + this.props.onClassName
    else
      return 'favorite ' + this.props.offClassName
  }

  componentWillUnmount () {
    this.listener.remove()
  }

  render () {
    return (
      <i className={this.currentClassName()} aria-hidden="true" onClick={ (e) => this.onClick(e) } key={ this.props.id }>
        { this.props.caption }
      </i>
    )
  }
}

export default SwitchableStateIndicator
