const $commentsTable = $('.project-comments table.custom-datatable')
const $statusChangesTable = $('.project-status-changes table.custom-datatable')
const options = {
  pageLength: 10,
  bStateSave: false,
  info: false,
  dom: 't<"bottom"p>',
  autoWidth: false,
  stateSave: true,
  order: [[1, 'asc']]
}
export default function () {
  $commentsTable.dataTable(options)
  $statusChangesTable.dataTable(options)
}
