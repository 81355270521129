import React from 'react'
import connect from './connect'
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import AssignForm from './assign_form'
import CreateForm from './create_form'

const I18n = window.I18n

class Forms extends React.Component {

  state = { activeTab: '1' }

  toggle = (tab) => this.setState({ activeTab: tab })

  render () {
    return (
      <div>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '1' })}
              onClick={() => { this.toggle('1'); }}
            >
              {I18n.t('multi_step.project_step_button.assign_form.tab_caption')}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '2' })}
              onClick={() => { this.toggle('2'); }}
            >
              {I18n.t('multi_step.project_step_button.create_form.tab_caption')}
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col sm="12">
                <AssignForm
                  mspProjects={this.props.projects}
                  mspSteps={this.props.steps}
                  current={this.props.current}
                  toggleModal={this.props.toggleModal}
                  loadStepsForMspProject={this.props.loadStepsForMspProject}
                  assignStep={this.props.assignStep}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col sm="12">
                <CreateForm
                  mspWorkflows={this.props.workflows}
                  current={this.props.current}
                  createMspProject={this.props.createMspProject}
                />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    )
  }
}

export default connect(Forms)
