import ProjectExpensesFiltersForm from './additional_reports/by_type/project_expenses'
import { Component } from 'react'
const FORMS = {
  'project_expenses': ProjectExpensesFiltersForm
}

export default class FormByType extends Component {

  render () {
    const CurrentForm = FORMS[this.props.type]
    return (
      <div className="form-by-type">
        <CurrentForm formData={ this.props.data } />
      </div>
    )
  }
}
