import * as React from 'react'
import { useState, useEffect } from 'react'
import classNames from 'classnames'
import store from '../../../../../stores/campaign_summary_amounts_store'
import { snakeCase } from '../../../../../libs/helpers'
import { ERROR_TYPE_TO_ICON } from '../../config'
import HiddenFields from './hidden_fields'
import AmountInput from './amount_input'
import AmountVariants from './amount_variants'

const { I18n } = window as any

const ErrorIcon = ({ type }) => {
  const ErrorIconView = ERROR_TYPE_TO_ICON[type]

  return (
    <ErrorIconView title={I18n.t(`campaigns.error_messages.${snakeCase(type)}`)} />
  )
}

const listeners = []

type AmountCellItemProps = {
  minAmount: number
  maxAmount: number
  tagsAmount: number
  elementId: number
  locationId: number
  id: number
  campaignElementId: number
  amount: number
}
export default function AmountCellItem (props: AmountCellItemProps) {
  const { minAmount, maxAmount, tagsAmount, elementId, locationId, id, campaignElementId, amount } = props
  const [componentAmount, updateComponentAmount] = useState(amount)
  const [errors, updateErrors] = useState([])

  const updateAmountValue = (e) => {
    const value = parseInt(e.target.value, 10) || 0
    updateComponentAmount(value)
  }

  useEffect(() => {
    listeners.push(store.addListener(`ValidationResult-${elementId}-${locationId}`,  (validatorErrors) => {
      updateErrors(validatorErrors)
    }))
    listeners.push(store.addListener(`UpdateAmount-${elementId}-${locationId}`, (newAmount) => {
      updateComponentAmount(newAmount)
    }))
    return () => listeners.forEach(listener => listener.remove())
  }, [])

  useEffect(() => {
    store.setAmount(elementId, locationId, componentAmount, { minAmount, maxAmount, tagsAmount })
  }, [componentAmount])

  const css = classNames(['amount-item', { 'errors': errors.length }])
  return (
    <div className={css}>
      <div className="">
        <AmountVariants min={minAmount} max={maxAmount} tagsAmount={tagsAmount} onChange={updateAmountValue} />
      </div>
      <AmountInput value={componentAmount} min={minAmount} max={maxAmount} onChange={updateAmountValue} />
      <HiddenFields id={id} locationId={locationId} campaignElementId={campaignElementId} />
      <div className="error-icons">
        {errors.map((type) => <ErrorIcon key={type} type={type} />)}
      </div>
    </div>
  )
}