import { connect } from 'react-redux'
import { updateElement, editElement, removeElement, clearForm  } from '../../../../actions/elements_order'

function mapStateToProps (state) {
  return {
    elements: state.getIn(['elementsOrder', 'elements']),
    total: state.getIn(['elementsOrder', 'total']),
    removed: state.getIn(['elementsOrder', 'removed']),
  }
}

function mapDispatchToProps (dispatch) {
  return {
    updateElement: (finderKey, finderValue, field, value) => dispatch(updateElement(finderKey, finderValue, field, value)),
    editElement: element => dispatch(editElement(element)),
    removeElement: (finderKey, finderValue) => dispatch(removeElement(finderKey, finderValue)),
    clearForm: () => dispatch(clearForm()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
