import * as React from 'react'
import SpinnerOverlay from '../../../shared/spinner_overlay'
import TemplatePreviewContent from './template_preview_content'

type TemplatePreviewProps = {
  currentElement: any
  currentTemplate: number
  currentTemplateData: any
  currentReplaceElementId: string
  addElement: (element: any, key: string) => void
}

function TemplatePreview(props: TemplatePreviewProps) {
  const { currentTemplate, currentTemplateData, currentElement, currentReplaceElementId, addElement } = props
  if (!currentTemplate && !currentElement) {
    // Template (or Element) isn't selected - don't show anything
    return null
  }
  if (currentTemplate && !currentTemplateData) {
    return (
      // Template selected, but isn't loaded yet - show spinner
      <SpinnerOverlay isActive>
        <div className="template-preview mt-3" />
      </SpinnerOverlay>
    )
  }
  return (
    <TemplatePreviewContent
      currentElement={currentElement}
      currentTemplateData={currentTemplateData}
      currentReplaceElementId={currentReplaceElementId}
      addElement={addElement}
    />
  )
}

export default TemplatePreview
