import * as React from 'react'
import { Component } from 'react'
import { format, subYears } from 'date-fns'
import store from 'stores/forms_data_store'
import Select from 'components/shared/base_react_select'
import DatePicker from 'react-datepicker'

class ProjectExpensesFiltersForm extends Component {

  constructor (props) {
    super(props)

    this.defaultValues = {
      startAt: null,
      endAt: null,
      selectedLocations: [],
      selectedCompanies: [],
      selectedVendors: [],
      formRef: this
    }

    store.init('by-type', this.defaultValues)

    this.listener = store.addListener('UpdateProjectExpensesFormView', () => this.forceUpdate())
  }

  filtersData () {
    return {
      start_at: store.forms['by-type'].startAt ? format(store.forms['by-type'].startAt, 'yyyy-MM-dd') : null,
      end_at: store.forms['by-type'].endAt ? format(store.forms['by-type'].endAt, 'yyyy-MM-dd') : null,
      location_ids: _.map(store.forms['by-type'].selectedLocations, (loc) => loc.id),
      company_ids: _.map(store.forms['by-type'].selectedCompanies, (comp) => comp.id),
      vendor_ids: _.map(store.forms['by-type'].selectedVendors,    (vend) => vend.id)
    }
  }

  componentWillUnmount () {
    this.listener.remove()
  }

  onChangeSelect (val, field) {
    store.assign('by-type', field, val)
    this.forceUpdate()
  }

  onClickFilter (e) {
    store.trigger('FilterClicked')
  }

  onClickReset (e) {
    store.init('by-type', this.defaultValues)
    this.forceUpdate()
    store.trigger('FilterClicked')
  }

  prepareDatePickerProps (params) {
    return {
      dateFormat: 'yyyy-MM-dd',
      showMonthDropdown: true,
      showYearDropdown: true,
      minDate: subYears(new Date(), 10),
      maxDate: new Date(),
      className: 'form-control-sm',
      name: params.key,
      selected: store.get('by-type', params.key),
      placeholderText: this.props.formData.placeholders[params.key],
      onChange: (date) => {
        store.assign('by-type', params.key, date)
        this.forceUpdate()
      },
    }
  }

  render () {
    return (
      <div id="project_expenses_filters_form" className="row">
        <div className="col-2">
          <DatePicker {...this.prepareDatePickerProps({ key: 'startAt' })} />
        </div>
        <div className="col-2">
          <DatePicker {...this.prepareDatePickerProps({ key: 'endAt' })} />
        </div>
        <div className="col-2">
          <Select
            value={ store.get('by-type', 'selectedLocations') }
            options={this.props.formData.locations}
            isMulti
            getOptionLabel={({ id }) => id}
            getOptionValue={({ name }) => name}
            onChange={ (v) => this.onChangeSelect(v, 'selectedLocations') }
            placeholder={ this.props.formData.placeholders.selectLocations }
          />
        </div>
        <div className="col-2">
          <Select
            value={ store.get('by-type', 'selectedVendors') }
            options={this.props.formData.vendors}
            isMulti
            getOptionLabel={({ id }) => id}
            getOptionValue={({ name }) => name}
            onChange={ (v) => this.onChangeSelect(v, 'selectedVendors') }
            placeholder={ this.props.formData.placeholders.selectVendors } />
        </div>
        <div className="col-2">
          <Select
            value={ store.get('by-type', 'selectedCompanies') }
            options={this.props.formData.companies}
            isMulti
            getOptionLabel={({ id }) => id}
            getOptionValue={({ name }) => name}
            onChange={ (v) => this.onChangeSelect(v, 'selectedCompanies') }
            placeholder={ this.props.formData.placeholders.selectCompanies } />
        </div>
        <div className="col-2 actions">
          <a className="btn btn-primary btn-sm" onClick={ (e) => this.onClickFilter(e) }>
            { this.props.formData.filterBtCaption }
          </a>
          <a className="btn btn-secondary btn-sm" onClick={ (e) => this.onClickReset(e) }>
            { this.props.formData.resetBtCaption }
          </a>
        </div>
      </div>
    )
  }
}

export default ProjectExpensesFiltersForm
