import React from 'react'
import FormContent from './items'
import { FormMode } from './items/form_mode_context'
import SubmitButton from './items/elements/submit_btn'


const I18n = window.I18n

const defaultSubmitButtonElement = { caption: I18n.t('application.actions.save') }

export default class FinalForm extends React.Component {
  render () {
    const { url, authenticityToken, data } = this.props
    const mode = (this.props.readOnly ? 'readOnly' : 'readyForFill')
    return (
      <div className="handover-form final-form">
        <form className="default-form default-form" action={url} method="post">
          <input type="hidden" name="authenticity_token" value={authenticityToken} />
          <FormContent rows={data.rows} mode={mode} />
          <div className="row">
            <div className="col-12">
              <SubmitButton element={defaultSubmitButtonElement} mode={new FormMode(mode)} />
            </div>
          </div>
        </form>
      </div>
    )
  }
}
