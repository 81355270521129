import * as React from 'react'
import { useFormContext } from "react-hook-form"
import { WidgetFactoryInterface } from '../survey_answer_form'

export default function Text({ field, fieldName }: WidgetFactoryInterface) {
  const { required } = field
  const { register } = useFormContext()
  const fieldId = fieldName.replace(/[\[\]]/g, '_')

  return (
    <li className={`text input form-group numeric ${required ? 'required' : 'optional'}`}>
      <label htmlFor={fieldId} className="label">
        {field.name}
      </label>
      <div className="default-form-input-wrapper">
        <textarea id={fieldId} rows={4} className="form-control" {...register(fieldName)} />
      </div>
    </li>
  )
}
