import React from 'react'
import { isNil } from 'lodash'

const DATE_FORMAT = 'DD-MM-YYYY'

function ChartRow({ name, startDate, endDate, installationDate, range }) {
  return (
    <tr className="projects-chart-row">
      <td className="projects-chart-row-title sticky-header">{name}</td>
      <td className="projects-chart-row-title">{startDate.format(DATE_FORMAT)}</td>
      <td className="projects-chart-row-title">{endDate && endDate.format(DATE_FORMAT)}</td>
      {range.map(day => {
        const cellClass = day.isBetween(startDate, endDate, 'day', '[]') ? 'filled' : ''
        return (
          <td className={cellClass} key={day}>
            {!isNil(installationDate) && day.isSame(installationDate, 'day') && (
              <span className="installation-date">&bull;</span>
            )}
            {!isNil(startDate) && isNil(endDate) && day.isSame(startDate, 'day') && (
              <span>&#10731;</span>
            )}
          </td>
        )
      })}
    </tr>
  )
}

export default ChartRow
