import Select from 'components/shared/base_react_select'
import ItemTypes, { getElementDefaultName } from '../../lib/item_types'

const I18n = window.I18n

const AVAILABLE_ELEMENTS = Object.keys(ItemTypes.ELEMENT.options.variants)

export default ({ addElement }) => {

  const options = AVAILABLE_ELEMENTS.map((key) => {
    let elementType = ItemTypes.ELEMENT.options.variants[key]
    return { id: elementType, label: getElementDefaultName(elementType) || key }
  })

  return (
    <Select
      value={''}
      options={options}
      onChange={addElement}
      placeholder={I18n.t('application.actions.select')}
    />
  )
}
