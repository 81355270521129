import React from 'react'

const { I18n } = window

function SelectProjects({ availableProjects, selectedProjects, setSelectedProjects }) {
  const defaultSelected = availableProjects.map((p) => p.get('id')).toJS()
  const allSelected = selectedProjects.sort().toString() === defaultSelected.sort().toString()
  const toggleAll = () => {
    if (allSelected) {
      setSelectedProjects([])
    } else {
      setSelectedProjects(defaultSelected)
    }
  }
  const toggleOne = (id) => {
    if (selectedProjects.includes(id)) {
      setSelectedProjects(selectedProjects.filter((e) => e !== id))
    } else {
      setSelectedProjects([...selectedProjects, id])
    }
  }

  return (
    <div className="col">
      <table className="table">
        <thead>
          <tr>
            <th>
              <input type="checkbox" checked={allSelected} onChange={() => toggleAll()} />
            </th>
            <th>{I18n.t('activerecord.attributes.project_ticket.id')}</th>
            <th>{I18n.t('activerecord.attributes.project_ticket.location')}</th>
          </tr>
        </thead>
        {availableProjects && (
          <tbody>
            {availableProjects.map((project) => {
              const id = project.get('id')
              return (
                <tr key={id}>
                  <td>
                    <input type="checkbox" checked={selectedProjects.includes(id)} onChange={() => toggleOne(id)} />
                  </td>
                  <td>#{id}</td>
                  <td>{project.get('location_name')}</td>
                </tr>
              )
            })}
          </tbody>
        )}
      </table>
    </div>
  )
}

export default SelectProjects
