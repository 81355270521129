import { useState, useEffect, useMemo } from 'react'
import store from 'stores/campaign_summary_amounts_store'
import ChangeAmountControl from 'components/campaigns/summary/change_amount_control'

const ElementTotalAmount = (props) => {

  const { elementId, minAmount, maxAmount, tagsAmount } = props
  const [total, updateTotal] = useState(props.totalValue)

  useEffect(() => {
    const listener = store.addListener(`TotalWasChanged-${elementId}`,  () => {
      updateTotal(store.totalFor(elementId))
    })
    return () => listener.remove()
  }, [])

  const setMinForAll = () => store.setNewAmountThroughEvent(elementId, minAmount)
  const setMaxForAll = () => store.setNewAmountThroughEvent(elementId, maxAmount)
  const setCustomAmountForAll = (val) => store.setNewAmountThroughEvent(elementId, val)

  return (
    <div className="row">
      <div className="col-2 text-left">
        <ChangeAmountControl
          min={minAmount}
          max={maxAmount}
          setMaxForAll={setMaxForAll}
          setMinForAll={setMinForAll}
          setCustomAmountForAll={setCustomAmountForAll}
        />
      </div>
      <div className="col-8 text-center">
        {total}
      </div>
    </div>
  )
}

export default ElementTotalAmount
