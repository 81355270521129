import * as React from 'react'
import { SavedFiltersControllerType } from './use_saved_filters_controller'
import SmModalWindow from '../../../shared/modal_window'
import SavedFilterForm from './saved_filter_form'
import { ProjectReportFilterDefinitionType } from './types'
import I18n from '../../../../libs/i18n'

type ChangeSavedFilterButtonProps = {
  savedFiltersController: SavedFiltersControllerType
  availableFilters: ProjectReportFilterDefinitionType[]
  showCompletedLabel: string
  initialAvailableFilters: ProjectReportFilterDefinitionType[]
  completedStatusId: string
}

export default function ChangeSavedFilterButton(props: ChangeSavedFilterButtonProps) {
  const { savedFiltersController, availableFilters, initialAvailableFilters, completedStatusId, showCompletedLabel } =
    props
  const { setFilter, formFilter } = savedFiltersController
  const [dialogOpened, setDialogOpened] = React.useState(false)
  const showModal = () => setDialogOpened(true)
  const closeModal = () => setDialogOpened(false)
  const modalContent = (
    <SavedFilterForm
      savedFiltersController={savedFiltersController}
      closeModal={closeModal}
      availableFilters={availableFilters}
      initialAvailableFilters={initialAvailableFilters}
      completedStatusId={completedStatusId}
      showCompletedLabel={showCompletedLabel}
    />
  )
  const modalButtons = (
    <div className="buttons-container">
      <button type="button" className="btn btn-outline-secondary mr-2" onClick={closeModal}>
        Luk
      </button>
      <button
        type="submit"
        className="btn btn-primary"
        onClick={(e) => {
          setFilter(formFilter)
          closeModal()
        }}
      >
        {I18n.t("custom_reports.projects.saved_filters.apply_filter")}
      </button>
    </div>
  )
  return (
    <>
      <button type="button" className="btn btn-outline-secondary text-nowrap" onClick={showModal}>
        {I18n.t("custom_reports.projects.saved_filters.change_filter")}
      </button>
      <SmModalWindow
        title="Skift filter"
        visible={dialogOpened}
        onClose={() => {
          setDialogOpened(false)
        }}
        content={modalContent}
        buttons={modalButtons}
        windowID="projects-report-change-filter-button"
      />
    </>
  )
}
