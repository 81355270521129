import { connect } from 'react-redux'
import { loadData } from 'actions/rent_list_actions'

function mapStateToProps (state, props) {
  return {
    elementGroups: state.getIn(['rentList', 'elementGroups'])?.toJS() || {},
    groupedAvailableElements: state.getIn(['rentList', 'groupedAvailableElements'])?.toJS() || {},
    rentElementRequestInProgress: state.getIn(['rentList', 'rentElementRequestInProgress']),
    rentedItems: state.getIn(['rentList', 'rentedItems'])?.toJS() || {},
    errors: state.getIn(['rentList', 'errors']).toJS() || [],
  }
}

function mapDispatchToProps (dispatch) {
  return {
    loadData: data => dispatch(loadData(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)
