import PropTypes from 'prop-types'
import ProjectItem from './project_item'

const Projects = ({items}) => {

  return (
    <>
      {items.map(project => (
        <ProjectItem key={`project-${project.id}`} item={project} />
      ))}
    </>
  )
}

Projects.propTypes = {
  items: PropTypes.array.isRequired
}

export default Projects