import React from 'react'

class DatatablesGlobalSearch extends React.Component {
  constructor(props) {
    super(props)
    this.state = { value: '' }

    this.handleChange = this.handleChange.bind(this)
  }

  render() {
    return (
      <input className="global-search form-control" placeholder={ this.props.placeholder } value={ this.state.value } onChange={ this.handleChange } />
    )
  }

  handleChange(event) {
    this.setState({value: event.target.value}, () => { this.updateDatatables() })
  }

  updateDatatables() {
    const value = this.state.value
    $(this.props.selector).each(function(){
      var datatable = $(this).find('table').dataTable().api()
      datatable.search(value).draw()

      var noResults = datatable.page.info().recordsDisplay == 0
      noResults ? $(this).hide() : $(this).show()

      var searchField = $(this).find('.search')
      value.length > 0 ? searchField.hide() : searchField.show()
    })
  }
}

export default DatatablesGlobalSearch
