import React from 'react'
import SmModalWindow from '../../shared/modal_window'
import Select from 'components/shared/base_react_select'

const I18n = window.I18n

export default class TagsModalSelector extends React.Component {

  state = { item: null, selected: [] }

  onChange = (opts) => {
    this.setState({ item: this.state.item, selected: opts })
  }

  onClose = () => this.props.resetActiveItem()

  modalContent = () => {
    return (
      <div>
        <Select
          isMulti
          value={this.state.selected}
          options={this.props.tags}
          onChange={this.onChange}
        />
      </div>
    )
  }

  clickApply = () => {
    this.props.selectTags(this.state.item, this.state.selected)
  }

  modalButtons = () => {
    return (
      <div className="buttons-container">
        <button className="btn btn-primary" type="button" onClick={this.clickApply.bind(this)}>
          {I18n.t('application.actions.apply')}
        </button>
      </div>
    )
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    let selected = []

    if (nextProps.item) {
      if (prevState.item && nextProps.item.cID.isEqual(prevState.item.cID)) {
        selected = prevState.selected
      }
      else { selected = nextProps.item.tags }
    }

    return { item: nextProps.item, selected: selected || [] }
  }

  render () {
    const title = I18n.t("campaigns.others.select_tags_for", { name: this.state.item?.name })
    return (
      <div>
        <SmModalWindow title={title} visible={!!this.state.item}
          onClose={ this.onClose }
          content={ this.modalContent() }
          buttons={ this.modalButtons() }
          windowID="campaign_modal_tags_selector_for_campaign_element"
        />
      </div>
    )
  }
}
