import * as React from 'react'
import { useFormContext } from "react-hook-form"
import { WidgetFactoryInterface } from '../survey_answer_form'

export default function String({ field, fieldName }: WidgetFactoryInterface) {
  const { required } = field
  const { register } = useFormContext()
  const fieldId = fieldName.replace(/[\[\]]/g, '_')


  return (
    <li className={`string input form-group stringish ${required ? 'required' : 'optional'}`}>
      <label htmlFor={fieldId} className="label">
        {field.name}
      </label>
      <div className="default-form-input-wrapper">
        <input id={fieldId} className=" form-control" type="text" {...register(fieldName)} />
      </div>
    </li>
  )
}
