import { useEffect, useState } from 'react'
import uuid from 'uuid/v4'
import { SavedFilterType } from './types'

// filter - a filter used to query the data
// currentSavedFilter - a filter that is currently selected, it has an id, name, and filter fields that are equal to the filter
// formFilter - a filter that is currently being edited

type UseSavedFiltersControllerProps = {
  filter: any,
  setFilter: (filter: any) => void
  savedFilters: SavedFilterType[]
  updateSavedFilters: (newSavedFilters: SavedFilterType[]) => void
}
export type SavedFiltersControllerType = {
  savedFilters: SavedFilterType[]
  saveAndApply: (onSuccessCallback: () => void) => void
  deleteFilter: () => void
  changeCurrentSavedFilter: (e: any) => void
  addNewSavedFilter: () => void
  filter: any
  setFilter: (filter: any) => void
  currentSavedFilter: SavedFilterType
  setCurrentSavedFilter: (filter: SavedFilterType) => void
  formFilter: any
  setFormFilter: (filter: any) => void
}
export default function useSavedFiltersController(props: UseSavedFiltersControllerProps): SavedFiltersControllerType {
  const { filter, setFilter, updateSavedFilters, savedFilters } = props

  const [currentSavedFilter, setCurrentSavedFilter] = useState<SavedFilterType>()
  const [formFilter, setFormFilter] = useState(currentSavedFilter?.filter || filter)
  useEffect(() => {
    setFormFilter(currentSavedFilter?.filter || filter)
  }, [currentSavedFilter])

  const saveAndApply = (onSuccessCallback: () => void) => {
    let newFilters = [...savedFilters]
    const newFilter = { ...currentSavedFilter, filter: formFilter }

    if (currentSavedFilter && currentSavedFilter.id) {
      if (newFilters.find(f => f.id === currentSavedFilter.id)) {
        newFilters = newFilters.map(f => (f.id === currentSavedFilter.id ? newFilter : f))
      } else {
        newFilters.push(newFilter)
      }

      if (currentSavedFilter.default) {
        newFilters = newFilters.map(f => (f.id === currentSavedFilter.id ? f : { ...f, default: false }))
      }
    }
    setCurrentSavedFilter(newFilter)
    setFilter(formFilter)
    updateSavedFilters(newFilters)
    onSuccessCallback()
  }

  const deleteFilter = () => {
    const newFilters = savedFilters.filter(f => f.id !== currentSavedFilter.id)
    setCurrentSavedFilter(null)
    setFilter(formFilter)
    updateSavedFilters(newFilters)
  }

  const changeCurrentSavedFilter = e => {
    const { value } = e.target
    if (value === '') {
      setCurrentSavedFilter(null)
      return
    }
    const newCurrentSavedFilter = savedFilters.find(f => f.id === value)
    setCurrentSavedFilter(newCurrentSavedFilter)
    // setFilter(newCurrentSavedFilter.filter)
  }

  const addNewSavedFilter = () => {
    const newFilter = {
      id: uuid(),
      name: 'Nyt filter',
      filter: formFilter,
      default: false,
    }
    setCurrentSavedFilter(newFilter)
  }

  return {
    savedFilters,
    saveAndApply,
    deleteFilter,
    changeCurrentSavedFilter,
    addNewSavedFilter,
    currentSavedFilter,
    setCurrentSavedFilter,
    formFilter,
    setFormFilter,
    filter,
    setFilter
  }
}
